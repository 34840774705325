import Button from './Button'
import Icon from './Icon'

function Down (props) {
  return <Icon {...props}>
    <path d="M4.66699 6.66663L8.00033 9.99996L11.3337 6.66663H4.66699Z" fill="currentColor"/>
  </Icon>
}

function DownButton (props) {
  return <Button {...props}><Down /></Button>
}

export default Down
export { DownButton }
