import { useAppState } from '@/state/app'
import { useProject, useTopic } from '@/state/project'
import '@/styles/Editor/Breadcrumbs.css'

function Item ({ children }) {
  return <span>{children}</span>
}

function Breadcrumbs () {
  const { navigationOpen } = useAppState()
  const project = useProject()
  const topic   = useTopic()

  return <h1 className="CodeDoor__Editor__Breadcrumbs">
    {
      ! navigationOpen && <>
        <Item>{project.name}</Item>
        <Item>{topic.name}</Item>
      </>
    }
    {
      navigationOpen
        ? <Item>{topic.name}</Item>
        : null
    }
  </h1>
}

export default Breadcrumbs
