import classNames from 'classnames'
import '@/styles/Icon.css'

function Icon ({ children, className, width = 16, height = 16, dimention = 16 }) {
  return <svg className={classNames('icon', className)}  width={width} height={height} viewBox={`0 0 ${dimention} ${dimention}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    {children}
  </svg>
}

export default Icon
