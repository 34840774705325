import classNames from 'classnames'
import '@/styles/CardList.css'

export default function CardList ({ children, large, style = null }) {
  return <div
    className={classNames(
      'CodeDoor__CardList',
      large ? 'is-large' : null
    )}
    style={style}
  >
    { children }
  </div>
}
