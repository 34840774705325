import { useParams } from 'react-router-dom'
import API from '@/api'
import Avatar from '@/components/Avatar'
import Breadcrumbs from '@/components/Breadcrumbs'
import CardPage from '@/components/CardPage'
import Email from '@/components/Icons/Email'
import Github from '@/components/Icons/Github'
import Pin from '@/components/Icons/Pin'
import Slack from '@/components/Icons/Slack';
import { Title } from '@/components/Meta'
import ProjectListItem from '@/components/ProjectListItem'
import Tag from '@/components/Tag'
import TagList from '@/components/TagList'
import { useAuth } from '@/state/auth'

import '@/styles/CardPageUser.css'

const fetchStudent = ({ id }) => API.get(`/users/${id}?filter[role]=STUDENT&include=skills,projects,projects.students`)

export default function Student() {
  const { id } = useParams()
  const { isAdmin } = useAuth()

  return <CardPage
    promiseFn={fetchStudent}
    id={id}
    watch={id}
    className="CodeDoor__Card__User"
    breadcrumbs={[
      isAdmin
        ? {
          label: 'Students',
          to: '/students',
        } : {
          label: 'Peers',
          to: '/peers',
        },
      u => <Breadcrumbs.Item label={u.fullName} key="student" />,
    ]}
  >{
      user => <>
        <Title text={user.fullName} />

        <header>
          <Avatar user={user} />
          <h1 className="title">{user.fullName}</h1>
          <p className="lead">{user.bio}</p>

          <ul>
            <li>
              <Email />
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </li>
            {
              user.githubUsername && (
                <li>
                  <Github />
                  <a href={`https://github.com/${user.githubUsername}`} target="_blank" rel="noreferrer">{user.githubUsername}</a>
                </li>
              )
            }
            {
              user.slackUsername && (
                <li>
                  <Slack />
                  {user.slackUsername}
                </li>
              )
            }
            {
              user.location && (
                <li>
                  <Pin />
                  <span>{user.location}</span>
                </li>
              )
            }
          </ul>
        </header>
        <section>
          {
            user.projects?.length
              ? <>
                <p className="label">Projects</p>
                <div>
                  {
                    user.projects.map(p => <ProjectListItem project={p} key={p.id} />)
                  }
                </div>
              </>
              : <p className="label">This user hasn't started working on projects</p>
          }
        </section>
        {
          user.skills?.length
            ? <aside>
              <p className="label">Skills</p>
              <TagList>{user.skills.map(s => <Tag title={s.name} to={`/skills/${s.slug}`} key={s.id} />)}</TagList>
            </aside>
            : null
        }
      </>
    }</CardPage>
}
