import { useHistory } from 'react-router-dom'
import Button from '@/components/Button'
import { useTopic } from '@/state/project'

function PreviousButton () {
  const history = useHistory()
  const topic = useTopic()

  if (! topic.previousLink) return null
  const goPrevious = () => history.push(`${topic.previousLink}`)

  return <Button onClick={goPrevious} type="secondary">Previous</Button>
}

export default PreviousButton
