import shorten from '@/helpers/shorten'
import Card from './Card'
import Tag from './Tag'
import TagList from './TagList'
import '@/styles/SkillListItem.css'

const SkillListItem = ({ skill }) => (
  <Card
    to={`/skills/${skill.slug}`}
    className="CodeDoor__SkillListItem"
  >
    <h1>{skill.name}</h1>
    <p>{shorten(skill.description)}</p>

    <TagList className="above-link">
      { skill.children?.map(childSkill => (
        <Tag title={childSkill.name} to={`/skills/${childSkill.slug}`} key={childSkill.id} />
      )) }
    </TagList>
  </Card>
)

export default SkillListItem
