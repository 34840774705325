import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import API from '@/api'
import CardList from '@/components/CardList'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import { StudentCard } from '@/components/UserCard'
import { useAuth } from '@/state/auth'

const fetchOrganisationPeers = ({ id }) => API.get(`/organisations/${id}/students`)

export default function Peers() {
  const { user } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="container">
      <Title text={t('vocab.peers')} />

      <h1 className="title">{t('vocab.peers')}</h1>

      <h2 className="title is-secondary">These are your {user.organisation.name} Peers</h2>
      <Async promiseFn={fetchOrganisationPeers} id={user.organisation.id}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {orgPeers => (
            <CardList>
              {orgPeers.map(peer => <StudentCard user={peer} key={peer.id} />)}
            </CardList>
          )}
        </Async.Fulfilled>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>
    </div >
  )
}
