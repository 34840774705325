import Button from './Button'
import Icon from './Icon'

function Circle () {
  return <Icon>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8ZM12.6667 8C12.6667 10.5773 10.5773 12.6667 8 12.6667C5.42267 12.6667 3.33333 10.5773 3.33333 8C3.33333 5.42267 5.42267 3.33333 8 3.33333C10.5773 3.33333 12.6667 5.42267 12.6667 8Z" fill="currentColor"/>
  </Icon>
}

function ThinCircle () {
  return <Icon>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6666 8.00001C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00001C1.33325 4.31811 4.31802 1.33334 7.99992 1.33334C11.6818 1.33334 14.6666 4.31811 14.6666 8.00001ZM13.3333 8.00001C13.3333 10.9455 10.9454 13.3333 7.99992 13.3333C5.0544 13.3333 2.66659 10.9455 2.66659 8.00001C2.66659 5.05449 5.0544 2.66668 7.99992 2.66668C10.9454 2.66668 13.3333 5.05449 13.3333 8.00001Z" fill="currentColor"/>
  </Icon>
}

function CircleButton (props) {
  return <Button {...props}><Circle /></Button>
}

export default Circle
export { CircleButton, ThinCircle }
