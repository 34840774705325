import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import API from '@/api'
import ErrorModal from '@/components/ErrorModal'
import SuccessModal from '@/components/SuccessModal'
import Button from './Button'
import EditTeam from './EditTeam'

const createTeam = ([projectDefinitionId, students]) => API.post(`/projects`, {
  students,
  projectDefinitionId,
})

export default function CreateTeamModal ({
  projectDefinitionId,
  onCreated = () => null,
}) {
  const [open, setOpen] = useState(false)
  const [fresh, setFresh] = useState(false)
  const [students, setStudents] = useState([])
  const { t } = useTranslation()
  const { data, run, error } = useAsync({ deferFn: createTeam })

  if (error) {
    const message = error?.message || 'Unknown error'
    return <ErrorModal open={open} message={message} onCloseSuccess={() => setOpen(false)} />
  }

  const onCloseSuccess = () => {
    setOpen(false)
    onCreated()
  }

  if (data && !fresh) {
    return <>
      <Button className="is-large" onClick={() => {
        setOpen(true)
        setFresh(true)
      }}>{t('projects.admin.create.cta')}</Button>
      <SuccessModal open={open} message={t('projects.admin.create.modal.success')} onCloseSuccess={onCloseSuccess} />
    </>
  }

  return <>
    <Button className="is-large" onClick={() => setOpen(true)}>{t('projects.admin.create.cta')}</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">{t('projects.admin.create.modal.title')}</h1>
      <p className="lead">{t('projects.admin.create.modal.lead')}</p>

      <EditTeam
        students={students}
        setStudents={setStudents}
      />

      <footer>
        <Button className="CodeDoor__Button CodeDoor__Button__secondary" onClick={() => setOpen(false)}>{t('vocab.cancel')}</Button>
        <Button onClick={() => {
          run(projectDefinitionId, students)
          setFresh(false)
        }} disabled={students.length === 0}>{t('vocab.create')}</Button>
      </footer>
    </Modal>
  </>
}
