import React from 'react'
import { Jutsu } from 'react-jutsu'

import '@/styles/JitsiView.css'

const JitsiView = (props) => {
  const { roomInitName, me } = props;

  return (
    <Jutsu
      subject='Team Meeting'
      containerStyles={{ width: '100%', height: '400px', marginBottom: '1em' }}
      roomName={roomInitName}
      displayName={me}
      onMeetingEnd={() => props.onMeetingEnd()}
      loadingComponent={<p>loading ...</p>}
      errorComponent={<p>Oops, something went wrong</p>} />
  )
}

export default JitsiView
