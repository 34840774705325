import Button from './Button'
import Icon from './Icon'

function CircleCheck ({ className }) {
  return <Icon className={className}>
    <path d="M8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667Z" fill="currentColor" className="dark" />
    <path d="M6.66667 9.72666L10.8667 5.52666C10.995 5.41828 11.1594 5.36232 11.3272 5.36997C11.495 5.37761 11.6537 5.4483 11.7716 5.5679C11.8895 5.68749 11.9579 5.84718 11.9632 6.01505C11.9685 6.18291 11.9102 6.34658 11.8 6.47333L7.13334 11.14C7.00872 11.2621 6.84117 11.3306 6.66667 11.3306C6.49217 11.3306 6.32462 11.2621 6.2 11.14L4.2 9.13999C4.08982 9.01324 4.03153 8.84958 4.0368 8.68171C4.04206 8.51385 4.11049 8.35416 4.2284 8.23456C4.34631 8.11497 4.50502 8.04428 4.67279 8.03664C4.84056 8.02899 5.00504 8.08495 5.13334 8.19333L6.66667 9.72666Z" fill="currentColor" className="light" />
  </Icon>
}

function CircleCheckButton (props) {
  return <Button {...props}><CircleCheck /></Button>
}

export default CircleCheck
export { CircleCheckButton }
