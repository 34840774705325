import Storage from './Storage'

export default class LocalStorage extends Storage { }

export const LOCALSTORAGE_KEYS = {
  LOCALE: 'locale',
  USER: 'user',
  APP_STATE: 'app_state',

  // View Preference
  DATA_TABLE_VIEW: 'data_table_view',

  // Editor
  CURRENT_PROJECT: 'current_project',
  LAST_LOCATION: 'last_location',
  LAST_TOPIC_WORKED_ON: 'last_topic_worked_on',
  PROJECT_DEFINITION: 'project_definition',
}

export const storage = new LocalStorage()
