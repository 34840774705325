export const outlineSubtask = {
  name: '',
  nameError: false,
  description: '',
  descriptionError: false,
  children: [],
  skills: [],
};

export const emptyOutlineItem = [
  {
    name: '',
    nameError: false,
    description: '',
    descriptionError: false,
    children: [],
    skills: [],
  },
];

export default {
  name: '',
  nameError: false,
  description: '',
  descriptionError: false,
  level: 'CodeDoor',
  outline: [],
  outlineError: false,
}
