import Button from './Button'
import Icon from './Icon'

function Menu () {
  return <Icon>
    <path d="M2.66667 3.33334H13.3333C13.5101 3.33334 13.6797 3.40357 13.8047 3.5286C13.9298 3.65362 14 3.82319 14 4C14 4.17681 13.9298 4.34638 13.8047 4.47141C13.6797 4.59643 13.5101 4.66667 13.3333 4.66667H2.66667C2.48986 4.66667 2.32029 4.59643 2.19526 4.47141C2.07024 4.34638 2 4.17681 2 4C2 3.82319 2.07024 3.65362 2.19526 3.5286C2.32029 3.40357 2.48986 3.33334 2.66667 3.33334ZM2.66667 7.33334H13.3333C13.5101 7.33334 13.6797 7.40357 13.8047 7.5286C13.9298 7.65362 14 7.82319 14 8C14 8.17681 13.9298 8.34638 13.8047 8.47141C13.6797 8.59643 13.5101 8.66667 13.3333 8.66667H2.66667C2.48986 8.66667 2.32029 8.59643 2.19526 8.47141C2.07024 8.34638 2 8.17681 2 8C2 7.82319 2.07024 7.65362 2.19526 7.5286C2.32029 7.40357 2.48986 7.33334 2.66667 7.33334ZM2.66667 11.3333H13.3333C13.5101 11.3333 13.6797 11.4036 13.8047 11.5286C13.9298 11.6536 14 11.8232 14 12C14 12.1768 13.9298 12.3464 13.8047 12.4714C13.6797 12.5964 13.5101 12.6667 13.3333 12.6667H2.66667C2.48986 12.6667 2.32029 12.5964 2.19526 12.4714C2.07024 12.3464 2 12.1768 2 12C2 11.8232 2.07024 11.6536 2.19526 11.5286C2.32029 11.4036 2.48986 11.3333 2.66667 11.3333Z" fill="currentColor"/>
  </Icon>
}

function MenuButton (props) {
  return <Button {...props}><Menu /></Button>
}

export default Menu
export { MenuButton }
