import classNames from 'classnames'
import '@/styles/FormGroup.css'

function FormGroup ({ onClick, children, label, error, className, ...props }) {
  return <div
    className={classNames(
      'CodeDoor__FormGroup',
      className,
      error ? 'has-error' : null
    )}
    {...props}
  >
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      <span className="label">{label}</span>
      {children}
    </label>

    { error ? <span className="message">{error}</span> : null }
  </div>
}

export default FormGroup
