import { useState } from 'react'
import { useAsync } from 'react-async'
import Modal from 'react-modal'
import auth from '@/api/auth'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'
import Button from './Button'
import FormGroup from './FormGroup'
import Input from './Input'

const updatePassword = ([password]) => auth.updatePassword(password)

export default function CheckForMissingPassword () {
  const [open, setOpen] = useState(true)
  const [password, setPassword] = useState('')
  const { user } = useAuth()
  const { isFulfilled, error, isPending, run } = useAsync({ deferFn: updatePassword })

  if (isFulfilled) return null
  if (! user.passwordMissing) return null

  const handleSubmit = async event => {
    event.preventDefault()
    run(password)
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      closeTimeoutMS={300}
    >
      <h1 className="title">New Password</h1>
      <p className="lead">Great to have you back, {user.fullName}! Please set a new password for your account!</p>

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Password"
          error={getValidationMessage('password', error)}
        >
          <Input
            type="password"
            placeholder="*********"
            value={password}
            onChange={setPassword}
          />
        </FormGroup>

        <div className="actions">
          <Button
            type="submit"
            disabled={isPending}
          >{
              isPending
                ? 'Saving...'
                : 'Save'
            }</Button>
        </div>
      </form>
    </Modal>
  )
}
