import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import shorten from '../helpers/shorten'
import striphtml from '../helpers/striphtml'
import Button from './Button'
import { ThinCircle } from './Icons/Circle'
import CircleCheck from './Icons/CircleCheck'

export default function ProjectOutlineItem({ item }) {
  const { id, progress, name, description, children } = item
  const [childrenVisible, setChildrenVisible] = useState(false);
  const { t } = useTranslation()

  return <div>
    {
      progress === 100
        ? <CircleCheck className="is-active" />
        : <ThinCircle />
    }
    <div className="text">
      <strong>{name}</strong>
      {shorten(striphtml(description))}

      {(childrenVisible) && (
        <div className="subItems">
          {children.map(subTopic => (
            <div className="subItem" key={`${id}-${subTopic.id}`} style={{ display: 'flex' }}>
              <div>
                <strong>{subTopic.name}</strong>
                {striphtml(subTopic.description)}
              </div>
            </div>
          ))}
        </div>
      )}
      {(children && children.length > 0 && !childrenVisible) && (
        <Button variant="secondary" onClick={() => setChildrenVisible(!childrenVisible)}>{t('vocab.more')}</Button>
      )}
      {(children && children.length > 0 && childrenVisible) && (
        <Button variant="secondary" onClick={() => setChildrenVisible(!childrenVisible)}>{t('vocab.less')}</Button>
      )}
    </div>
  </div>
}
