import UserLink from './UserLink'
import '@/styles/Avatar.css'

export default function Avatar ({ user, link = false }) {

  const avatar = user.avatarUrl
    ? <img src={user.avatarUrl} alt={user.fullName} />
    : user.initials

  return link
    ? <UserLink user={user} className="CodeDoor__Avatar">{avatar}</UserLink>
    : <span className="CodeDoor__Avatar">{avatar}</span>

}
