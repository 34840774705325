import { useTranslation } from 'react-i18next'
import Card from '@/components/Card'
import MyActiveProjects from '@/components/MyActiveProjects';

export default function UserLocale() {
  const { t } = useTranslation()

  return <Card className="CodeDoor__UserSettings__Locale is-large">
    <h1 className="title">{t('settings.user.projects.title')}</h1>

    <MyActiveProjects title={t('home.students.projects')} />
  </Card>
}
