import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import API from '@/api'
import ActiveProjectsTable from '@/components/ActiveProjectsTable'
import Breadcrumbs from '@/components/Breadcrumbs'
import CardPage from '@/components/CardPage'
import CreateTeamModal from '@/components/CreateTeamModal'
import { Title } from '@/components/Meta'
import ProjectOutline from '@/components/ProjectOutline'
import RequestLicense from '@/components/RequestLicense'
import StartProject from '@/components/StartProject'
import Tag from '@/components/Tag'
import TagList from '@/components/TagList'
import { useTrackedForceUpdate } from '@/helpers/force-update'
import { useAuth } from '@/state/auth'
import '@/styles/CardPageProjectDefinition.css'

const fetchProjectDefinition = ({ id, includes = 'skills' }) =>
  API.get(`/project-definitions/${id}?include=${includes}`)

export default function ProjectDefinition () {
  const { isAdmin } = useAuth()
  const { id } = useParams()
  const [toWatch, update] = useTrackedForceUpdate()
  const { t } = useTranslation()

  return (
    <CardPage
      promiseFn={fetchProjectDefinition}
      id={id}
      watch={toWatch}
      includes={isAdmin ? 'skills,organisationProjects,organisationProjects.mentors,organisationProjects.students' : 'skills'}
      className="CodeDoor__Card__ProjectDefinition"
      breadcrumbs={[
        {
          label: t('projects.title'),
          to: '/projects',
        },
        d => <Breadcrumbs.Item label={d.name} key="definition" />,
      ]}
    >{
        definition => (
          <>
            <Title text={definition.name} />

            <header>
              <h1 className="title">{definition.name}</h1>
              <div className="actions">
                {
                  definition.price > 0 && !definition.hasAvailableLicense
                    ? <RequestLicense />
                    : isAdmin
                      ? <CreateTeamModal
                        className="is-large"
                        projectDefinitionId={id}
                        onCreated={update}
                      />
                      : <StartProject definition={definition} />
                }
              </div>
            </header>
            <p
              className="lead"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: definition.description,
              }}
            />

            {
              isAdmin && !!definition.projects?.length && (
                <section className="teams">
                  <ActiveProjectsTable projects={definition.projects} />
                </section>
              )
            }

            <section className="left">
              <p className="label">{t('vocab.level')}</p>
              <div><Tag title={definition.level} /></div>

              <ProjectOutline outline={definition.outline} />
            </section>

            <section className="right">
              <p className="label">{t('vocab.skills')}</p>
              <TagList>
                {
                  definition.skills.map(skill => (
                    <Tag title={skill.name} to={`/skills/${skill.slug}`} key={skill.id} />
                  ))
                }
              </TagList>

              {/* <p className="label">Mentors</p> */}
            </section>
          </>
        )
      }
    </CardPage>
  )
}
