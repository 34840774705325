import React from 'react'
import EasyEdit, { Types } from 'react-easy-edit';
import { useTranslation } from 'react-i18next'
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Button from '@/components/Button'
import { outlineSubtask } from '@/components/definition-template'
import useForceUpdate from '@/helpers/force-update'
import striphtml from '@/helpers/striphtml'
import { emptyOutlineItem } from './definition-template'
import { Dropdown, DropdownContent, DropdownItem, DropdownTrigger } from './Dropdown';
import { ThinCircle } from './Icons/Circle'
import CircleCheck from './Icons/CircleCheck'
import Tag from './Tag'
import TagList from './TagList'

function OutlineTitle({ outlineItem, definition, setDefinition, outlineItemIndex }) {
  const item = outlineItem || emptyOutlineItem
  const { name } = item
  const displayComponent = <strong className={classNames({ 'has-error': outlineItem.nameError })}>{name || 'Outline title'}</strong>

  return (
    <EasyEdit
      value={name}
      saveOnBlur
      onHoverCssClass="inline-hover-on"
      cssClassPrefix="CodeDoor__Inline__DefinitionEditor text "
      displayComponent={displayComponent}
      placeholder={name || 'Outline title'}
      type={Types.TEXT}
      onSave={
        (value) => {
          let newValue = value
          if (value !== '' && value == null) {
            newValue = ''
          }

          const outline = Object.assign([],
            definition.outline,
            { [outlineItemIndex]: { ...definition.outline[outlineItemIndex], name: newValue } }
          );

          const newDefinition = { ...definition, outline }
          setDefinition(newDefinition)
        }
      }
    />
  );
}

function OutlineSubtitle({ description, definition, setDefinition, outlineItemIndex, outlineItem }) {
  const displayComponent = <p className={classNames({ 'has-error': outlineItem.descriptionError })}>{(striphtml(description || 'Outline subtitle'))}</p>;

  return (
    <EasyEdit
      value={description}
      saveOnBlur
      onHoverCssClass="inline-hover-on"
      cssClassPrefix="CodeDoor__Inline__DefinitionEditor text "
      displayComponent={displayComponent}
      placeholder={description || 'Outline subtitle'}
      type={Types.TEXT}
      // validationMessage="empty"
      // onValidate={value => {return value != null && value !== ''}}
      onSave={
        (value) => {
          let newValue = value
          if (value !== '' && value == null) {
            newValue = ''
          }

          const outline = Object.assign([],
            definition.outline,
            { [outlineItemIndex]: { ...definition.outline[outlineItemIndex], description: newValue } }
          );

          const newDefinition = { ...definition, outline }
          setDefinition(newDefinition)
        }
      }
    />
  );
}

function OutlineSubTopic({ subTopic, id, definition, setDefinition, outlineItem, outlineItemIndex, update, availableSkills, subTopicIndex }) {
  const nameDisplayComponent = <strong className={classNames({ 'has-error': subTopic.nameError })} >{subTopic.name || 'Subtopic name'}</strong>;
  const descriptionDisplayComponent = <p className={classNames({ 'has-error': subTopic.descriptionError })} >{striphtml(subTopic.description || 'Subtopic description')}</p>;

  return (

    <div key={`${id}-${subTopic.id}`} className="subItem__Edit">
      <div className="subItem" style={{ display: 'flex' }} >
        <div >
          <EasyEdit
            value={subTopic.description}
            saveOnBlur
            onHoverCssClass="inline-hover-on"
            cssClassPrefix="CodeDoor__Inline__DefinitionEditor text "
            displayComponent={nameDisplayComponent}
            placeholder={subTopic.name || 'Subtopic name'}
            type={Types.TEXT}
            onSave={
              (value) => {
                let newValue = value
                if (value !== '' && value == null) {
                  newValue = ''
                }

                if (subTopic) {
                  const { outline } = definition
                  const subTopicIndex = outline[outlineItemIndex].children.indexOf(subTopic)
                  const updatedSubtopic = { ...subTopic, name: newValue }
                  // eslint-disable-next-line no-param-reassign
                  definition.outline[outlineItemIndex].children[subTopicIndex] = updatedSubtopic;
                  setDefinition(definition)
                  update()
                } else {
                  // eslint-disable-next-line no-console
                  console.error('subTopic not defined')
                }
              }
            }
          />
          <EasyEdit
            value={subTopic.description}
            saveOnBlur
            onHoverCssClass="inline-hover-on"
            cssClassPrefix="CodeDoor__Inline__DefinitionEditor text " displayComponent={descriptionDisplayComponent}
            placeholder={subTopic.description || 'Subtopic description'}
            type={Types.TEXT}
            // validationMessage="empty"
            // onValidate={value => {return value != null && value !== ''}}
            onSave={
              (value) => {
                let newValue = value
                if (value !== '' && value == null) {
                  newValue = ''
                }

                if (subTopic) {
                  const { outline } = definition
                  const subTopicIndex = outline[outlineItemIndex].children.indexOf(subTopic)
                  const updatedSubtopic = { ...subTopic, description: newValue }

                  // eslint-disable-next-line no-param-reassign
                  definition.outline[outlineItemIndex].children[subTopicIndex] = updatedSubtopic;
                  setDefinition(definition)
                  update()
                } else {
                  // eslint-disable-next-line no-console
                  console.error('subTopic not defined')
                }
              }
            }
          />
        </div>
      </div>
      <SubTopicSkillSelectionList
        definition={definition}
        setDefinition={setDefinition}
        availableSkills={availableSkills}
        outlineItem={outlineItem}
        outlineItemIndex={outlineItemIndex}
        subTopic={subTopic}
        subTopicIndex={subTopicIndex} />
    </div>
  );
}

export default function ProjectOutlineItemEdit({
  definition,
  outlineItem = emptyOutlineItem,
  outlineItemIndex = 0,
  setDefinition,
  availableSkills,
}) {
  const update = useForceUpdate()
  const { progress, description, children } = outlineItem
  const addNewOutlineSubtask = () => {
    const outline = Object.assign([],
      definition.outline,
      { [outlineItemIndex]: { ...definition.outline[outlineItemIndex], children: children.concat(outlineSubtask) } }
    );
    const newDefinition = { ...definition, outline }
    setDefinition(newDefinition)
  }

  return <div>
    {
      progress === 100
        ? <CircleCheck className="is-active" />
        : <ThinCircle />
    }
    <div className="text">
      <div className='ProjectOutline__ItemEdit'>
        <div>
          <OutlineTitle
            definition={definition}
            outlineItem={outlineItem}
            outlineItemIndex={outlineItemIndex}
            setDefinition={setDefinition}
          />
          <OutlineSubtitle
            description={description}
            definition={definition}
            outlineItem={outlineItem}
            outlineItemIndex={outlineItemIndex}
            setDefinition={setDefinition}
          />
        </div>
        <TopicSkillSelectionList
          definition={definition}
          setDefinition={setDefinition}
          availableSkills={availableSkills}
          outlineItem={outlineItem}
          outlineItemIndex={outlineItemIndex} />
      </div>
      <div className="subItems">
        {children.map((subTopic, index) => (
          <OutlineSubTopic
            update={update}
            key={`${subTopic.id}-${index}`}
            subTopic={subTopic}
            subTopicIndex={index}
            id={`${subTopic.id}-${index}`}
            definition={definition}
            outlineItem={outlineItem}
            outlineItemIndex={outlineItemIndex}
            setDefinition={setDefinition}
            availableSkills={availableSkills}
          />
        ))}
      </div>
      <Button className="is-small" onClick={addNewOutlineSubtask}>add subtopic</Button>
    </div>
  </div >
}

function TopicSkillSelectionList({ availableSkills, definition, setDefinition, outlineItem, outlineItemIndex }) {
  const { t } = useTranslation()

  const addSkill = (newSkill) => {
    const { skills } = outlineItem;
    if (skills.find(skill => skill.id === newSkill.id)) return

    const newSkills = outlineItem.skills.concat([newSkill])
    const newOutline = Object.assign([],
      definition.outline,
      { [outlineItemIndex]: { ...definition.outline[outlineItemIndex], skills: newSkills } }
    );
    setDefinition({ ...definition, outline: newOutline })
  }

  return (
    <TagList>
      <Dropdown>
        <DropdownTrigger>
          <span className='CodeDoor__Button CodeDoor__Button__primary'>+ {t('vocab.skill')} </span>
        </DropdownTrigger>
        <DropdownContent>
          {availableSkills.map(skill => <DropdownItem onSelect={() => addSkill(skill)} value={skill.id} key={uuidv4(skill.id)}>{skill.name}</DropdownItem>)}
        </DropdownContent>
      </Dropdown>
      {outlineItem.skills.map(skill => <Tag to="#" key={uuidv4()} title={skill.name} />)}
    </TagList>
  )
}

function SubTopicSkillSelectionList({ availableSkills, definition, setDefinition, outlineItem, outlineItemIndex, subTopic, subTopicIndex }) {
  const { t } = useTranslation()
  const addSkill = (newSkill) => {
    const { skills } = subTopic
    if (skills.find(skill => skill.id === newSkill.id)) return
    const newSkills = subTopic.skills.concat([newSkill])
    const { children } = outlineItem
    const newChildren = Object.assign([],
      children,
      { [subTopicIndex]: { ...children[subTopicIndex], skills: newSkills } }

    )
    const newOutline = Object.assign([],
      definition.outline,
      { [outlineItemIndex]: { ...definition.outline[outlineItemIndex], children: newChildren } }
    )

    setDefinition({ ...definition, outline: newOutline })
  }

  return (
    <TagList>
      <Dropdown>
        <DropdownTrigger>
          <span className='CodeDoor__Button CodeDoor__Button__primary'>+ {t('vocab.skill')} </span>
        </DropdownTrigger>
        <DropdownContent>
          {availableSkills.map(skill => <DropdownItem onSelect={() => addSkill(skill)} value={skill.id} key={uuidv4(skill.id)}>{skill.name}</DropdownItem>)}
        </DropdownContent>
      </Dropdown>
      {subTopic.skills.map(skill => <Tag to="#" key={uuidv4()} title={skill.name} />)}
    </TagList>
  )
}
