import React from 'react'
import { useTranslation } from 'react-i18next'
import { VIEW } from '@/helpers/enums'
import { GridButton } from './Icons/Grid'
import { ListButton } from './Icons/List'

export default function DataViewSwitch ({ state, setState }) {
  const isList = state === VIEW.TABLE
  const { t } = useTranslation()

  return (<>
    <ListButton
      onClick={() => setState(VIEW.TABLE)}
      className={isList ? 'is-active' : ''}
      title={t('vocab.list_view')}
    />

    <GridButton
      onClick={() => setState(VIEW.CARD)}
      className={!isList ? 'is-active' : ''}
      title={t('vocab.grid_view')}
    />
  </>
  )
}
