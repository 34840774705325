import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from '@/components/PrivateRoute'
import ProjectDefinitionEditor from '@/components/ProjectDefinitionEditor'
import AdminHome from '@/views/Home/AdminHome'
import Project from '@/views/Projects/Project'
import ProjectDefinition from '@/views/Projects/ProjectDefinition'
import Projects from '@/views/Projects/Projects'
import Organisation from '@/views/Settings/Organisation'
import User from '@/views/Settings/User'
import Skill from '@/views/Skill'
import Skills from '@/views/Skills'
import Student from '@/views/Student'
import Students from '@/views/Students'

const AdminRoutes = () => <Switch>
  <PrivateRoute exact path="/">
    <AdminHome />
  </PrivateRoute>

  <PrivateRoute exact path="/students">
    <Students />
  </PrivateRoute>

  <PrivateRoute exact path="/students/:id">
    <Student />
  </PrivateRoute>

  <PrivateRoute exact path="/projects">
    <Projects />
  </PrivateRoute>

  <PrivateRoute exact path="/project-definitions/:id">
    <ProjectDefinition />
  </PrivateRoute>

  <PrivateRoute exact path="/project-definition/new">
    <ProjectDefinitionEditor />
  </PrivateRoute>

  <PrivateRoute exact path="/projects/:id">
    <Project />
  </PrivateRoute>

  <PrivateRoute exact path="/skills">
    <Skills />
  </PrivateRoute>

  <PrivateRoute exact path="/skills/:slug">
    <Skill />
  </PrivateRoute>

  <PrivateRoute exact path="/settings/user">
    <User />
  </PrivateRoute>

  <PrivateRoute exact path="/settings/organisation">
    <Organisation />
  </PrivateRoute>

  <Route path="*">
    <Redirect to="/" />
  </Route>
</Switch>

export default AdminRoutes
