import { Async } from 'react-async'
import classNames from 'classnames'
import Breadcrumbs from './Breadcrumbs'
import Button from './Button'
import Card from './Card'
import Loading from './Loading'

export default function CardPage ({
  breadcrumbs,
  children,
  ...props
}) {
  return (
    <Async {...props} >
      <Async.Pending><Loading /></Async.Pending>

      <Async.Fulfilled>{ data => (
        <div className="container">
          {
            breadcrumbs && (
              <Breadcrumbs>
                {
                  breadcrumbs
                    .map((b, index) => typeof b === 'function'
                      ? b(data)
                      : <Breadcrumbs.Item to={b.to} label={b.label} key={index + 1} />)
                    .filter(b => !!b)
                }
              </Breadcrumbs>
            )
          }
          <Card className={classNames(props.className, 'is-large')}>
            { children(data) }
          </Card>
        </div>
      )}</Async.Fulfilled>

      <Async.Rejected>{ error => (
        <div className="container text-center">
          <h1 className="title">{error.message}</h1>
          <Button to="/">Go home</Button>
        </div>
      )
      }</Async.Rejected>
    </Async>
  )
}
