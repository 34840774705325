import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import FormGroup from '@/components/FormGroup'
import Input from '@/components/Input'
import { Title } from '@/components/Meta'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'

export default function Login () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const auth = useAuth()
  const { t } = useTranslation()
  const { error, isPending, run } = useAsync({ deferFn: auth.signIn })

  const handleSubmit = async event => {
    event.preventDefault()
    run(email, password)
  }

  return (
    <>
      <Title text={t('auth.login.title')} />

      <h1>{t('auth.login.title')}</h1>

      <form onSubmit={handleSubmit}>
        <FormGroup
          label={t('vocab.email')}
          error={getValidationMessage('email', error)}
        >
          <Input
            type="email"
            placeholder="you@hey.com"
            value={email}
            onChange={setEmail}
            autoComplete="email"
          />
        </FormGroup>

        <FormGroup
          label={t('vocab.password')}
          error={getValidationMessage('password', error)}
        >
          <Input
            type="password"
            placeholder="*********"
            value={password}
            onChange={setPassword}
            autoComplete="current-password"
          />
        </FormGroup>

        <div className="actions">
          {
            !isPending && (
              <Button to="/auth/reset" variant="secondary">
                {t('auth.login.to_reset')}
              </Button>
            )
          }

          <Button type="submit" disabled={isPending}>{
            isPending
              ?  t('vocab.submitting')
              :  t('auth.login.cta')
          }</Button>
        </div>
      </form>
    </>
  )
}
