import { useEffect } from 'react'
import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import API from '@/api'
import ChooseStep from '@/components/Editor/ChooseStep'
import EditorLayout from '@/components/Editor/EditorLayout'
import ProjectNavigation from '@/components/Editor/ProjectNavigation'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'
import { useAppState } from '@/state/app'
import { storageKey, attachmentStorageKey } from '@/state/models/Answer'
import { TopicsMap } from '@/state/models/Topic'
import { ProvideProject } from '@/state/project'
import '@/styles/Editor/Editor.css'

const loadProject = async ({ id }) => {
  const [project, answers] = await Promise.all([
    API.get(`/projects/${id}/tree`),
    API.get(`/projects/${id}/my-answers`),
  ])

  // TODO: Save the answers to localStorage
  answers.forEach(answer => {
    storage.setItem(storageKey(answer.topicId), answer.body)
    storage.setItem(attachmentStorageKey(answer.topicId, answer.id), answer.attachments)
  })

  return project
}

function NestableTopic() {
  const { url } = useRouteMatch()
  const { topicId } = useParams()
  const topic = TopicsMap[topicId]

  if (topic.hasChildren) {
    return <Switch>
      <Route path={url} exact>
        <Redirect to={`${topic.id}/${topic.children[0].id}`} />
      </Route>

      <Route path={`${url}/:topicId/:tab?`}>
        <NestableTopic />
      </Route>
    </Switch>
  }

  return <EditorLayout />
}

function Editor() {
  const { path } = useRouteMatch()
  const { projectId } = useParams()
  const { navigation, theme } = useAppState()
  const { t } = useTranslation()

  useEffect(() => {
    storage.setItem(LOCALSTORAGE_KEYS.CURRENT_PROJECT, projectId)
  }, [projectId])
  return (
    <div className={classNames('CodeDoor__Editor', `navigation-${navigation}`, `theme-${theme}`)}>
      <Async promiseFn={loadProject} id={projectId}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {project => (
            <ProvideProject project={project}>
              <Title text={t('editor.meta_title', { project: project.name })} />

              <ProjectNavigation />

              <Switch>
                <Route path={`${path}/topics/:topicId`}>
                  <NestableTopic />
                </Route>

                <Route path="*">
                  <ChooseStep />
                </Route>
              </Switch>
            </ProvideProject>
          )}
        </Async.Fulfilled>
        <Async.Rejected>There was an error loading the project!</Async.Rejected>
      </Async>
    </div>
  )
}

export default Editor
