import { useProject } from '@/state/project'
import NavigationItem from './NavigationItem'

function NavigationTopic ({ topic }) {
  const project = useProject()
  const topicUrl = `/editor/${project.id}/topics/${topic.id}`

  return <NavigationItem
    to={topicUrl}
    label={topic.name}
    isComplete={topic.isComplete}
    key={topic.id}
  >
    {
      topic.children
        .filter(child => child.name !== topic.name)
        .map(child => <NavigationItem
          to={`${topicUrl}/${child.id}`}
          label={child.name}
          isComplete={child.isComplete}
          key={child.id}
        />)
    }
  </NavigationItem>
}

export default NavigationTopic
