function IframeProjectDefinition({ video }) {
  return (
    <iframe
      title="Video"
      width="100%"
      height="100%"
      src={video}
      frameBorder="0"
      overFlow="hidden"
    />
  );
}

export default IframeProjectDefinition;
