import { useState } from 'react'
import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import API from '@/api'
import Card from '@/components/Card'
import CardList from '@/components/CardList'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import { StudentCard } from '@/components/UserCard'
import { useAuth } from '@/state/auth'

const requestOptions = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
};

const listUsersTopics = async ({ userId }) =>
  fetch(`https://container.tensorparc.com/api/openai/beltureUserTopics/${userId}`, requestOptions)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

const fetchOrganisationPeers = ({ id }) => API.get(`/organisations/${id}/students`)

export default function Peers() {
  const { user } = useAuth()
  const auth = useAuth()
  const [userid] = useState(auth.user.id)
  const { t } = useTranslation()

  return (
    <div className="container">
      <Title text={t('vocab.peersranking')} />

      <h1 className="title">Bel {t('vocab.peersranking')}</h1>
      <CardList>
        <StudentCard user={user} key={user.id} >
          <Async promiseFn={listUsersTopics} userId={userid}>
            {({ data, error, isLoading }) => {
              if (isLoading) return <Loading />;
              if (error) return `Something went wrong: ${error.message}`;
              if (data) {
                return (
                  <div>
                    <h2 className='title is-secondary'>In total, you have {data.reduce((a, b) => a + b.score, 0)} Bels collected.</h2>
                  </div>
                );
              }
            }}
          </Async>
        </StudentCard>
      </CardList>
      <h2 className="title is-secondary">Selected Peers @ {user.organisation.name}</h2>
      <Async promiseFn={fetchOrganisationPeers} id={user.organisation.id}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {orgPeers => (
            orgPeers.sort((a, b) => b.score - a.score).slice(0, 10).map((peer) => (

              <Async promiseFn={listUsersTopics} userId={peer.id}>
                {({ data, error, isLoading }) => {
                  if (isLoading) return <Loading />;
                  if (error) return `Something went wrong: ${error.message}`;
                  if (data) {
                    return (
                      <div>
                        <Card>
                          <h2 className='title is-secondary'>{peer.firstName} has {data.reduce((a, b) => a + b.score, 0)} Bels collected</h2>
                        </Card>
                      </div>
                    );
                  }
                }}

              </Async>
            ))
          )}
        </Async.Fulfilled>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>

    </div >
  )
}
