import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import API from '@/api'
import ActivityStream from '@/components/ActivityStream'
import CardList from '@/components/CardList'
import { Title } from '@/components/Meta'
import StatistcsGraphCard from '@/components/StatisticsGraphCard'
import Tag from '@/components/Tag'
import TagList from '@/components/TagList'
import UserList from '@/components/UserList'
import { useAuth } from '@/state/auth'
import '@/styles/AdminHome.css'
import 'intro.js/introjs.css';

const fetchLearnedSkills = ({ id }) => API.get(`/organisations/${id}/skills/learned`)
const fetchInactiveStudents = ({ id }) => API.get(`/organisations/${id}/students?filter[active_state]=inactive`)
const fetchLatestActivities = ({ id }) => API.get(`/organisations/${id}/activities`)
const fetchStudentStatistics = ({ id }) => API.get(`/organisations/${id}/statistics/students`)
const fetchProjectStatistics = ({ id }) => API.get(`/organisations/${id}/statistics/projects`)
const fetchSkillStatistics = ({ id }) => API.get(`/organisations/${id}/statistics/skills`)

export default function AdminHome() {
  const { user } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="container CodeDoor__AdminHome">

      <Title text={t('home.meta_title')} />

      <h1 className="title">{t('home.title', { name: user.firstName })}</h1>

      <header>
        <p className="lead">{t('home.admins.lead', { name: user.organisation.name })}</p>
      </header>

      <section className="cards">
        <CardList>
          <Async promiseFn={fetchStudentStatistics} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => <StatistcsGraphCard data={{
                label: t('vocab.students'),
                link: 'students',
                count: data.count,
                footer: <span><span className="primary">+{data.inTimeframe}</span> {t('home.admins.compared')}</span>,
              }} />
              }
            </Async.Fulfilled>
          </Async>
          <Async promiseFn={fetchProjectStatistics} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => <StatistcsGraphCard data={{
                label: t('vocab.projects'),
                link: 'projects',
                count: data.count,
                footer: <span><span className="primary">+{data.inTimeframe.length}</span> {t('home.admins.compared')}</span>,
              }} />
              }
            </Async.Fulfilled>
          </Async>
          <Async promiseFn={fetchSkillStatistics} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => <StatistcsGraphCard data={{
                label: t('vocab.skills'),
                link: 'skills',
                count: data.count,
                footer: <span><span className="primary">+{data.inTimeframe.length}</span> {t('home.admins.compared')}</span>,
              }} />
              }
            </Async.Fulfilled>
          </Async>
        </CardList>
      </section>
      <div className="container-66-33">
        <div>
          <h2 className="title is-secondary">{t('home.admins.latest')}</h2>
          <Async promiseFn={fetchLatestActivities} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => <ActivityStream activities={data} />}
            </Async.Fulfilled>
          </Async>
        </div>

        <div>
          <h2 className="title is-secondary">{t('home.admins.learned')}</h2>
          <Async promiseFn={fetchLearnedSkills} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => (
                <TagList>
                  {data.map(p => <Tag title={p.name} to={`/skills/${p.slug}`} key={p.id} />)}
                </TagList>
              )}
            </Async.Fulfilled>
          </Async>

          <h2 className="title is-secondary">{t('home.admins.inactive')}</h2>
          <Async promiseFn={fetchInactiveStudents} id={user.organisation.id}>
            <Async.Fulfilled>
              {data => <UserList users={data} className="is-inactive" />}
            </Async.Fulfilled>
          </Async>
        </div>
      </div>
    </div>
  )
}
