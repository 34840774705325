import React from 'react'
import classNames from 'classnames';
import ProjectOutlineItemEdit from '@/components/ProjectDefinitionOutlineItemEdit'

export default function ProjectDefinitionOutlineEdit({ definition, setDefinition, availableSkills }) {
  React.useEffect(() => {
    // console.log('ProjectDefinitionOutlineEdit render')
  }, [definition])
  return (
    <>
      <p className={classNames('label', { 'has-error': definition.outlineError })} >Outline</p>
      <div className="CodeDoor__ProjectOutline" >
        {
          definition?.outline?.map(
            (outlineItem, outlineItemIndex) =>
              <ProjectOutlineItemEdit
                definition={definition}
                key={outlineItem?.id || `${outlineItemIndex}-${outlineItemIndex}`}
                outlineItem={outlineItem}
                outlineItemIndex={outlineItemIndex}
                setDefinition={setDefinition}
                availableSkills={availableSkills}
              />
          )
        }
      </div>
    </>
  );
}
