import Button from './Button'
import Icon from './Icon'

function Grid () {
  return <Icon>
    <path d="M2.66699 7.33329H7.33366V2.66663H2.66699V7.33329ZM2.66699 13.3333H7.33366V8.66663H2.66699V13.3333ZM8.66699 13.3333H13.3337V8.66663H8.66699V13.3333ZM8.66699 2.66663V7.33329H13.3337V2.66663" fill="currentColor"/>
  </Icon>
}

function GridButton (props) {
  return <Button {...props}><Grid /></Button>
}

export default Grid
export { GridButton }
