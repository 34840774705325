import { createContext, useContext, useState } from 'react'
import auth from '@/api/auth'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'

const AuthContext = createContext()

/**
 * A hook that provides the auth state.
 */
function useProvideAuth () {
  const [user, setUser] = useState(storage.getItem(LOCALSTORAGE_KEYS.USER))

  return {
    user,

    get isAdmin () {
      return user?.role === 'ADMIN'
    },

    get isStudent () {
      return user?.role === 'STUDENT'
    },

    get isAuthenticated () {
      return user !== null
    },

    get isOnboarded () {
      return user.onboardedAt !== null
    },

    async signIn ([email, password]) {
      setUser(await auth.signIn({ email, password }))
    },

    async signUp ([code, password, firstName, lastName, email]) {
      setUser(await auth.signUp({ code, password, firstName, lastName, email }))
    },

    async signOut () {
      await auth.signOut()
      setUser(null)
      storage.removeItem(LOCALSTORAGE_KEYS.USER)
    },

    async update ([data = user]) {
      setUser(await auth.update(data))
    },

    setUser (updates) {
      setUser({
        ...user,
        ...updates,
      })
    },

    setUserOrganisation (updates) {
      setUser({
        ...user,
        organisation: {
          ...user.organisation,
          ...updates,
        },
      })
    },
  }
}

/**
 * The AuthContext state provider component.
 *
 * @param {object} props Props passed into the provider
 */
function ProvideAuth ({ children }) {
  const auth = useProvideAuth()

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

/**
 * A hook that uses the auth context.
 */
function useAuth () {
  return useContext(AuthContext)
}

export {
  ProvideAuth,
  useAuth,
}
