import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import 'intro.js/introjs.css';
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import Loading from '@/components/Loading'
import { useAuth } from '@/state/auth'

export default function TalkToAi() {
  const auth = useAuth()
  const [userid] = useState(auth.user.id)
  const today = (new Date().setHours(0, 0, 0, 0)).toString()
  const uniquesession = today.substr(0, 8) + userid.toString()
  const [answer, setAnswer] = useState(auth.user.answer)
  const [chatlog, setChatlog] = useState([])
  const { t } = useTranslation()
  const { isPending } = useAsync({ deferFn: auth.update })
  const [loadingA, setLoadingA] = useState(false);

  async function getA(answer) {
    try {
      setLoadingA(true);
      setChatlog(arr => [...arr, answer]);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answer, userid, uniquesession }),
      };
      // const valueA = await fetch(`https://containerapi.test/api/openai/chat`,  requestOptions )
      const valueA = await fetch(`https://container.tensorparc.com/api/openai/chat`, requestOptions)
      let text = await valueA.text();
      if (text === '') text = 'sorry, i have no idea.';
      setChatlog(arr => [...arr, text]);
      setAnswer('');
    } catch (e) {
      // ToDo proper error handling
    } finally {
      setLoadingA(false);
    }
  }

  const submit = event => {
    event.preventDefault()
    getA(answer)
  }

  return <Card className="CodeDoor__UserSettings__Profile is-large">
    <header>
      <h1 className="title"> </h1>
      <div className="actions" />
    </header>

    <div>
      {loadingA && <Loading />}
    </div>

    <div style={{ maxHeight: '16rem', overflowY: 'auto' }}>
      {chatlog.map((chat, index) =>
        <div key={index} className={(index % 2 === 0) ? 'message sent' : 'message received'}
          style={{
            margin: '1em',
            float: (index % 2 === 0) ? 'left' : 'right',
            padding: '1em',
            backgroundColor: (index % 2 === 0) ? '#e5e7eb' : '#9ca3af',
            color: (index % 2 === 0) ? '#374151' : '#fff',
          }} >{chat}</div>
      ).reverse()}
    </div>

    <form onSubmit={submit}>

      <FormGroup
      >
        <textarea
          className="CodeDoor__Input"
          rows="15"
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          placeholder={t('home.students.belture_expl')}
        />
      </FormGroup>

      <div className="actions">
        <Button className="tour-savebutton" type="submit" disabled={isPending}>{
          isPending
            ? 'send'
            : 'send'
        }</Button>
      </div>
    </form>
  </Card>
}
