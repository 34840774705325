import Avatar from './Avatar'
import Card from './Card'
import Email from './Icons/Email'
import Pin from './Icons/Pin'
import '@/styles/UserCard.css'

export function StudentCard({ user, to = `/peers/${user.id}`, children }) {
  return <UserCard user={user} to={to}>
    {
      children || <ul>
        <li>
          <Email />
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </li>
        <li className="BioCard">{user.bio}</li>
        <li>
          <Pin />
          <span>{user.location ? user.location : 'Time Traveler'}</span>
        </li>
      </ul>
    }
  </UserCard>
}

export default function UserCard({ user, to, children }) {
  return <Card to={to} className="CodeDoor__UserCard">
    <Avatar user={user} />

    <div className='CodeDoor__UserCard'>
      <h1>{user.fullName}</h1>
      {children}

    </div>
  </Card>
}
