import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { generateMap, TopicsMap } from './models/Topic'

const ProjectContext = createContext()

/**
 * A hook to use the project context.
 */
function useProject () {
  return useContext(ProjectContext)
}

/**
 * The ProjectContext state provider component.
 *
 * @param {object} props Props passed into the provider
 */
function ProvideProject ({ children, project }) {
  // Build Topic Tree
  generateMap(project)

  return (
    <ProjectContext.Provider value={project}>
      {children}
    </ProjectContext.Provider>
  )
}

/**
 * A hook to get the current topic.
 */
function useTopic () {
  const { topicId } = useParams()

  if (! topicId) return null

  return TopicsMap[topicId]
}

export {
  ProjectContext,
  ProvideProject,
  useProject,
  useTopic,
}
