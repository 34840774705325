import classNames from 'classnames'
import Github from'./Icons/Github'
import Link from './Icons/Link'
import Play from './Icons/Play'
import Slack from './Icons/Slack'
import '@/styles/ExternalLinkTag.css'

export default function ExternalLinkTag ({
  className,
  defaultIcon = Link,
  label,
  url,
  href = url,
  ...props
}) {
  let Icon = defaultIcon
  if (url.includes('youtu')) Icon = Play
  if (url.includes('github')) Icon = Github
  if (url.includes('slack.com')) Icon = Slack

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      title={label}
      className={classNames('CodeDoor__ExternalLinkTag', className)}
      {...props}
    >
      <span className="icon"><Icon /></span>
      <span className="text">{label}</span>
    </a>
  )
}
