import classNames from 'classnames'
import { useAuth } from '@/state/auth'
import Avatar from './Avatar'
import UserLink from './UserLink'
import '@/styles/UserGroup.css'

export function AvatarList ({ users, link = true, max = 3 }) {
  return <div className="CodeDoor__AvatarList">
    {
      users
        .slice(0, max)
        .map(user => (
          <Avatar user={user} link={link} key={user.id} />
        ))
    }
  </div>
}

export function PlainUserNameList ({ prepend = '', users }) {
  return users.length === 1
    ? `${prepend}${users[0].fullName}`
    : users.length === 2
      ? `${prepend}${users[0].firstName} and ${users[1].firstName}`
      : `${prepend}${users[0].firstName}, ${users[1].firstName} +${users.length - 2} more`
}

export function UserNameList ({ prepend = '', users }) {
  const { user } = useAuth()

  return <div className="CodeDoor__UserNameList">
    {
      users.length === 1
        ? users[0].id === user.id ? 'Solo project' : <span>{prepend}<UserLink user={users[0]} /></span>
        : users.length === 2
          ? <span>{prepend}<UserLink user={users[0]} /> and <UserLink user={users[1]} /></span>
          : <span>{prepend}<UserLink user={users[0]} />, <UserLink user={users[1]} /> +{users.length - 2} more</span>
    }
  </div>
}

export default function UserGroup ({ children, className, users }) {
  return (
    <div className={classNames('CodeDoor__UserGroup', className)}>
      <AvatarList users={users} />
      <UserNameList users={users} prepend="with " />
      { children }
    </div>
  )
}
