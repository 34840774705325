import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { Title } from '@/components/Meta'
import MyProjects from '@/components/MyProjects'
import OrganisationProjects from '@/components/OrganisationProjects'
import { useAuth } from '@/state/auth'
import '@/styles/ProjectEditor/ProjectEditor.css'

function AdminProjects() {
  const { t } = useTranslation()
  return (
    <div className="container">
      <Title text={t('projects.title')} />
      <div className="create-project-container">
        <h1 className="title">{t('projects.title')}</h1>
        <Button className="is-large" to='/project-definition/new'>{t('projects.admin.define-project.cta')}</Button>
      </div>
      <OrganisationProjects />
    </div>
  )
}

function StudentProjects() {
  const { t } = useTranslation()
  return (
    <div className="container">
      <Title text={t('projects.title')} />
      <h1 className="title">{t('projects.title')}</h1>
      <MyProjects />
    </div>
  )
}

export default function Projects() {
  const { isAdmin } = useAuth()
  return isAdmin ? <AdminProjects /> : <StudentProjects />
}
