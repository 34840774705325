import '../styles/NewlineText.css'

const NewlineText = ({ text, ...props }) => {

  return text
    ? <div className="__CodeDoor_NewlineText" {...props} >
      {text.split('\n').map((line, index) => <p key={index}>{line}</p>)}
    </div>
    : null
}
export default NewlineText