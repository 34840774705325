import { useState } from 'react'
import { Async, useAsync } from 'react-async'
// import { useTranslation } from 'react-i18next'
import 'intro.js/introjs.css';
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import Loading from '@/components/Loading'
import { useAuth } from '@/state/auth'

const roboFeedback = async ({ answer, description }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ answer, description }),
  };
  const res = await fetch(`https://container.tensorparc.com/api/openai/robofeedback`, requestOptions)
  if (!res.ok) throw new Error(res.statusText)
  return res.json()
}

export default function AiFeedback({ topic }) {
  const auth = useAuth()
  const [userid] = useState(auth.user.id)
  const today = (new Date().setHours(0, 0, 0, 0)).toString()
  const uniquesession = today.substr(0, 8) + userid.toString()
  const [answer, setAnswer] = useState(auth.user.answer)
  const [chatlog, setChatlog] = useState([])
  // const { t } = useTranslation()
  const { isPending } = useAsync({ deferFn: auth.update })
  const [loadingA, setLoadingA] = useState(false);

  const [showHint, setShowHint] = useState(false)

  async function getA(answer) {
    try {
      setLoadingA(true);
      setChatlog(arr => [...arr, answer]);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answer, userid, uniquesession }),
      };
      // const valueA = await fetch(`https://containerapi.test/api/openai/chat`,  requestOptions )
      const valueA = await fetch(`https://container.tensorparc.com/api/openai/chat`, requestOptions)
      let text = await valueA.text();
      if (text === '') text = 'You are too smart for me. I need to ask my mom.';
      setChatlog(arr => [...arr, text]);
      setAnswer('');
    } catch (e) {
      // ToDo proper error handling
    } finally {
      setLoadingA(false);
    }
  }

  const submit = event => {
    event.preventDefault()
    getA(answer)
  }

  return <Card className="CodeDoor__UserSettings__Profile is-large">
    {!showHint && <Button onClick={() => setShowHint(true)}>Hey Codolino, please give me feedback!</Button>}
    {showHint && <div>
      <header>
        <h1 className="title"> </h1>
        <div className="actions" />
      </header>

      <div>
        {loadingA && <Loading />}
      </div>

      <div style={{ maxHeight: '16rem', overflowY: 'auto' }}>
        {chatlog.map((chat, index) =>
          <div key={index} className={(index % 2 === 0) ? 'message sent' : 'message received'}
            style={{
              margin: '1em',
              float: (index % 2 === 0) ? 'left' : 'right',
              padding: '1em',
              backgroundColor: (index % 2 === 0) ? '#e5e7eb' : '#9ca3af',
              color: (index % 2 === 0) ? '#374151' : '#fff',
            }} >{chat}</div>
        ).reverse()}
        <Async promiseFn={roboFeedback} answer={topic.answer.body} description={topic.description}>
          {({ data, error, isPending }) => {
            if (isPending) return (<p className="lead"><Loading /></p>)
            if (error) return (<p className="lead">Codolino got an error: ${error.message}</p>)
            if (data) {
              return (
                <div key="0" className='message received'
                  style={{
                    margin: '1em',
                    float: 'right',
                    padding: '1em',
                    backgroundColor: '#9ca3af',
                    color: '#fff',
                  }} >{data.response}</div>
              )
            }
            return null
          }}
        </Async>
      </div>

      <form onSubmit={submit}>

        <FormGroup
        >
          <textarea
            className="CodeDoor__Input"
            rows="4"
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            placeholder='You can talk to Codolino here and discuss this part of the project.'
          />
        </FormGroup>

        <div className="actions">
          <Button className="tour-savebutton" type="submit" disabled={isPending}>{
            isPending
              ? 'send >'
              : 'send >'
          }</Button>
        </div>
      </form>
    </div>
    }
  </Card>
}
