import classNames from 'classnames'
import UserList from '@/components/UserList'
import '@/styles/Comment.css'

export default function Comment ({ comment, className, ...props })
{
  return <div
    className={classNames('CodeDoor__Comment', className)}
    {...props}
  >
    <UserList users={[comment.user]}/>
    <div className="text">{comment.body}</div>
  </div>
}
