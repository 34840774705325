import { useTranslation } from 'react-i18next'
import LinkIcon from '@/components/Icons/Link'
import striphtml from '@/helpers/striphtml'
import ExternalLinkTag from '../ExtenalLinkTag'
import UserLink from '../UserLink'

export default function ProjectAnswerAttachmentActivity ({ activity }) {
  const { data } = activity
  const { attachment, topic, oldData } = data
  const { t } = useTranslation()

  const title = `activities.descriptions.${data.action}_${activity.type}`
  return <>
    <div>
      <div
        className="CodeDoor__Activity__PROJECT_PROGRESS__Answer"
        key={activity.id}
      >
        <LinkIcon className="is-active" />
        <div className="text">
          <strong>{topic.name} ({t(title)})
            {attachment.user && (
              <span className="owner">
                - by <UserLink user={attachment.user}>{attachment.user.fullName}</UserLink>
              </span>
            )}
          </strong>
          {striphtml(data.topic.description)}
        </div>
        <div className="attachments">

          {oldData
            ? (
              <div className="comparison">
                <div>
                  <h3 className="title">Old</h3>
                  <ExternalLinkTag url={oldData.url} label={oldData.name} />
                </div>
                <div>
                  <h3 className="title">New</h3>
                  <ExternalLinkTag url={attachment.url} label={attachment.name} />
                </div>
              </div>
            )
            : <div className="comparison">
              <div>
                <ExternalLinkTag url={attachment.url} label={attachment.name} />
              </div>
            </div>
          }
        </div>

      </div>
    </div>
  </>
}
