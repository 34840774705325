import { withRouter } from 'react-router-dom'
import { MenuButton } from '@/components/Icons/Menu'
import { useAppState } from '@/state/app'
import { useTopic } from '@/state/project'
import Breadcrumbs from './Breadcrumbs'
import ElementNavigationItem from './ElementNavigationItem'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'
import '@/styles/Editor/TopBar.css'

function TopBar () {
  const { navigationOpen, toggleNavigation } = useAppState()
  const topic = useTopic()

  // Fixme - cache this
  topic.parent?.updateProgress()

  return (
    <header className="CodeDoor__Editor__TopBar">
      <div className="left">
        { ! navigationOpen && (
          <MenuButton
            onClick={() => toggleNavigation()}
            className="open"
            aria-label="Open Project Navigation"
          />
        )}
        <Breadcrumbs />
      </div>
      <div className="center">
        { topic.parent?.children.map(t => <ElementNavigationItem topic={t} key={t.id} />) }
      </div>
      <div className="right">
        <PreviousButton />
        <NextButton />
      </div>
    </header>
  )
}

export default withRouter(TopBar)
