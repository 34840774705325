import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import classNames from 'classnames'
import API from '@/api'
import { useAuth } from '@/state/auth'
import Button from './Button'
import '@/styles/OnboardingModal.css'

function OnboardingModal () {
  const { t } = useTranslation()
  const [isWorking, setIsWorking] = useState(false)
  const [currentProgress, setCurrentProgress] = useState(0)
  const { isAdmin, setUser } = useAuth()

  const onboardingGreet = isAdmin ? t('home.admins.onboarding.greet') : t('home.students.onboarding.greet')
  const onboardingSteps = isAdmin
    ? [
      {
        title: t('home.students.onboarding.title1'),
        content: t('home.students.onboarding.step1'),
      },
      {
        title: t('home.students.onboarding.title2'),
        content: t('home.students.onboarding.step2'),
      },
    ]
    : [
      {
        title: t('home.admins.onboarding.title1'),
        content: t('home.admins.onboarding.step1'),
      },
      {
        title: t('home.admins.onboarding.title2'),
        content: t('home.admins.onboarding.step2'),
      },
    ]

  const hasMultipleSteps = onboardingSteps.length > 1

  const handlePrev = () => {
    if (currentProgress < 1) return false
    setCurrentProgress(currentProgress - 1)
  }
  const handleNext = () => {
    if (currentProgress === onboardingSteps.length - 1) return false
    setCurrentProgress(currentProgress + 1)
  }

  const onboardUser = async () => {
    setIsWorking(true)
    const response = await API.put('/user/onboard')
    setUser(response)
  }

  const currentStep = onboardingSteps[currentProgress];
  return (
    <div>
      <Modal
        className="CodeDoor__OnboardingModal"
        isOpen
        closeTimeoutMS={300}
      >
        <header>
          <h1 className="title">{onboardingGreet}</h1>
        </header>
        <section>
          <h2>{currentStep.title}</h2>
          <div>
            {currentStep.content}
          </div>
        </section>
        <footer className={classNames({ 'multiple': hasMultipleSteps })}>
          {
            hasMultipleSteps && !currentProgress === 0
              ? <Button variant="secondary" onClick={handlePrev}>Back</Button>
              : <div />
          }
          {
            currentProgress === onboardingSteps.length - 1
              ? <Button disabled={isWorking} onClick={onboardUser}>Close</Button>
              : <Button onClick={handleNext}>Next</Button>
          }
        </footer>
      </Modal>
    </div >
  )
}

export default OnboardingModal
