import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import Circle from '@/components/Icons/Circle'
import CircleCheck from '@/components/Icons/CircleCheck'
import { useTrackAnswer } from '@/state/models/Answer'
import '@/styles/Editor/ElementNavigationItem.css'

function ElementNavigationItem ({ topic }) {
  const { path } = useRouteMatch()
  const match = useRouteMatch(path.replace(':topicId', topic.id))
  useTrackAnswer(topic)

  return (
    <Link
      to={`${topic.id}`}
      className={classNames('CodeDoor__Editor__ElementNavigationItem', match ? 'is-active' : '')}
      aria-label={topic.name}
    >{
        topic.isComplete
          ? <CircleCheck />
          : <Circle />
      }</Link>
  )
}

export default ElementNavigationItem
