import Button from './Button'
import Icon from './Icon'

function List () {
  return <Icon>
    <path d="M5.33333 2.66663V5.33329H14V2.66663H5.33333ZM5.33333 13.3333H14V10.6666H5.33333V13.3333ZM5.33333 9.33329H14V6.66663H5.33333V9.33329ZM2 5.33329H4.66667V2.66663H2V5.33329ZM2 13.3333H4.66667V10.6666H2V13.3333ZM2 9.33329H4.66667V6.66663H2V9.33329Z" fill="currentColor"/>
  </Icon>
}

function ListButton (props) {
  return <Button {...props}><List /></Button>
}

export default List
export { ListButton }
