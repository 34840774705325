// import { useTranslation } from 'react-i18next'
import Belture from '@/components/Belture'
import { Title } from '@/components/Meta'

export default function Skills() {
  // const { t } = useTranslation()

  return (
    <div className="container">
      <Title text='welcome to the belture learning experience' />
      <h1 className="title">  Belture will help you formulate the right questions</h1>
      <h2 className="title is-secondary">What do you want to learn today? Belture will give you key questions. For each set of answers, you'll get one BEL <span
        className="skills"
        style={{
          display: 'inline-flex',
          padding: '0 2px',
          borderRadius: '4px',
          whiteSpace: 'break-spaces',
          color: '#000',
          height: '24px',
        }}><img src="https://i.imgur.com/Woz22t2.png?1" alt="bel logo" style={{
          marginLeft: '3px',
        }} /></span> . The BELs stack up toward a topic that Belture sets for you.</h2>
      <Belture />
    </div>
  )
}
