import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { onEnter } from '@/helpers/key-press'
import '@/styles/Button.css'

function Button ({ onClick, to, type = 'button', variant = 'primary', children, className, ...props }) {
  const classes = classNames('CodeDoor__Button', className, `CodeDoor__Button__${variant}`)

  return to
    ? <Link to={to} className={classes}>{children}</Link>
    : <button
      type={type}
      onClick={onClick}
      onKeyPress={onEnter(onClick)}
      className={classes} {...props}
    >{children}</button>
}

export default Button
