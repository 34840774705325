import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'
import EditorSettings from './EditorSettings'
import ElementEditor from './ElementEditor'
import ElementSidebar from './ElementSidebar'
import TopBar from './TopBar'

import '@/styles/Editor/EditorLayout.css'

function useSaveLastLocation () {
  const location = useLocation()

  useEffect(() => {
    storage.setItem(LOCALSTORAGE_KEYS.LAST_LOCATION, location.pathname)
  }, [location])
}

function EditorLayout () {
  useSaveLastLocation()

  return <div className="CodeDoor__Editor__EditorLayout">
    <TopBar />
    <ElementEditor />
    <ElementSidebar />
    <EditorSettings />
  </div>
}

export default EditorLayout
