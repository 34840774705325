import { useState } from 'react'
import Button from '@/components/Button'
import Check from '@/components/Icons/Check'
import striphtml from '@/helpers/striphtml'
import UserLink from '../UserLink'

export default function ProjectProgressActivity ({ activity }) {
  const [expanded, setExpanded] = useState(false)

  const answers = expanded
    ? activity.data.answers
    : activity.data.answers.slice(0, 2)

  return <>
    <div>
      {
        answers.map(answer => (
          <div
            className="CodeDoor__Activity__PROJECT_PROGRESS__Answer"
            key={answer.topic.id}
          >
            <Check className="is-active" />
            <div className="text">
              <strong>{answer.topic.name}
                {answer.user && (
                  <span className="owner">
                    - by <UserLink user={answer.user}>{answer.user.fullName}</UserLink>
                  </span>
                )}
              </strong>
              {striphtml(answer.topic.description)}
            </div>
            <div className="mono">{answer.body}</div>
          </div>
        ))
      }
    </div>
    {
      activity.data.answers.length > 2 && (
        <Button onClick={() => setExpanded(!expanded)} variant="secondary" className="is-small">
          {expanded ? 'View less' : 'View all'}
        </Button>
      )
    }
  </>
}
