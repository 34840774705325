import { Link } from 'react-router-dom'
import NavigationTopic from '@/components/Editor/NavigationTopic'
import { CloseButton } from '@/components/Icons/Close'
import { useAppState } from '@/state/app'
import { TopicsMap } from '@/state/models/Topic'
import { useProject } from '@/state/project'
import '@/styles/Editor/ProjectNavigation.css'

function ProjectNavigation () {
  const { toggleNavigation } = useAppState()
  const project = useProject()

  return (
    <aside className="CodeDoor__Editor__ProjectNavigation">
      <header>
        <Link to={`/projects/${project.id}`}>
          <img src={`${process.env.PUBLIC_URL  }/cddr-logo.png`} alt="Codedoor Logo"/>
          <h1>{project.name}</h1>
        </Link>
        <CloseButton onClick={() => toggleNavigation()} className="close" aria-label="Close Project Navigation" />
      </header>

      <main>
        <h1 className="label">Topics</h1>
        <ul>
          { project.topics
            .map(({ id }) => TopicsMap[id])
            .map(topic => <NavigationTopic topic={topic} key={topic.id} />)
          }
        </ul>
      </main>
    </aside>
  )
}

export default ProjectNavigation
