import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import Check from '@/components/Icons/Check'
import Button from './Button'

export default function SuccessModal ({ open, message = 'yes that worked!', onCloseSuccess }) {
  const { t } = useTranslation()

  return <>
    <Modal isOpen={open} onRequestClose={onCloseSuccess} closeTimeoutMS={300} className="with-icon">
      <Check class="success" />
      <h1 className="title">{t('vocab.success')}</h1>
      <p className="lead">{message}</p>
      <footer>
        <Button onClick={onCloseSuccess}>{t('vocab.ok')}</Button>
      </footer>
    </Modal>
  </>

}
