export const VIEW = {
  CARD: 'card',
  TABLE: 'table',
}

export const SORTS = {
  NONE: 'NONE',
  ASC: 'ASC',
  DESC: 'DESC',
}
