import { useTranslation } from 'react-i18next'
import { Title } from '@/components/Meta'
import OrganisationProfile from './OrganisationProfile'
import '@/styles/OrganisationSettings.css'

export default function Organisation () {
  const { t } = useTranslation()

  return <div className="container CodeDoor__OrganisationSettings">
    <Title text={t('settings.organisation.title')} />

    <OrganisationProfile />
  </div>
}
