import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import '@/styles/Dropdown.css'

export const Dropdown         = props =>  <DropdownMenu.Root
  {...props}
  className="CodeDoor__Dropdown CodeDoor__Dropdown__Root"
/>
export const DropdownTrigger  = props =>  <DropdownMenu.Trigger
  {...props}
  className="CodeDoor__Dropdown CodeDoor__Dropdown__Trigger"
/>
export const DropdownContent  = props =>  <DropdownMenu.Content
  {...props}
  className="CodeDoor__Dropdown CodeDoor__Dropdown__Content"
/>
export const DropdownItem     = props =>  <DropdownMenu.Item
  {...props}
  className="CodeDoor__Dropdown CodeDoor__Dropdown__Item"
/>
