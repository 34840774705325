import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import 'intro.js/introjs.css';
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import { AvatarPicker } from '@/components/ImagePicker'
import Input from '@/components/Input'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'

export default function UserProfile() {
  const auth = useAuth()
  const [email, setEmail] = useState(auth.user.email)
  const [firstName, setFirstName] = useState(auth.user.firstName)
  const [lastName, setLastName] = useState(auth.user.lastName)
  const [bio, setBio] = useState(auth.user.bio)
  const [githubUsername, setGithubUsername] = useState(auth.user.githubUsername)
  const [slackUsername, setSlackUsername] = useState(auth.user.slackUsername)
  const [location, setLocation] = useState(auth.user.location)
  const { t } = useTranslation()
  const { error, isPending, run } = useAsync({ deferFn: auth.update })
  const [buttonText, setButtonText] = useState('Save')
  const changeText = (text) => setButtonText(text);

  const submit = event => {
    event.preventDefault()

    run({
      email,
      firstName,
      lastName,
      bio,
      githubUsername,
      slackUsername,
      location,
    })
  }

  return <Card className="CodeDoor__UserSettings__Profile is-large">
    <header>
      <h1 className="title">{t('settings.user.profile.title')}</h1>
    </header>

    <p className="label">{t('vocab.photo')}</p>
    <AvatarPicker />

    <form onSubmit={submit}>
      <FormGroup
        label={t('vocab.first_name')}
        error={getValidationMessage('first_name', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="John"
          value={firstName}
          onChange={setFirstName}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.last_name')}
        error={getValidationMessage('last_name', error)}
      >
        <Input
          type="text"
          placeholder="Doe"
          value={lastName}
          onChange={setLastName}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.bio')}
        error={getValidationMessage('bio', error)}
      >
        <textarea
          className="CodeDoor__Input"
          rows="4"
          value={bio}
          onChange={e => setBio(e.target.value)}
          placeholder={t('settings.user.profile.bio_placeholder')}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.email')}
        error={getValidationMessage('email', error)}
      >
        <Input
          type="email"
          placeholder="john.doe@example.com"
          value={email}
          onChange={setEmail}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.location')}
        error={getValidationMessage('location', error)}
      >
        <Input
          type="text"
          placeholder="Neverland"
          value={location}
          onChange={setLocation}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.github_username')}
      >
        <Input
          type="text"
          placeholder="peter_pan"
          value={githubUsername}
          onChange={setGithubUsername}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.slack_username')}
        error={getValidationMessage('slack_username', error)}
      >
        <Input
          type="text"
          placeholder="U012A3B45"
          value={slackUsername}
          onChange={setSlackUsername}
        />
      </FormGroup>

      <div className="actions">
        <Button className="tour-savebutton" type="submit" disabled={isPending} onClick={() => changeText('Saved! Looking Good!')}>{
          isPending
            ? t('vocab.submitting')
            : buttonText
        }</Button>
      </div>
    </form>
  </Card>
}
