import { Async } from 'react-async'
import day from '@/helpers/day'
import Avatar from './Avatar'
import Button from './Button'
import Table from './Table'
import '@/styles/StudentsTable.css'

export default function StudentsTable({ sorted, nextSort }) {
  return <Table className="CodeDoor__StudentsTable is-framed">
    <Table.Head>
      <Table.TH />
      <Table.TH
        label="Name"
        sort={sorted('name')}
        onClick={() => nextSort('name')}
        sortable
      />
      <Table.TH
        label="Email"
        sort={sorted('email')}
        onClick={() => nextSort('email')}
        sortable
      />
      <Table.TH
        label="Projects"
        sort={sorted('projectsCount')}
        onClick={() => nextSort('projectsCount')}
        sortable
      />
      <Table.TH
        label="Skills"
        sort={sorted('skillsCount')}
        onClick={() => nextSort('skillsCount')}
        sortable
      />
      <Table.TH
        label="Last Active"
        sort={sorted('lastLogin')}
        onClick={() => nextSort('lastLogin')}
        sortable
      />
      <Table.TH />
    </Table.Head>
    <Async.Fulfilled>
      {
        students => (
          <Table.Body>
            {
              students.map(u => (
                <Table.Row key={u.id}>
                  <Table.TD>
                    <Avatar user={u} />
                  </Table.TD>
                  <Table.TD>{u.fullName}</Table.TD>
                  <Table.TD>{u.email}</Table.TD>
                  <Table.TD>{u.projectsCount}</Table.TD>
                  <Table.TD>{u.skillsCount}</Table.TD>
                  <Table.TD>{u.lastLogin ? day (u.lastLogin).calendar() : 'Never'}</Table.TD>
                  <Table.TD>
                    <Button
                      to={`/students/${u.id}`}
                      variant="secondary"
                      className="is-small"
                    >View</Button>
                  </Table.TD>
                </Table.Row>
              ))
            }
          </Table.Body>
        )
      }
    </Async.Fulfilled>
  </Table>
}
