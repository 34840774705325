import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import day from '@/helpers/day'
import ProjectAnswerAttachmentActivity from './ProjectAnswerAttachmentActivity'
import ProjectAttachmentActivity from './ProjectAttachmentActivity'
import ProjectCommentActivity from './ProjectCommentActivity'
import ProjectProgressActivity from './ProjectProgressActivity'
import '@/styles/Activity.css'

const types = {
  PROJECT_COMMENT: ProjectCommentActivity,
  PROJECT_PROGRESS: ProjectProgressActivity,
  PROJECT_ATTACHMENT: ProjectAttachmentActivity,
  ANSWER_ATTACHMENT: ProjectAnswerAttachmentActivity,
}

export default function Activity ({
  activity,
  withDescription = false,
  withTargetLink = false,
  ...props
}) {
  const { t } = useTranslation()

  return <div
    className={classNames('CodeDoor__Activity', `CodeDoor__Activity__${activity.type}`)}
    {...props}
  >
    <p>
      {withDescription && <span>{t(`activities.descriptions.${activity.type}`, activity)}</span>}
      {day(activity.createdAt).calendar()}
    </p>

    {types[activity.type]({ activity })}

    {withTargetLink && (
      <Link to={t(`activities.targetLinks.${activity.type}`, activity)} className="CodeDoor__Activity__Target_Link">Visit</Link>
    )}
  </div>
}
