import { useState } from 'react'
import Activity from './Activities/Activity'
import AddComment from './AddComment'
import '@/styles/ProjectActivities.css'

export default function ProjectActivities ({ activities: items }) {
  const [activities, setActivities] = useState(items)

  return <section className="activities">
    <p className="label">Latest Activity</p>

    <AddComment
      onCreated={comment => setActivities([
        {
          id: Date.now(),
          createdAt: comment.createdAt,
          type: 'PROJECT_COMMENT',
          object: comment,
        },
        ...activities,
      ])}
    />

    <div className="CodeDoor__ProjectActivities">
      {
        activities?.map(activity => <Activity activity={activity} key={activity.id} />)
      }
    </div>
  </section>
}
