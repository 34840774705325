import { useCallback, useState } from 'react'
import { Async, useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import API from '@/api'
import Button from '@/components/Button'
import FormGroup from '@/components/FormGroup'
import Input from '@/components/Input'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'

const validateInvite = ({ code }) => API.get(`/invites/validate/${code}`)

export default function Register () {
  const { code } = useParams()
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState()
  const auth = useAuth()
  const { t } = useTranslation()

  const { error, isPending, run } = useAsync({ deferFn: auth.signUp })

  const handleSubmit = async event => {
    event.preventDefault()
    run({ code, password, firstName, lastName, email })
  }

  const setData = useCallback(
    result => setEmail(result.email),
    [setEmail]
  )

  return (
    <>
      <Title text={t('auth.register.title')} />

      <h1>{t('auth.register.title')}</h1>

      <Async promiseFn={validateInvite} code={code} onResolve={setData}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          { () => (
            <form onSubmit={handleSubmit}>
              <FormGroup
                label={t('vocab.first_name')}
                error={getValidationMessage('firstName', error)}
              >
                <Input
                  type="text"
                  autoFocus
                  placeholder="John"
                  value={firstName}
                  onChange={setFirstName}
                />
              </FormGroup>

              <FormGroup
                label={t('vocab.last_name')}
                error={getValidationMessage('lastName', error)}
              >
                <Input
                  type="text"
                  placeholder="Doe"
                  value={lastName}
                  onChange={setLastName}
                />
              </FormGroup>

              <FormGroup
                label={t('vocab.password')}
                error={getValidationMessage('password', error)}
              >
                <Input
                  type="password"
                  autoComplete="new-password"
                  placeholder="*********"
                  value={password}
                  onChange={setPassword}
                />
              </FormGroup>

              <div className="actions">
                <Button type="submit" disabled={isPending}>{
                  isPending
                    ? t('vocab.submitting')
                    : t('auth.register.cta')
                }</Button>
              </div>
            </form>
          )}
        </Async.Fulfilled>
        <Async.Rejected>
          <div className="flex-gap">
            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height={36} width={36}>
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg></div>
            <div>{t('auth.register.invalid_code')}</div>
          </div>
        </Async.Rejected>
      </Async>
    </>
  )
}
