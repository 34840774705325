import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import Circle from '@/components/Icons/Circle'
import CircleCheck from '@/components/Icons/CircleCheck'
import '@/styles/Editor/NavigationItem.css'

function NavigationItem ({ label, to, isComplete = false, children }) {
  const match = useRouteMatch({
    path: to,
  })

  return <li className={classNames('CodeDoor__Editor__NavigationItem', match ? 'is-active' : '')}>
    <Link to={to}>
      {
        isComplete
          ? <CircleCheck />
          : <Circle />
      }
      {label}
    </Link>
    { match && children && children.length
      ? <ul>{children}</ul>
      : null
    }
  </li>
}

export default NavigationItem
