import { useTranslation } from 'react-i18next'
import day from '@/helpers/day'
import Button from './Button'
import Table from './Table'
import { AvatarList, PlainUserNameList, UserNameList } from './UserGroup'
import '@/styles/ProjectsTable.css'

export default function ActiveProjectsTable ({ projects }) {
  const { t } = useTranslation()

  if (!projects?.length) return null

  return <Table className="CodeDoor__ProjectsTable">
    <Table.Head>
      <Table.TH />
      <Table.TH label="Team" />
      <Table.TH label="Progress" />
      <Table.TH label="Last Active" />
      <Table.TH label="Mentorship" />
      <Table.TH />
    </Table.Head>
    <Table.Body>
      {
        projects.map(p => (
          <Table.Row key={p.id}>
            <Table.TD>
              <AvatarList users={p.students} />
            </Table.TD>
            <Table.TD>
              <UserNameList users={p.students} />
            </Table.TD>
            <Table.TD>{p.progress}%</Table.TD>
            <Table.TD>{day(p.updatedAt).calendar()}</Table.TD>
            <Table.TD>
              {
                p.mentors?.length
                  ? <PlainUserNameList users={p.mentors || []} />
                  : t('vocab.mentorship_empty')
              }
            </Table.TD>
            <Table.TD>
              <Button
                to={`/projects/${p.id}`}
                variant="secondary"
                className="is-small"
              >{t('vocab.view')}</Button>
            </Table.TD>
          </Table.Row>
        ))
      }
    </Table.Body>
  </Table>
}
