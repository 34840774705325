import Check from '@/components/Icons/Check';
import Progress from '@/components/Progress'
import { useTrackAnswer } from '@/state/models/Answer'
import { useTopic } from '@/state/project'
import ExternalLinkTag from '../ExtenalLinkTag'
import HintModal from './HintModal'
import RoboAdvisor from './RoboAdvisor'

import '@/styles/Editor/ElementSidebar.css'
import '@/styles/ResourceLink.css'

function ElementSidebar() {
  const topic = useTopic()
  useTrackAnswer(topic.answer)

  const progressHelpText = topic.progress < 60
    ? `Please continue working on the topic.`
    : `Congratulations, you have completed this exercise! You can continue to the next lesson.`

  return (
    <aside className="CodeDoor__Editor__ElementSidebar">
      <section>
        <h1 className="label">Your Progress</h1>
        <p className="sub">{progressHelpText}</p>

        <div className="CodeDoor__Statistic">
          {topic.isComplete && <span className="check"><Check /></span>}
          <Progress percentage={topic.progress} />
        </div>
      </section>
      <section>
        <h1 className="label">Stuck?</h1>

        <HintModal topic={topic} className="help" />

        {topic.robo === 1 && <section className='margin_top'>
          <RoboAdvisor topic={topic} className="help" />
        </section>
        }
        <ExternalLinkTag
          url={`https://slack.com/app_redirect?channel=${process.env.REACT_APP_SLACK_CHANNEL_ID}`}
          label="Talk to a human"
          className="CodeDoor__ResourceLink always-active help"
        />
      </section>
    </aside>
  )
}

export default ElementSidebar
