import React, { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import classNames from 'classnames'
import API from '@/api'
import dataURItoBlob from '@/helpers/dataURItoBlob'
import { useAuth } from '@/state/auth'
import Avatar from './Avatar'
import Button from './Button'
import Camera from './Icons/Camera'
import '@/styles/ImagePicker.css'

const validateFiles = (files) => {
  const oneMB = 1024 * 1024
  const acceptedFileTypes = [
    'image/x-png',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
  ]
  if (files && files.length > 0) {
    const currentFile = files[0]
    const { type, size } = currentFile

    if (!acceptedFileTypes.includes(type)) {
      alert('This file is not allowed. Only images are allowed.')
      return false
    }
    if (size > oneMB) {
      const sizeInMB = (size / oneMB).toFixed(2)
      alert(`Image too big (${sizeInMB}MB) max is 1MB)`)
      return false
    }
    return true
  }
}

const ImagePicker = ({ round = false, user, asyncFn, orgLogo = false }) => {
  const editorRef = useRef(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [droppedImage, setDroppedImage] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [editorScale, setEditorScale] = useState(1.2)
  const { t } = useTranslation()

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles.length > 0) validateFiles(rejectedFiles)
    if (files && files.length > 0) {
      const isVerified = validateFiles(files)
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0]
        setDroppedImage(currentFile)
        setModalOpen(true)
      }
    }
  }

  const handleEditorSave = () => {
    if (!editorRef) return false
    setIsUpdating(true)
    setModalOpen(false)
    const canvasScaled = editorRef.current.getImageScaledToCanvas()
    const base64Image = canvasScaled.toDataURL()
    const formData = new FormData()
    formData.append('image', dataURItoBlob(base64Image))
    asyncFn(formData, () => setIsUpdating(false))
  }

  return (
    <div
      className={classNames('CodeDoor__ImagePicker', {
        round,
        'is-updating': isUpdating,
      })}
    >
      {orgLogo ? (
        user.organisation.logoUrl && (
          <img
            className="logo"
            src={user.organisation.logoUrl}
            alt={user.organisation.name}
          />
        )
      ) : (
        <Avatar user={user} />
      )}
      <Dropzone onDrop={handleOnDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <span className={classNames('image-selector', isDragActive && 'is-dragging')} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="caption">
              <Camera width={40} height={40} dimention={24} />
            </div>
          </span>
        )}
      </Dropzone>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        closeTimeoutMS={300}
      >
        <h1 className="title">{t('vocab.crop_image')}</h1>
        <div className="CodeDoor__ImageEditorContainer">
          <AvatarEditor
            className="CodeDoor__ImageEditor"
            ref={editorRef}
            image={droppedImage}
            width={200}
            height={200}
            border={100}
            color={[255, 255, 255, 0.8]} // RGBA
            scale={Number(editorScale)}
            rotate={0}
          />
          <br />
          <input
            type="range"
            min="1"
            max="3"
            step="0.05"
            value={editorScale}
            onChange={({ target }) => setEditorScale(target.value)}
          />
        </div>
        <footer>
          <Button variant="secondary" onClick={() => setModalOpen(!modalOpen)}>
            Close
          </Button>
          <Button onClick={handleEditorSave}>{t('vocab.save')}</Button>
        </footer>
      </Modal>
    </div>
  )
}

const AvatarPicker = (props) => {
  const { user, setUser } = useAuth()
  const updateFn = async (formData, callback) => {
    const { avatarUrl } = await API.upload(`/user/avatar`, formData)
    setUser({ avatarUrl })
    callback()
  }

  return <ImagePicker user={user} asyncFn={updateFn} {...props} round />
}

const LogoPicker = (props) => {
  const { user, setUserOrganisation } = useAuth()
  const updateFn = async (formData, callback) => {
    const { logoUrl } = await API.upload(`/organisations/${user.organisation.id}/logo`, formData)
    setUserOrganisation({ logoUrl })
    callback()
  }

  return <ImagePicker user={user} asyncFn={updateFn} orgLogo {...props} />
}

export { ImagePicker, AvatarPicker, LogoPicker }
