import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router-dom'
import API from '@/api'
import AttachmentList from '@/components/AttachmentList'
import Button from '@/components/Button'
import CardPage from '@/components/CardPage'
import { Dropdown, DropdownContent, DropdownItem, DropdownTrigger } from '@/components/Dropdown'
import EditProjectInformation from '@/components/EditProjectInformation'
import DotsVertical from '@/components/Icons/DotsVertical'
import Video from '@/components/Icons/Video'
import JitsiView from '@/components/JitsiView'
import ManageMentors from '@/components/ManageMentors'
import ManageTeam from '@/components/ManageTeam'
import { Title } from '@/components/Meta'
import Progress from '@/components/Progress'
import ProjectActivities from '@/components/ProjectActivities'
import ProjectOutline from '@/components/ProjectOutline'
import Tag from '@/components/Tag'
import TagList from '@/components/TagList'
import UserGroup from '@/components/UserGroup'
import UserList from '@/components/UserList'
import { useTrackedForceUpdate } from '@/helpers/force-update'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'
import { useAuth } from '@/state/auth'
import useProjectBreadcrumbs, { getProjectTitle } from './project-breadcrumbs'
import '@/styles/CardPageProject.css'

const fetchProject = ({ id }) => API.get(`/projects/${id}`);
const unEnrollFromProject = (id) => API.delete(`/projects/${id}/my-participation`);

export default function Project() {
  const { isAdmin, isStudent, user } = useAuth()
  const { id } = useParams()
  const breadcrumbs = useProjectBreadcrumbs()
  const [toWatch, update] = useTrackedForceUpdate()
  const [manageTeamModalOpen, setManageTeamModalOpen] = useState(false)
  const [manageMentorsModalOpen, setManageMentorsModalOpen] = useState(false)
  const [redirect, setRedirect] = useState(false);
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false)
  const [call, setCall] = useState(false)
  const { t } = useTranslation()
  const onMeetingEnd = () => { setCall(false) }

  const unEnroll = async (id) => {
    const accept = window.confirm('Delisting from this project will reset all your progress on it and remove you from the team.')
    if (accept) {
      const { ok } = await unEnrollFromProject(id);
      if (ok) { setRedirect(true) }
    }
  }

  const lastEditedLink = () => {
    const projectLastEdits = storage.getItem(LOCALSTORAGE_KEYS.LAST_TOPIC_WORKED_ON, {})[id]
    if (projectLastEdits) {
      return `/editor/${id}/topics/${projectLastEdits.topics.join('/')}`
    }
    return `/editor/${id}`
  }

  if (redirect) return <Redirect to="/projects" />
  return (
    <CardPage
      promiseFn={fetchProject}
      id={id}
      watch={toWatch}
      className="CodeDoor__Card__Project"
      breadcrumbs={breadcrumbs}
    >

      {
        project => <>
          <Title text={getProjectTitle(project, isAdmin)} />

          <header>
            <div>
              <UserGroup users={project.students} />
              {
                (isAdmin || project.students.find(u => u.id === user.id))
                && <div className="actions">
                  <Button type='button' className="CodeDoor__Button__secondary is-small" onClick={() => setCall(true)}><Video /></Button>
                  <Dropdown>
                    <DropdownTrigger>
                      <DotsVertical />
                    </DropdownTrigger>

                    <DropdownContent className="CodeDoor__Dropdown" align="end">
                      <DropdownItem onSelect={() => setEditInfoModalOpen(true)} className="CodeDoor__Dropdown">
                        {t('projects.settings.info.cta')}
                      </DropdownItem>
                      <DropdownItem onSelect={() => setManageTeamModalOpen(true)} className="CodeDoor__Dropdown">
                        {t('projects.admin.manage.cta')}
                      </DropdownItem>
                      {isAdmin && <DropdownItem onSelect={() => setManageMentorsModalOpen(true)} className="CodeDoor__Dropdown">
                        {t('projects.admin.mentors.cta')}
                      </DropdownItem>}
                    </DropdownContent>
                  </Dropdown>

                  <EditProjectInformation
                    project={project}
                    open={editInfoModalOpen}
                    setOpen={setEditInfoModalOpen}
                    onSaved={() => {
                      setEditInfoModalOpen(false)
                      // FIXME - we can just locally update the project data...
                      update()
                    }}
                  />
                  <ManageTeam
                    projectId={id}
                    users={project.students}
                    open={manageTeamModalOpen}
                    setOpen={setManageTeamModalOpen}
                    onCreated={() => {
                      setManageTeamModalOpen(false)
                      // FIXME - we can just optimistically update the project data...
                      update()
                    }}
                  />
                  <ManageMentors
                    projectId={id}
                    users={project.mentors}
                    open={manageMentorsModalOpen}
                    setOpen={setManageMentorsModalOpen}
                    onCreated={() => {
                      setManageMentorsModalOpen(false)
                      // FIXME - we can just optimistically update the project data...
                      update()
                    }}
                  />
                </div>
              }
            </div>

            {call === true ? <JitsiView onMeetingEnd={onMeetingEnd} roomInitName={`cddrProjects-${id}-team`} me={user.fullName} /> : ''}

            <h1 className="title">{project.name}</h1>
            <p
              className="lead"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: project.description,
              }}
            />
          </header>

          <aside>
            {
              isStudent && (
                <div>
                  <Button to={lastEditedLink} className="is-block is-large">
                    {
                      project.progress > 1 ? t('projects.student.cta.continue') : t('projects.student.cta.start')
                    }
                  </Button>
                  <Button variant="secondary" onClick={() => unEnroll(id)} className="is-block is-large">Delist</Button>
                </div>
              )
            }

            <p className="label">{t('vocab.progress')}</p>
            <div className="CodeDoor__Statistic">
              <span className="title">{project.progress || 0}<span className="label"> %</span></span>
              <Progress percentage={project.progress} />
            </div>

            <p className="label">{t('vocab.status')}</p>
            <Tag title={t(`projects.status.${project.status}`)} />

            <p className="label">{t('vocab.skills')}</p>
            <TagList>
              {
                [...new Set(project.skills.map(s => s.name))].map(skill => (
                  <Tag key={skill} title={skill} />
                ))
              }
            </TagList>

            <p className="label">{t('vocab.attachments')}</p>
            <AttachmentList projectId={project.id} items={project.attachments} />

            <p className="label">{t('vocab.mentorship')}</p>
            {
              project.mentors?.length
                ? <UserList users={project.mentors} />
                : <p>{t('vocab.mentorship_empty')}</p>
            }
          </aside>

          <section className="outline">
            <ProjectOutline outline={project.outline} />
          </section>

          <ProjectActivities activities={project.activities} />
        </>
      }
    </CardPage>
  )
}
