import classNames from 'classnames'
import '@/styles/Loading.css'

function Loading ({ className, variant = 'large' }) {
  return <div
    className={classNames('CodeDoor__Loading', className, `CodeDoor__Loading__${variant}`)}
  ><div /></div>
}

export default Loading
