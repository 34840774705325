import { useState } from 'react'
import Async from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import API from '@/api'
import Video from '@/components/Icons/Video'
import JitsiView from '@/components/JitsiView'
import NewlineText from '@/components/NewlineText'
import { useAuth } from '@/state/auth'
import Attachment from '../Attachment'
import Avatar from '../Avatar'
import Button from '../Button'
import '../../styles/PeerAnswers.css'
import Loading from '../Loading'

const loadAnswers = ({ topicId, projectId }) => API.get(`/projects/${projectId}/answers/topic/${topicId}`)

const PeerAnswers = ({ topicId, projectId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const { id } = useParams()
  const [call, setCall] = useState(false)
  const onMeetingEnd = () => { setCall(false) }
  const { user } = useAuth();
  const { t } = useTranslation()

  const toggleModal = (data = false) => {
    setModalOpen(!modalOpen)
    setModalData(data || null)
  }
  return (
    <div className='__CodeDoor_PeerAnswers'>
      <Async promiseFn={loadAnswers} topicId={topicId} projectId={projectId} watch={topicId}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {data => {
            return <div className='__Peer_Button_Container'>
              {data.filter(answer => answer.user.id !== user.id).map(answer => {
                const { user: peer } = answer
                return <Button variant="secondary" key={answer.id} onClick={() => toggleModal(answer)}>
                  <Avatar user={peer} />
                  <span className='username'>{peer.fullName}</span>
                </Button>
              })}
            </div>
          }}
        </Async.Fulfilled>
        <Async.Rejected>There was an error loading peer answers</Async.Rejected>
      </Async>
      <Modal
        className="CodeDoor_PeerAnswer_Modal"
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        closeTimeoutMS={300}
      >
        <h1 className="title">{modalData?.user?.fullName}</h1>
        <section className="modal_body">
          <div>
            <h2>Answer</h2>
            <hr />
            <NewlineText text={modalData?.body} />
          </div>
          <p>
            Talk with {modalData?.user?.fullName} about the answer. Click the button to start a call with {modalData?.user?.fullName}.
            <Button type='button' className="CodeDoor__Button__secondary is-small videochatpeer" onClick={() => setCall(true)}><Video /></Button> </p>
          {call === true ? <JitsiView onMeetingEnd={onMeetingEnd} roomInitName={`cddrProjects-${id}-team`} me={user.fullName} /> : ''}
          <div>
            <h2>Attachments</h2>
            <hr />
            <div className="Attachment_Container">
              {modalData?.attachments?.map((attachment) => <Attachment key={attachment.id} attachment={attachment} />)}
            </div>

          </div>
        </section>
        <footer>
          <Button variant="secondary" onClick={toggleModal}>
            {t('vocab.close')}
          </Button>
        </footer>
      </Modal>
    </div >
  )
}

export default PeerAnswers
