import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { matchPath, useRouteMatch, Link, useLocation } from 'react-router-dom'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'
import Attachment from '@/components/Attachment'
import Button from '@/components/Button'
import AiFeedback from '@/components/Editor/AiFeedback';
import ExternalLinkTag from '@/components/ExtenalLinkTag';
import FormGroup from '@/components/FormGroup'
import Add from '@/components/Icons/Add'
import Article from '@/components/Icons/Article';
import Input from '@/components/Input'
import { Title } from '@/components/Meta'
import useForceUpdate from '@/helpers/force-update'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'
import { useTopic } from '@/state/project'
import '@/styles/Editor/ElementEditor.css'
import '@/styles/Editor/ElementAnswerAttachment.css'
import PeerAnswers from './PeerAnswers'
import Iframe from './Video'

/**
 * Generate a line for our text editor.
 *
 * @param {string} line The content of the line
 */
const line = line => `<span class="CodeDoor__Editor__ElementEditor__LineNumber">${line}</span>`

/**
 * Generate the default lines.
 */
const placeholderLines = (() => {
  let append = ''
  for (let i = 1; i < 9; i++) {
    append += `\n${line('')}`
  }
  return append
})()

/**
 * Prepare syntax highlighting and line numbers for our text editor.
 *
 * @param {string} code The code to highlight.
 */
const highlight = code => {
  let highlighted = Prism.highlight(code, Prism.languages.markup, 'markup')
    .split('\n')
    .map(line)
    .join('\n')

  highlighted += placeholderLines

  return highlighted
}

function ElementEditor() {
  const topic = useTopic()
  const update = useForceUpdate()
  const { t } = useTranslation()
  const defaultModalData = { name: '', url: '' }
  const [isBusy, setIsBusy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(defaultModalData)

  const location = useLocation();
  const tabItem = location.pathname.split('/').pop();

  const { url } = useRouteMatch();
  const match = matchPath(url, { path: '/editor/:projectId/topics' })
  const { projectId } = match.params

  const toggleModal = (modalDataState = null) => {
    if (modalDataState) {
      setModalData(modalDataState)
    }
    setModalOpen(!modalOpen)
  }

  const generateTopicIds = (topic) => {
    const parentTopicId = topic.parent.id ? generateTopicIds(topic.parent) : []
    return [...parentTopicId, topic.id]
  }

  const updateLastEditStatus = () => {
    const storageLastEdits = storage.getItem(LOCALSTORAGE_KEYS.LAST_TOPIC_WORKED_ON, {})
    storageLastEdits[projectId] = { topics: generateTopicIds(topic) }
    storage.setItem(LOCALSTORAGE_KEYS.LAST_TOPIC_WORKED_ON, storageLastEdits)
  }

  const addAttachment = () => {
    setIsBusy(true)
    topic.answer.addAttachment(modalData, () => {
      updateLastEditStatus()
      setIsBusy(false)
      toggleModal(defaultModalData)
    })
  }

  const updateAttachment = () => {
    setIsBusy(true)
    topic.answer.updateAttachment(modalData, () => {
      updateLastEditStatus()
      toggleModal(defaultModalData)
      setIsBusy(false)
    })
  }
  const removeAttachment = (id) => {
    if (!window.confirm('Do you really want to delete this attachment?')) return false
    setIsBusy(true)
    topic.answer.removeAttachment(id, () => {
      updateLastEditStatus()
      setIsBusy(false)
    })
  }

  return (
    <main className="CodeDoor__Editor__ElementEditor">
      <Title text={t('editor.topic.meta_title', { topic: topic.name, project: topic.project.name })} />
      <div className="CodeDoor__Editor__ElementEditor__TabItem" title="This is your topic universe. Dive deep, emerge, read, watch, do research, communicate, collaborate">
        <div>
          <div className="empty">
            <h1 className="title CodeDoor_Editor_Title">This is your <span className="CodeDoor__Editor__ElementNavigationItem" style={{ fontStyle: 'italic' }}>{topic.name}</span>-universe</h1>
            <p className="CodeDoor__Editor__ElementNavigationItem CodeDoor_Editor_Subtitle">Emerge, read, watch, do research, build, communicate, collaborate, take notes.</p>
          </div>
        </div>
        <p className="CodeDoor__Editor__ElementNavigationItem CodeDoor_Editor_Subtitle"><span className="blink"> Choose what you want to do:</span></p>
        <Link className={tabItem === 'description' ? 'active' : ''} to={`${url}/description`}>Read about your task</Link>
        {topic.resourcesInTree.length > 0 ? <Link className={tabItem === 'resources' ? 'active' : ''} to={`${url}/resources`}>Do Research</Link> : null}
        {topic.video && <Link className={tabItem === 'video' ? 'active' : ''} to={`${url}/video`}>Watch a video</Link>}
        <Link className={tabItem === 'feedback' ? 'active' : ''} to={`${url}/feedback`}>Talk to the A.I. CodoLino</Link>
      </div>

      <header>

        {tabItem === 'description' && <div><p
          className="lead"
          style={{ marginTop: '1em' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: topic.description || `What did you learn about "${topic.name}"?`,
          }}
        /></div>}

        {(tabItem === 'video' && topic.video) && <div>
          <h1 className="title">
            {topic.video && (
              <Iframe video={topic.video} />
            )}
          </h1></div>}

        {tabItem === 'resources' && <div><section>
          <div className="empty">
            <h1 className="CodeDoor_Editor_Title">Recommended Resources</h1>
            <p className="CodeDoor__Editor__ElementNavigationItem CodeDoor_Editor_Subtitle">
              {topic.resourcesInTree.length}
              {topic.resourcesInTree.length === 1 ? ' resource' : ' resources. Please contact us if you get stuck doing research.'}
            </p>
          </div>
          <div className="resources">
            {
              topic.resourcesInTree?.map(r => (
                <ExternalLinkTag
                  url={r.link}
                  label={r.name}
                  className="CodeDoor__ResourceLink"
                  defaultIcon={Article}
                  key={r.id}
                />
              ))
            }
          </div></section>
        </div >}

        {tabItem === 'feedback' && !topic.isSummary && <div className="resources">
          {topic.robo === 1 && <section>
            <AiFeedback topic={topic} />
          </section>
          }
        </div>}

      </header >

      {
        topic.uploadAttachment && <section className="CodeDoor_Editor_Answer_Attachment">
          <h1 className="title">Required Attachment <Button variant="secondary" onClick={() => toggleModal()}><Add /></Button> </h1>
          <div className="__Attachment_Container">
            {topic.answer.attachments?.map(attachment => {
              return <Attachment
                canEdit
                key={attachment.id}
                attachment={attachment}
                onUpdateClick={() => toggleModal(attachment)}
                onDeleteClick={() => removeAttachment(attachment.id)}
              />
            })}
          </div>
        </section>
      }
      < section className="CodeDoor__Editor__ElementEditor__CodeEditor__Wrapper" >
        <h1 className="CodeDoor_Editor_Subtitle">Smart (Code)Editor</h1>
        <Editor
          value={topic.answer.body}
          onValueChange={code => {
            topic.answer.update(code)
            update() // Force the component to update
            updateLastEditStatus()
          }}
          highlight={highlight}
          style={{
            fontFamily: 'monospace',
            fontSize: 16,
          }}
          className="CodeDoor__Editor__ElementEditor__CodeEditor"
        />
      </section >
      <section className="CodeDoor_Editor_Peer_Answers">
        <h1 className="title">Peer answers</h1>
        <div className="__Peer_Answers_Container">
          <PeerAnswers projectId={projectId} topicId={topic.id} />
        </div>
      </section>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => toggleModal(defaultModalData)}
        closeTimeoutMS={300}
      >
        <h1 className="title">Add attachment</h1>
        <FormGroup label="Name">
          <Input
            type="text"
            placeholder="My attachment"
            value={modalData.name}
            onChange={(name) => setModalData({ ...modalData, name })}
          />
        </FormGroup>
        <FormGroup label="URL">
          <Input
            type="text"
            placeholder="https://example.com"
            value={modalData.url}
            onChange={(url) => setModalData({ ...modalData, url })}
          />
        </FormGroup>
        <footer>
          <footer>
            <Button
              disabled={isBusy}
              variant="secondary"
              onClick={() => setModalOpen(false)}
            >Close</Button>
            <Button
              disabled={false}
              onClick={modalData.id ? updateAttachment : addAttachment}
            >
              {isBusy ? 'Saving...' : 'Save attachment'}
            </Button>
          </footer>
        </footer>
      </Modal>
    </main >
  )
}

export default ElementEditor
