function Iframe({ video }) {
  return (
    <iframe
      title="Video"
      width="560"
      height="315"
      src={video}
      frameBorder="0"
      overFlow="hidden"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen/>
  );
}

export default Iframe;
