import { Async } from 'react-async'
import API from '../api'
import { useAuth } from '../state/auth'
import CardList from './CardList'
import Loading from './Loading'
import ProjectCard from './ProjectCard'

const fetchProjects = ({ userId }) => API.get(`/users/${userId}/projects/active`)

export default function MyActiveProjects({ title = 'Your current projects' }) {
  const { user } = useAuth()

  return <>
    <Async promiseFn={fetchProjects} userId={user.id}>
      <Async.Pending>
        <h2 className="title is-secondary">{title}</h2>
        <Loading />
      </Async.Pending>
      <Async.Fulfilled>
        {projects => projects.length
          ? <>
            <h2 className="title is-secondary">{title}</h2>
            <CardList large>
              {projects.map(p => <ProjectCard project={p} key={p.id} />)}
            </CardList>
          </>
          : null
        }
      </Async.Fulfilled>
      <Async.Rejected>Error...</Async.Rejected>
    </Async>
  </>
}
