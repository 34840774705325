import { useState } from 'react'
import { Async } from 'react-async'
import Modal from 'react-modal'
import API from '@/api'
import day from '@/helpers/day'
import Button from './Button'
import Loading from './Loading'
import Table from './Table'

const listInviteUsers = () => API.get(`/invites`)

export default function ListInviteUsers () {
  const [open, setOpen] = useState(false)

  return <>
    <Button onClick={() => setOpen(true)} className="CodeDoor__Button CodeDoor__Button__secondary">Show invites</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">Pending invites</h1>
      <p className="lead">These users have received an email invitation.</p>

      <Async promiseFn={listInviteUsers}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          { data => (
            <Table>
              <Table.Head>
                <Table.TH label="Email" />
                <Table.TH label="Code" />
                <Table.TH label="Created At" />
              </Table.Head>
              <Table.Body>
                { data.map(invite => (
                  <Table.Row key={invite.id}>
                    <Table.TD>{invite.email}</Table.TD>
                    <Table.TD><code>{invite.code}</code></Table.TD>
                    <Table.TD>{day(invite.created_at).format('ll')}</Table.TD>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Async.Fulfilled>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>
      <footer>
        <Button className="CodeDoor__Button CodeDoor__Button__secondary" onClick={() => setOpen(false)}>Close</Button>
      </footer>
    </Modal>
  </>
}
