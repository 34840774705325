import CheckForMissingPassword from '@/components/CheckForMissingPassword'
import OnboardingModal from '@/components/OnboardingModal'
import TopBar from '@/components/TopBar'
import AdminRoutes from '@/routes/AdminRoutes'
import StudentRoutes from '@/routes/StudentRoutes'
import { useAuth } from '@/state/auth'
import '@/styles/Platform.css'

function Platform () {
  const { isAdmin, isOnboarded } = useAuth()

  return <div className="CodeDoor__Platform">
    <TopBar />
    <CheckForMissingPassword />
    {!isOnboarded && <OnboardingModal />}

    {
      isAdmin
        ? <AdminRoutes />
        : <StudentRoutes />
    }
  </div>
}

export default Platform
