import { useState } from 'react'
import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'
import API from '@/api'
import Button from '@/components/Button'
import Loading from '@/components/Loading'
import { useTopic } from '@/state/project'

const loadProject = ({ id }) => API.get(`/projects/${id}`)

function CompletedModal ({ topic }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return <>
    <Button onClick={() => setOpen(true)}>{t('vocab.complete')}</Button>
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      closeTimeoutMS={300}
    >
      <Async promiseFn={loadProject} id={topic.project.id}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {project => {
            const title = t(`editor.completed_modal.${project.status}`)
            const description = t(`editor.completed_modal.${project.status}_description`, { project })

            return <>
              <h1 className="title">{title}</h1>
              <p className="lead">{description}</p>
              <footer>
                {
                  project.status !== 'WORKING_ON'
                    ? <Button to={`/projects/${project.id}`}>{t('vocab.continue')}</Button>
                    : <Button onClick={() => setOpen(false)}>{t('vocab.continue')}</Button>
                }
              </footer>
            </>
          }}
        </Async.Fulfilled>
        <Async.Rejected>There was an error loading the project!</Async.Rejected>
      </Async>
    </Modal>
  </>
}

function NextButton () {
  const history = useHistory()
  const topic = useTopic()

  if (! topic.nextLink) return <CompletedModal topic={topic} />
  const goNext = () => history.push(`./${topic.nextLink}`)

  return <Button onClick={goNext}>Continue</Button>
}

export default NextButton
