import { useEffect } from 'react'
import RegexParser from 'regex-parser'
import { throttle } from 'throttle-debounce'
import API from '@/api'
import useForceUpdate from '@/helpers/force-update'
import { storage } from '@/helpers/storage/LocalStorage'

const AnswerUpdater = {}
const AnswerUpdateListeners = {}
const MIN_ANSWER_LENGTH = 50
export const storageKey = id => `topics.${id}.answer`
export const attachmentStorageKey = id => `topics.${id}.attachments`

export const useTrackAnswer = ({ id }) => {
  const update = useForceUpdate()

  useEffect(() => {
    if (! AnswerUpdateListeners[id]) {
      AnswerUpdateListeners[id] = []
    }

    const i = AnswerUpdateListeners[id].push(update)

    // Clean up the trackers
    return () => AnswerUpdateListeners[id].splice(i - 1, 1)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps
}
export default class Answer {
  static forTopic (topic) {
    if (topic.answer) return topic.answer

    const answer = new Answer({
      topic,
    })

    // Save the topic answer to
    topic.answer = answer // eslint-disable-line no-param-reassign

    return topic.answer
  }
  
  constructor ({
    body = '',
    topic,
    version = 1,
  }) {
    this.version = version
    this.topic = topic
    this.body = body || storage.getItem(this.localStorageKey) || ''
    if (topic.uploadAttachment) {
      this.attachments = storage.getItem(this.attachmentLocalStorageKey) || []
    }

    if (! AnswerUpdater[this.id]) {
      AnswerUpdater[this.id] = throttle(
        2000, // 2 seconds
        this.updateAnswer
      )
    }
    this.saveAnswer = AnswerUpdater[this.id]
    this.updateProgress()
  }

  updateAnswer () {
    this.updateProgress()
    storage.setItem(this.localStorageKey, this.body)
    AnswerUpdateListeners[this.id]?.forEach(cb => cb(this))
    API.post(`/projects/${this.topic.project.id}/topics/${this.topic.id}/answers`, {
      body: this.body,
      progress: this.progress,
    })
  }

  get id () {
    return this.topic.id
  }

  get localStorageKey () {
    return storageKey(this.id)
  }

  get attachmentLocalStorageKey () {
    return attachmentStorageKey(this.id)
  }

  get isComplete () {
    return this.progress > 60
  }

  get regex () {
    return this.topic.regex
  }

  get hasRegex () {
    return !! this.regex
  }

  get regexp () {
    return RegexParser(this.regex)
  }

  get wordlist () {
    return this.topic.wordlist
  }

  get hasWordMatcher () {
    return !! this.wordlist
  }

  get cleanWordsList () {
    return this.hasWordMatcher
      ? this.wordlist.replace(/\//g, '')
      : ''
  }

  get keywords () {
    return this.cleanWordsList.split('|')
  }

  get requiredMatchCount () {
    return this.keywords.length || 1
  }

  get wordlistRegexp () {
    return new RegExp(this.cleanWordsList, 'gmi')
  }

  get matches () {
    if (! this.body) return []

    const matches = [...this.body.matchAll(this.wordlistRegexp)]

    return Array.from(new Set(matches.flat()))
  }

  async addAttachment (data, callback = () => { }) {
    const attachment = await API.post(`/projects/${this.topic.project.id}/topic/${this.id}/attachments`, data)
    storage.setItem(this.attachmentLocalStorageKey, attachment)
    this.attachments.push(attachment);
    this.updateAnswer();
    callback()
  }

  async removeAttachment (id, callback = () => {}) {
    const response = await API.delete(`/attachments/${id}`);

    if(response.status === 204){
      this.attachments = this.attachments.filter(attachment => attachment.id !== id)
      this.updateAnswer();
      callback()
    }
  }

  async updateAttachment (data, callback = () => {}) {
    const attachment = await API.put(`/attachments/${data.id}`, data)

    this.attachments = this.attachments.map(item => {
      return item.id === attachment.id
        ? attachment
        : item
    })
    this.updateAnswer();
    callback()
  }

  update (body) {
    this.body = body
    this.saveAnswer()
  }

  computeProgress () {
    // Have Attachment. So attachment is worth 100%
    if (this.attachments) {
      return this.attachments.length > 0 ? 100 : 0;
    }
  
    if (! this.hasRegex && ! this.hasWordMatcher) {
      return Math.max(0, Math.min(100, this.body.length - MIN_ANSWER_LENGTH))
    }

    // Have a regex, but no word matcher. So regex weighs 100%
    if (! this.hasWordMatcher) {
      return this.body.match(this.regexp) ? 100 : 0
    }

    // Have a wordlist, but no regex. So wordlist weighs 100%
    if (! this.hasRegex) {
      return Math.ceil((this.matches.length / this.requiredMatchCount) * 100)
    }

    // We have both cases, so the regex is worth 60%, the wordlist 40%
    const REGEX_WEIGHT = 60
    const WORDLIST_WEIGHT = 40
    let progress = this.body.match(this.regexp) ? REGEX_WEIGHT : 0

    progress += Math.min(
      WORDLIST_WEIGHT,
      Math.ceil((this.matches.length / this.requiredMatchCount) * WORDLIST_WEIGHT),
    )

    return progress
  }

  updateProgress () {
    this.progress = this.computeProgress()
    this.topic.updateProgress()
  }
}
