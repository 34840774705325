import { Link } from 'react-router-dom'
import classNames from 'classnames'
import '@/styles/Tag.css'

export default function Tag ({ title, to, href, className }) {
  const classes = classNames('CodeDoor__Tag', className)

  return href
    ? <a href={href} className={classes} target="_blank" rel="noreferrer">{title}</a>
    : to
      ? <Link to={to} className={classes}>{title}</Link>
      : <div className={classes}>{title}</div>
}
