import Button from './Button'
import Icon from './Icon'

function Moon () {
  return <Icon>
    <path d="M3.12101 11.3014C3.70186 12.1722 4.47962 12.894 5.39131 13.4084C6.303 13.9227 7.32299 14.2151 8.36871 14.262C9.41444 14.3089 10.4565 14.1088 11.4105 13.678C12.3646 13.2473 14.4314 11.7552 13.6903 11.6756C12.9491 11.5961 11.0447 12.1322 9.71151 11.6466C8.37833 11.1609 7.23487 10.2626 6.44745 9.08228C5.66004 7.90194 5.26975 6.50117 5.33335 5.08371C5.39696 3.66626 7.43044 1.55781 7.00175 1.36182C6.57307 1.16583 4.8743 2.01938 4.11018 2.73481C3.34606 3.45025 2.76115 4.33557 2.4028 5.31909C2.04445 6.30261 1.92273 7.35669 2.04751 8.396C2.17228 9.43531 2.54004 10.4306 3.12101 11.3014Z" fill="currentColor"/>
  </Icon>
}

function MoonButton (props) {
  return <Button {...props}><Moon /></Button>
}

export default Moon
export { MoonButton }
