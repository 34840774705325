import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import auth from './api/auth'
import Loading from './components/Loading'
import PrivateRoute from './components/PrivateRoute'
import { ProvideAppState } from './state/app'
import { ProvideAuth } from './state/auth'
import Auth from './views/Auth'
import Editor from './views/Editor'
import Platform from './views/Platform'
import './App.css'
import './styles/Modal.css'

Modal.setAppElement('#root')

function App () {
  const { i18n } = useTranslation()
  const { isPending } = useAsync({
    promiseFn: auth.getUser,
    onResolve: user => {
      i18n.changeLanguage(user.locale)
    },
  })

  if (isPending) return <Loading />

  return (
    <div className="App">
      <ProvideAuth>
        <ProvideAppState>
          <BrowserRouter>
            <Switch>
              <Route path="/auth">
                <Auth />
              </Route>

              <PrivateRoute path="/editor/:projectId">
                <Editor />
              </PrivateRoute>

              <PrivateRoute path="/editor" exact>
                <Redirect to="/projects" />
              </PrivateRoute>

              <PrivateRoute path="/">
                <Platform />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </ProvideAppState>
      </ProvideAuth>
    </div>
  )
}

export default App
