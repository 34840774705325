import classNames from 'classnames'
import '@/styles/Input.css'

function Input ({
  value = '',
  error = null,
  onChange,
  type = 'text',
  variant = 'default',
  className,
  ...props
}) {
  return <input
    type={type}
    value={value}
    onChange={e => onChange(e.target.value)}
    className={classNames('CodeDoor__Input', className, `CodeDoor__Input__${variant}`, error ? 'error' : '')}
    {...props}
  />
}

export default Input
