import { useState } from 'react'
import { useAsync } from 'react-async'
import { useParams } from 'react-router-dom'
import API from '@/api'
import { useAuth } from '@/state/auth'
import Button from './Button'

const addComment = ([body, userId, projectId]) => API.post(`/comments`, { body, userId, projectId  })

export default function AddComment ({ onCreated = () => null }) {
  const { user } = useAuth()
  const { id: projectId } = useParams()
  const [body, setBody] = useState('')

  const { isPending, run } = useAsync({
    deferFn: addComment,
    onResolve: comment => {
      setBody('')
      onCreated(comment)
    },
  })

  const handleSubmit = event => {
    event.preventDefault()
    run(body, user.id, projectId)
  }

  return <form onSubmit={handleSubmit}>
    <textarea
      className="CodeDoor__Input"
      rows="4"
      value={body}
      onChange={e => setBody(e.target.value)}
      placeholder="Write a comment"
    />
    <footer className="actions">
      <Button type="submit" disabled={isPending}>Save comment</Button>
    </footer>
  </form>
}
