import i18n from '@/i18n'
import API from '.'
import { LOCALSTORAGE_KEYS, storage } from '../helpers/storage/LocalStorage'

// initialize the session with a CSRF token
const initialize = () => API.get('/sanctum/csrf-cookie')

const auth = {
  async signIn (data) {
    await initialize()
    await API.post('/login', data)

    const user = await auth.getUser()
    await i18n.changeLanguage(user.locale)

    return user
  },

  async signUp (data) {
    await initialize()
    await API.post(`/register`, data.code)

    const user = await auth.getUser()

    return user
  },

  async getUser () {
    const user = await API.get('/user')

    if (!user) {
      storage.removeItem(LOCALSTORAGE_KEYS.USER)
      return
    }

    storage.setItem(LOCALSTORAGE_KEYS.USER, user)
    return user
  },

  async update (data) {
    await API.put('/user/profile-information', data)

    return this.getUser()
  },

  updateLocale (locale) {
    return API.put('/user/locale', { locale })
  },

  updatePassword (password, currentPassword = null) {
    return API.put('/user/password', { password, currentPassword })
  },

  updateOrganisation (id, data) {
    return API.put(`/organisations/${id}`, data)
  },

  signOut () {
    return API.post('/logout')
  },

  async requestPasswordResetEmail (email) {
    await initialize()
    return API.post('/forgot-password', { email })
  },

  async resetPassword (email, password, passwordConfirmation, token) {
    await initialize()
    return API.post('/reset-password', {
      email,
      password,
      passwordConfirmation,
      token,
    })
  },
}

export default auth
