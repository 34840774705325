import { useState } from 'react'
import { Async } from 'react-async';
import Modal from 'react-modal'
import Button from '@/components/Button'
import Loading from '@/components/Loading';

const roboFeedback = async ({ answer, description }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ answer, description }),
  };
  const res = await fetch(`https://container.tensorparc.com/api/openai/robofeedback`, requestOptions)
  if (!res.ok) throw new Error(res.statusText)
  return res.json()
}

function RoboAdvisor({ topic }) {
  const [showHint, setShowHint] = useState(false)

  if (topic.isSummary) return null

  return <div>
    <Button onClick={() => setShowHint(true)}>Get A.I. feedback</Button>
    <Modal
      isOpen={showHint}
      onRequestClose={() => setShowHint(false)}
      closeTimeoutMS={300}
    >
      <h1 className="title">Codolino says:</h1>

      <Async promiseFn={roboFeedback} answer={topic.answer.body} description={topic.description}>
        {({ data, error, isPending }) => {
          if (isPending) return (<p className="lead"><Loading /></p>)
          if (error) return (<p className="lead">Robo got an error: ${error.message}</p>)
          if (data) {
            return (
              <p className="lead">{data.response}</p>
            )
          }
          return null
        }}
      </Async>
      <footer>
        <Button onClick={() => setShowHint(false)}>Continue</Button>
      </footer>
    </Modal>
  </div>
}

export default RoboAdvisor
