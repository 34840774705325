import classNames from 'classnames'
import '@/styles/Editor/Progress.css'

function Progress ({ percentage, small = false }) {
  const radius = 40
  const circumference = Math.PI * radius * 2
  const innerStrokeWidth = 2
  const strokeWidth = percentage > 0
    ? small ? 8 : 4
    : 0
  const stroke = percentage >= 60 ? `var(--green)` : `var(--primary)`

  return (
    <svg
      viewBox='0 0 100 100'
      className={classNames(
        'CodeDoor__Editor__Progress',
        small && 'is-small'
      )}
    >
      <circle cx="50" cy="50" r={radius} style={{ strokeWidth: innerStrokeWidth }} />
      <circle
        cx="50" cy="50"
        r={radius}
        style={{ strokeWidth, stroke }}
        strokeDasharray={`${circumference * (percentage/100)}, ${circumference}`}
      />
    </svg>
  )
}

export default Progress
