import shorten from '../helpers/shorten'
import striphtml from '../helpers/striphtml'
import Card from './Card'
import Progress from './Progress'
import Tag from './Tag'
import UserGroup from './UserGroup'
import '@/styles/ProjectCard.css'

const ProjectCard = ({ project }) => <Card to={`/projects/${project.id}`} className="CodeDoor__ProjectCard">
  <header>
    <UserGroup users={project.students} />

    <div className="CodeDoor__Statistic is-small">
      <span className="title">{project.progress}<span className="label"> %</span></span>
      <Progress percentage={project.progress} small />
    </div>
  </header>

  <main>
    <h1>{project.name}</h1>
    <p>{shorten(striphtml(project.description))}</p>
  </main>

  <footer>
    <Tag title={project.level} />

    <div className="skills">
      <span>Skills:</span>
      {
        project.skills
          ?.slice(0, 3)
          ?.map(s => <Tag title={s.name} to={`/skills/${s.slug}`} key={s.id} />)
      }
    </div>
  </footer>
</Card>

export default ProjectCard
