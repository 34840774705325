import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { Redirect } from 'react-router-dom'
import API from '@/api'
import { useAuth } from '@/state/auth'
import Button from './Button'
import EditTeam from './EditTeam'

const startProject = ([id, students]) => API.post(`/projects`, {
  projectDefinitionId: id,
  students,
})

export default function StartProject ({ definition }) {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const [students, setStudents] = useState([])
  const { t } = useTranslation()

  const { data, isPending, run } = useAsync({ deferFn: startProject })

  if (data) return <Redirect to={`/projects/${data.id}`} />

  return <>
    <Button onClick={() => setOpen(true)} className="is-large">{t('projects.student.cta.start')}</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">{t('projects.student.create.modal.title')}</h1>
      <p className="lead">{t('projects.student.create.modal.lead')}</p>

      <EditTeam projectDefinitionId={definition.id} students={students} setStudents={setStudents} />

      <footer>
        <Button
          onClick={() => run(definition.id, [])}
          disabled={isPending}
          variant="secondary"
        >{t('projects.student.create.modal.go_solo')}</Button>
        <Button
          onClick={() => run(definition.id, [...students, user.id])}
          disabled={isPending}
        >{t('projects.student.create.modal.invite')}</Button>
      </footer>
    </Modal>
  </>
}
