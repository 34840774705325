import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { useAuth } from '@/state/auth'
import Login from './Login'
import Register from './Register'
import RequestPasswordReset from './RequestPasswordReset'
import ResetPassword from './ResetPassword'
import '@/styles/Auth.css'

export default function Auth () {
  const { path } = useRouteMatch()
  const auth = useAuth()
  const location = useLocation()

  if (auth.isAuthenticated) {
    const { from } = location.state || { from: { pathname: '/' } }
    return <Redirect to={from} />
  }

  return (
    <div className="CodeDoor__Auth">
      <div className="CodeDoor__Auth__inner">
        <Switch>
          <Route exact path={path}>
            <Redirect to="./login" />
          </Route>

          <Route path={`${path}/login`}>
            <Login />
          </Route>

          <Route path={`${path}/:code/register`}>
            <Register />
          </Route>

          <Route path={`${path}/reset`}>
            <RequestPasswordReset />
          </Route>

          <Route path={`${path}/reset-password`}>
            <ResetPassword />
          </Route>

          <Route path={`${path}/*`}>
            <Redirect to="./login" />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
