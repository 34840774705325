function VideoTalkToAi() {
  return (
    <iframe
      title="Video"
      width="100%"
      height="100%"
      src="https://player.vimeo.com/video/713186940"
      frameBorder="0"
      overFlow="hidden"
    />
  );
}

export default VideoTalkToAi;
