import Activity from './Activities/Activity'
import '@/styles/ActivityStream.css'

export default function ActivityStream ({ activities }) {
  if (! activities?.length) return null

  return <div className="CodeDoor__ActivityStream">
    {
      activities?.map(activity => (
        <Activity
          activity={activity}
          key={activity.id}
          withDescription
          withTargetLink
        />
      ))
    }
  </div>
}
