import { MoonButton } from '@/components/Icons/Moon'
import { SunButton } from '@/components/Icons/Sun'
// import { AudioOffButton } from '@/components/Icons/AudioOff'
// import { AudioOnButton } from '@/components/Icons/AudioOn'
import { useAppState } from '@/state/app'
import '@/styles/Editor/EditorSettings.css'

function EditorSettings () {
  const {
    themeDark,
    toggleTheme,
    // soundOn,
    // toggleSound,
  } = useAppState()

  return (
    <aside className="CodeDoor__Editor__EditorSettings">
      {
        themeDark
          ? <SunButton
            onClick={toggleTheme}
            aria-label="Switch the lights on"
          />
          : <MoonButton
            onClick={toggleTheme}
            aria-label="Switch the lights off"
          />
      }
      {/* {
        soundOn
          ? <AudioOnButton
              onClick={toggleSound}
              title="Audio playback is on"
              aria-label="Turn the audio guide on"
            />
          : <AudioOffButton
              onClick={toggleSound}
              title="Audio playback is off"
              aria-label="Turn the audio guide off"
            />
      } */}
    </aside>
  )
}

export default EditorSettings
