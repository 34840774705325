import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import authApi from '@/api/auth'
import Button from '@/components/Button'
import FormGroup from '@/components/FormGroup'
import Input from '@/components/Input'
import { Title } from '@/components/Meta'
import { getValidationMessage } from '@/helpers/errors'
import { useQuery } from '@/helpers/url-parameter'

export default function ResetPassword () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [error, setError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const token = useQuery().get('token')
  const { t } = useTranslation()

  // On Click send Code to Email
  async function handleSendCodeClick (event) {
    event.preventDefault()
    setIsSubmitting(true)

    try {
      await authApi.resetPassword(email, password, passwordConfirmation, token)
      setIsSubmitted(true)
    } catch (e) {
      setError(e)
    }

    setIsSubmitting(false)
  }

  return <>
    <Title text={t('auth.reset_password.title')} />

    <h1>{t('auth.reset_password.title')}</h1>

    {
      isSubmitted
        ? <>
          <p>{t('auth.reset_password.submitted.p1')}</p>
          <div className="actions">
            <Button to="/auth/login">{t('auth.to_login')}</Button>
          </div>
        </> : (
          <form onSubmit={handleSendCodeClick}>
            <FormGroup
              label={t('vocab.email')}
              error={getValidationMessage('email', error)}
            >
              <Input
                autoFocus
                type="email"
                placeholder="john@doe.com"
                value={email}
                onChange={setEmail}
                autoComplete="email"
                required
              />
            </FormGroup>

            <FormGroup
              label={t('vocab.password')}
              error={getValidationMessage('password', error)}
            >
              <Input
                type="password"
                placeholder="*********"
                value={password}
                onChange={setPassword}
                autoComplete="new-password"
                required
              />
            </FormGroup>

            <FormGroup
              label={t('vocab.password_confirmation')}
              error={getValidationMessage('passwordConfirmation', error)}
            >
              <Input
                type="password"
                placeholder="*********"
                value={passwordConfirmation}
                onChange={setPasswordConfirmation}
                autoComplete="new-password"
                required
              />
            </FormGroup>

            <div className="actions">
              <Button
                to="login"
                variant="secondary"
              >
                {t('auth.to_login')}
              </Button>

              <Button
                type="submit"
                disabled={isSubmitting}
              >
                {t('auth.reset_password.cta')}
              </Button>
            </div>
          </form>
        )
    }
  </>
}
