import { Helmet } from 'react-helmet-async'

export { HelmetProvider as MetaProvider } from 'react-helmet-async'

export function Title ({ text }) {
  return <Meta>
    <title>{text}</title>
  </Meta>
}

export default function Meta ({ children }) {
  return <Helmet titleTemplate="%s - CodeDoor Platform">
    {children}
  </Helmet>
}
