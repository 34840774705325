import { useState } from 'react'
import { useAsync } from 'react-async'
import Modal from 'react-modal'
import API from '@/api'
import Button from './Button'

const inviteUsers = ([emailList]) => API.post(`/invites`, { emailList })

export default function InviteUsers () {
  const [open, setOpen] = useState(false)
  const [emailList, setEmailList] = useState(null)
  const [fresh, setFresh] = useState(false)

  const { data, isPending, run } = useAsync({ deferFn: inviteUsers })

  if (data && !fresh) {return <>
    <Button onClick={() => {
      setOpen(true)
      setFresh(true)
    }}>Invite</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">Success,</h1>
      <p> the invites were sent!
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={36} height={36}>
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
      </p>
      <footer>
        <Button className="CodeDoor__Button" onClick={() => setOpen(false)}>Close</Button>
      </footer>
    </Modal>
  </>}

  return <>
    <Button onClick={() => setOpen(true)}>Invite</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">Invite Users</h1>
      <p className="lead">To invite users insert e-mails (one per line) here and click "Invite now"</p>
      <textarea className="CodeDoor__Input" rows="10" onChange={e => setEmailList(e.target.value)} />
      <footer>
        <Button className="CodeDoor__Button CodeDoor__Button__secondary" onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => {
          run(emailList)
          setFresh(false)
        }} disabled={isPending}>Invite now</Button>
      </footer>
    </Modal>
  </>
}
