import { Redirect } from 'react-router-dom'
import { TopicsMap } from '@/state/models/Topic'
import { useProject } from '@/state/project'

function ChooseStep () {
  const project = useProject()
  const firstTopic = TopicsMap[project.topics[0].id]

  return (
    <Redirect to={`/editor/${project.id}/topics/${firstTopic.id}`} />
  )
}

export default ChooseStep
