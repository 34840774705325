import { useParams } from 'react-router-dom'
import API from '@/api'
import Breadcrumbs from '@/components/Breadcrumbs'
import CardList from '@/components/CardList'
import CardPage from '@/components/CardPage'
import ExternalLinkTag from '@/components/ExtenalLinkTag'
import { Title } from '@/components/Meta'
import ProjectDefinitionCard from '@/components/ProjectDefinitionCard'
import Tag from '@/components/Tag'
import TagList from '@/components/TagList'
import { StudentCard } from '@/components/UserCard'
import '@/styles/CardPageSkill.css'
import '@/styles/ResourceLink.css'

const fetchSkill = ({ slug }) => API.get(`/skills/${slug}?include=parent,children,users,resources,projectDefinitions`)

const useBreadCrumbs = () => [
  {
    label: 'Skills',
    to: '/skills',
  },
  s => s.parent
    ? <Breadcrumbs.Item to={`/skills/${s.parent.slug}`} label={s.parent.name} key="parent" />
    : undefined,
  s => <Breadcrumbs.Item label={s.name} key="skill" />,
]

export default function Skill () {
  const { slug } = useParams()
  const breadcrumbs = useBreadCrumbs()

  return <CardPage
    promiseFn={fetchSkill}
    slug={slug}
    watch={slug}
    className="CodeDoor__Card__Skill"
    breadcrumbs={breadcrumbs}
  >
    {
      skill => <>
        <Title text={skill.name} />

        <header>
          <h1 className="title">{skill.name}</h1>
          <p className="lead">{skill.description}</p>
        </header>

        <section>
          {
            skill.projectDefinitions?.length
              ? <>
                <p className="label">Projects</p>
                <CardList large>
                  {
                    skill.projectDefinitions.map(
                      p => <ProjectDefinitionCard definition={p} key={p.id} />
                    )
                  }
                </CardList>
              </>
              : <p className="label">No Projects</p>
          }
          {
            skill.users?.length
              ? <>
                <p className="label">Students</p>
                <CardList large>
                  {
                    skill.users.map(u => <StudentCard user={u} key={u.id} />)
                  }
                </CardList>
              </>
              : <p className="label">No Students</p>
          }
        </section>

        <aside>
          {
            skill.resources?.length
              ? (
                <>
                  <p className="label">Resources</p>
                  {
                    skill.resources.map(r => (
                      <ExternalLinkTag
                        url={r.link}
                        label={r.name}
                        key={r.id}
                        className="CodeDoor__ResourceLink"
                      />
                    ))
                  }
                </>
              )
              : null
          }
          {
            skill.parent && <>
              <p className="label">Part of</p>
              <div><Tag title={skill.parent.name} to={`/skills/${skill.parent.slug}`} /></div>
            </>
          }
          {
            skill.children?.length
              ? <>
                <p className="label">Narrower Skills</p>
                <TagList>{ skill.children.map(s => <Tag title={s.name} to={`/skills/${s.slug}`} key={s.id} />) }</TagList>
              </>
              : null
          }
        </aside>
      </>
    }
  </CardPage>
}
