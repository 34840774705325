import { Link } from 'react-router-dom'
import Card from '@/components/Card'
import '@/styles/StatisticsCard.css'

export default function StatistcsGraphCard ({ data }) {

  return <>
    <Link to={data.link}>
      <Card className="CodeDoor__StatisticsCard">
        <h1>{data.count}</h1>
        <p className="label">{data.label}</p>

        {/* FIXME - this should be dynamic... */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="280"
          height="95"
          fill="none"
          viewBox="0 0 280 95"
        >
          <g clipPath="url(#clip0)">
            <mask
              id="path-1-outside-1"
              width="538"
              height="96"
              x="-2"
              y="0"
              fill="#000"
              maskUnits="userSpaceOnUse"
            >
              <path fill="#fff" d="M-2 0H536V96H-2z" />
              <path d="M534 38.898c0-5.46-7.718-6.575-9.264-1.34-.845 2.863-4.099 4.236-6.739 2.845l-.664-.35a5.343 5.343 0 00-7.405 2.63l-3.603 8.447c-2.319 5.436-9.53 6.567-13.402 2.102l-1.681-1.938a8.02 8.02 0 01-1.33-2.138l-2.302-5.47c-2.655-6.31-11.788-5.703-13.586.903-1.79 6.578-10.869 7.217-13.563.955l-2.749-6.392-2.571-7.269c-2.596-7.338-13.079-7.024-15.231.457l-2.651 9.217a7.716 7.716 0 01-14.568.769l-2.728-6.723c-1.932-4.761-8.784-4.421-10.236.508-1.618 5.497-9.544 5.06-10.549-.581l-1.109-6.223c-1.312-7.362-11.752-7.693-13.528-.429l-1.446 5.917c-.786 3.213-4.621 4.551-7.235 2.524-2.555-1.98-6.302-.754-7.19 2.354l-2.703 9.457c-2.086 7.3-12.214 7.86-15.094.837l-.206-.503a8 8 0 00-7.045-4.957l-2.032-.09a6.641 6.641 0 00-5.836 2.971c-3.26 4.931-10.853 3.465-12.043-2.325l-4.529-22.036c-.966-4.698-7.411-5.349-9.296-.939-1.729 4.045-7.501 3.935-9.073-.173l-1.745-4.56c-1.884-4.922-8.687-5.333-11.15-.675-2.117 4.003-7.693 4.41-10.369.756l-5.109-6.976-.129-.163c-4.24-5.349-12.801-3.273-14.119 3.424l-4.533 23.018c-1.084 5.51-8.346 6.86-11.339 2.109a6.206 6.206 0 00-7.17-2.594l-4.539 1.476a8.007 8.007 0 00-3.097 1.867l-3.557 3.452a8 8 0 01-11.56-.437l-5.347-6.038c-4.044-5.452-12.658-3.323-13.696 3.385l-2.917 18.85c-1.291 8.343-12.95 9.256-15.525 1.216l-.557-1.743-3.353-11.48c-1.909-6.54-11.32-6.09-12.598.601a6.49 6.49 0 01-1.941 3.521l-3.797 3.55a5.16 5.16 0 01-8.158-1.498l-2.594-5.294a8.067 8.067 0 01-.432-1.07l-3.273-10.173c-1.99-6.185-10.907-5.667-12.168.707-1.339 6.765-11.014 6.764-12.351-.001l-4.6-23.267a7.995 7.995 0 00-.352-1.242l-1.757-4.715c-2.736-7.342-13.303-6.78-15.246.81l-3.483 13.606a8 8 0 01-5.234 5.61l-1.704.564a6.464 6.464 0 01-7.66-2.954c-2.916-5.154-10.688-3.839-11.974 1.942-1.576 7.082-11.808 7.207-13.112.07l-.523-2.862c-1.302-7.129-11.108-8.139-13.835-1.425l-1.534 3.775-3.862 15.268c-1.711 6.764-11.396 6.543-12.797-.292-1.364-6.654-10.696-7.112-12.705-.624L.358 50.953A8 8 0 000 53.318V94H534V38.898z" />
            </mask>
            <path
              fill="url(#paint0_linear)"
              d="M534 38.898c0-5.46-7.718-6.575-9.264-1.34-.845 2.863-4.099 4.236-6.739 2.845l-.664-.35a5.343 5.343 0 00-7.405 2.63l-3.603 8.447c-2.319 5.436-9.53 6.567-13.402 2.102l-1.681-1.938a8.02 8.02 0 01-1.33-2.138l-2.302-5.47c-2.655-6.31-11.788-5.703-13.586.903-1.79 6.578-10.869 7.217-13.563.955l-2.749-6.392-2.571-7.269c-2.596-7.338-13.079-7.024-15.231.457l-2.651 9.217a7.716 7.716 0 01-14.568.769l-2.728-6.723c-1.932-4.761-8.784-4.421-10.236.508-1.618 5.497-9.544 5.06-10.549-.581l-1.109-6.223c-1.312-7.362-11.752-7.693-13.528-.429l-1.446 5.917c-.786 3.213-4.621 4.551-7.235 2.524-2.555-1.98-6.302-.754-7.19 2.354l-2.703 9.457c-2.086 7.3-12.214 7.86-15.094.837l-.206-.503a8 8 0 00-7.045-4.957l-2.032-.09a6.641 6.641 0 00-5.836 2.971c-3.26 4.931-10.853 3.465-12.043-2.325l-4.529-22.036c-.966-4.698-7.411-5.349-9.296-.939-1.729 4.045-7.501 3.935-9.073-.173l-1.745-4.56c-1.884-4.922-8.687-5.333-11.15-.675-2.117 4.003-7.693 4.41-10.369.756l-5.109-6.976-.129-.163c-4.24-5.349-12.801-3.273-14.119 3.424l-4.533 23.018c-1.084 5.51-8.346 6.86-11.339 2.109a6.206 6.206 0 00-7.17-2.594l-4.539 1.476a8.007 8.007 0 00-3.097 1.867l-3.557 3.452a8 8 0 01-11.56-.437l-5.347-6.038c-4.044-5.452-12.658-3.323-13.696 3.385l-2.917 18.85c-1.291 8.343-12.95 9.256-15.525 1.216l-.557-1.743-3.353-11.48c-1.909-6.54-11.32-6.09-12.598.601a6.49 6.49 0 01-1.941 3.521l-3.797 3.55a5.16 5.16 0 01-8.158-1.498l-2.594-5.294a8.067 8.067 0 01-.432-1.07l-3.273-10.173c-1.99-6.185-10.907-5.667-12.168.707-1.339 6.765-11.014 6.764-12.351-.001l-4.6-23.267a7.995 7.995 0 00-.352-1.242l-1.757-4.715c-2.736-7.342-13.303-6.78-15.246.81l-3.483 13.606a8 8 0 01-5.234 5.61l-1.704.564a6.464 6.464 0 01-7.66-2.954c-2.916-5.154-10.688-3.839-11.974 1.942-1.576 7.082-11.808 7.207-13.112.07l-.523-2.862c-1.302-7.129-11.108-8.139-13.835-1.425l-1.534 3.775-3.862 15.268c-1.711 6.764-11.396 6.543-12.797-.292-1.364-6.654-10.696-7.112-12.705-.624L.358 50.953A8 8 0 000 53.318V94H534V38.898z"
            />
            <path
              stroke="#5F61CE"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M534 38.898c0-5.46-7.718-6.575-9.264-1.34-.845 2.863-4.099 4.236-6.739 2.845l-.664-.35a5.343 5.343 0 00-7.405 2.63l-3.603 8.447c-2.319 5.436-9.53 6.567-13.402 2.102l-1.681-1.938a8.02 8.02 0 01-1.33-2.138l-2.302-5.47c-2.655-6.31-11.788-5.703-13.586.903-1.79 6.578-10.869 7.217-13.563.955l-2.749-6.392-2.571-7.269c-2.596-7.338-13.079-7.024-15.231.457l-2.651 9.217a7.716 7.716 0 01-14.568.769l-2.728-6.723c-1.932-4.761-8.784-4.421-10.236.508-1.618 5.497-9.544 5.06-10.549-.581l-1.109-6.223c-1.312-7.362-11.752-7.693-13.528-.429l-1.446 5.917c-.786 3.213-4.621 4.551-7.235 2.524-2.555-1.98-6.302-.754-7.19 2.354l-2.703 9.457c-2.086 7.3-12.214 7.86-15.094.837l-.206-.503a8 8 0 00-7.045-4.957l-2.032-.09a6.641 6.641 0 00-5.836 2.971c-3.26 4.931-10.853 3.465-12.043-2.325l-4.529-22.036c-.966-4.698-7.411-5.349-9.296-.939-1.729 4.045-7.501 3.935-9.073-.173l-1.745-4.56c-1.884-4.922-8.687-5.333-11.15-.675-2.117 4.003-7.693 4.41-10.369.756l-5.109-6.976-.129-.163c-4.24-5.349-12.801-3.273-14.119 3.424l-4.533 23.018c-1.084 5.51-8.346 6.86-11.339 2.109a6.206 6.206 0 00-7.17-2.594l-4.539 1.476a8.007 8.007 0 00-3.097 1.867l-3.557 3.452a8 8 0 01-11.56-.437l-5.347-6.038c-4.044-5.452-12.658-3.323-13.696 3.385l-2.917 18.85c-1.291 8.343-12.95 9.256-15.525 1.216l-.557-1.743-3.353-11.48c-1.909-6.54-11.32-6.09-12.598.601a6.49 6.49 0 01-1.941 3.521l-3.797 3.55a5.16 5.16 0 01-8.158-1.498l-2.594-5.294a8.067 8.067 0 01-.432-1.07l-3.273-10.173c-1.99-6.185-10.907-5.667-12.168.707-1.339 6.765-11.014 6.764-12.351-.001l-4.6-23.267a7.995 7.995 0 00-.352-1.242l-1.757-4.715c-2.736-7.342-13.303-6.78-15.246.81l-3.483 13.606a8 8 0 01-5.234 5.61l-1.704.564a6.464 6.464 0 01-7.66-2.954c-2.916-5.154-10.688-3.839-11.974 1.942-1.576 7.082-11.808 7.207-13.112.07l-.523-2.862c-1.302-7.129-11.108-8.139-13.835-1.425l-1.534 3.775-3.862 15.268c-1.711 6.764-11.396 6.543-12.797-.292-1.364-6.654-10.696-7.112-12.705-.624L.358 50.953A8 8 0 000 53.318V94H534V38.898z"
              mask="url(#path-1-outside-1)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="159"
              x2="159"
              y1="0"
              y2="94"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#292CB7" />
              <stop offset="1" stopColor="#9395FF" />
            </linearGradient>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0H279.99V94.223H0z" />
            </clipPath>
          </defs>
        </svg>

        <footer>
          { data.footer }
        </footer>
      </Card>
    </Link>
  </>
}
