import { Async } from 'react-async'
import API from '../api'
import { useAuth } from '../state/auth'
import CardList from './CardList'
import List from './Icons/List'
import Loading from './Loading'
import ProjectDefinitionCard from './ProjectDefinitionCard'
import '@/styles/ProjectCard.css'

const fetchProjects = ({ id }) => API.get(`/organisations/${id}/project-definitions/active`)

const fetchProjectDefinitions = ({ organisationId }) =>
  API.get(`/organisations/${organisationId}/project-definitions/inactive`)

export default function OrganisationProjects() {
  const { user } = useAuth()

  return <>
    <Async promiseFn={fetchProjects} id={user.organisation.id}>
      <Async.Pending>
        <h2 className="title is-secondary">Current</h2>
        <Loading />
      </Async.Pending>
      <Async.Fulfilled>
        {definitions => definitions.length
          ? <>
            <h2 className="title is-secondary">Hey! {user.organisation.name} Students currently work on these projects</h2>
            <CardList>
              {definitions.map(p => (
                <ProjectDefinitionCard definition={p} key={p.id} >
                  <p className='ProjectCardAnalytics'><span className="icon stats"><List /></span> {p.projects.length}
                    {p.projects.length > 1 ? ' teams are ' : ' team is '} currently working on this project</p>
                </ProjectDefinitionCard>
              ))}
            </CardList>
          </>
          : null
        }
      </Async.Fulfilled>
      <Async.Rejected>Error...</Async.Rejected>
    </Async>

    <h2 className="title">Available Projects</h2>
    <p className="title is-secondary">The following projects are available to your students with a free license or a paid license. </p>
    <Async promiseFn={fetchProjectDefinitions} organisationId={user.organisation.id}>
      <Async.Pending><Loading /></Async.Pending>
      <Async.Fulfilled>
        {data => (
          <CardList>
            {data.map(d => <ProjectDefinitionCard definition={d} key={d.id} />)}
          </CardList>
        )}
      </Async.Fulfilled>
      <Async.Rejected>Error...</Async.Rejected>
    </Async>
  </>
}
