import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import auth from '@/api/auth'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import Input from '@/components/Input'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'

const updatePassword = ([password, currentPassword]) => auth.updatePassword(password, currentPassword)

export default function UserPassword () {
  const { user } = useAuth()
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation()
  const { error, isPending, run } = useAsync({
    deferFn: updatePassword,
    onResolve: () => alert(t('settings.user.password.success')),
  })

  const submit = event => {
    event.preventDefault()

    run(password, currentPassword)
  }

  return <Card className="CodeDoor__UserSettings__Password is-large">
    <h1 className="title">{t('settings.user.password.title')}</h1>

    <form onSubmit={submit}>
      {/* Username for accessibility */}
      <input type="email" name="username" autoComplete="email" value={user.email} readOnly style={{ display: 'none' }} />

      <FormGroup
        label={t('settings.user.password.current')}
        error={getValidationMessage('current_password', error)}
      >
        <Input
          type="password"
          autoFocus
          autoComplete="current-password"
          placeholder="*********"
          value={currentPassword}
          onChange={setCurrentPassword}
        />
      </FormGroup>

      <FormGroup
        label={t('settings.user.password.new')}
        error={getValidationMessage('password', error)}
      >
        <Input
          type="password"
          autoFocus
          autoComplete="new-password"
          placeholder="*********"
          value={password}
          onChange={setPassword}
        />
      </FormGroup>

      <div className="actions">
        <Button type="submit" disabled={isPending}>{
          isPending
            ? t('vocab.submitting')
            : t('vocab.save')
        }</Button>
      </div>
    </form>
  </Card>
}
