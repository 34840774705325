import '../styles/UserGroup.css'
import { Link } from 'react-router-dom'
import { useAuth } from '../state/auth'

export default function UserLink ({ user, className, children = user.firstName }) {
  const { isAdmin } = useAuth()
  const link = isAdmin
    ? `/students/${user.id}`
    : `/peers/${user.id}`

  return (
    <Link to={link} className={className}>{children}</Link>
  )
}
