import { useState, useEffect } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import API from '@/api'
import SuccessModal from '@/components/SuccessModal'
import Button from './Button'
import EditTeam from './EditTeam'

const manageMentors = ([projectId, students]) => API.put(`/projects/${projectId}/mentors`, { students })

export default function ManageMentors ({
  projectId,
  open = true,
  setOpen = () => null,
  onCreated = () => null,
  users,
}) {
  const [fresh, setFresh] = useState(false)
  const [students, setStudents] = useState([])
  const { t } = useTranslation()
  const { data, run } = useAsync({ deferFn: manageMentors })

  useEffect(() => {
    const initialUser = users.map(user => user.id)
    setStudents(initialUser)
  }, [users])

  if (data && !fresh) {return <>
    <SuccessModal open={open} message={t('projects.admin.mentors.modal.success')} onCloseSuccess={onCreated}/>
  </>}

  return <>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">{t('projects.admin.mentors.modal.title')}</h1>
      <p className="lead">{t('projects.admin.mentors.modal.lead')}</p>

      <EditTeam
        students={students}
        setStudents={setStudents}
      />

      <footer>
        <Button  variant="secondary" onClick={() => setOpen(false)}>{t('vocab.cancel')}</Button>
        <Button onClick={() => {
          run(projectId, students)
          setFresh(false)
        }} disabled={students.length === 0}>{t('vocab.save')}</Button>
      </footer>
    </Modal>
  </>
}
