import { useState } from 'react'
import Modal from 'react-modal'
import Button from '@/components/Button'

function HintModal({ topic }) {
  const [showHint, setShowHint] = useState(false)

  if (topic.isSummary) return null

  const { answer } = topic
  if (!answer.hasWordMatcher) return null

  const unmatched = answer.keywords.filter(
    k => !answer.matches
      .filter(m => !!m)
      .map(m => m.toLowerCase())
      .includes(k)
  )

  if (!unmatched.length) return null

  const hint = unmatched[0]

  return <div>
    <Button onClick={() => setShowHint(true)}>Give me a hint</Button>
    <Modal
      isOpen={showHint}
      onRequestClose={() => setShowHint(false)}
      closeTimeoutMS={300}
    >
      <h1 className="title">Hint</h1>
      <p className="lead">Did you already find anything about “{hint}” regarding “{topic.name}”?</p>
      <footer>
        <Button onClick={() => setShowHint(false)}>Continue</Button>
      </footer>
    </Modal>
  </div>
}

export default HintModal
