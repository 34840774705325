import Button from './Button'
import Icon from './Icon'

function Sun () {
  return <Icon>
    <path d="M2.87871 11.7385L3.73325 12.554L4.82416 11.5187L3.96356 10.6974L2.87871 11.7385ZM7.39386 14C7.5878 14 8.60598 14 8.60598 14V12.2938H7.39386V14ZM7.99992 4.19636C7.0355 4.19636 6.11057 4.56198 5.42862 5.21279C4.74667 5.8636 4.36356 6.74629 4.36356 7.66668C4.36356 8.58706 4.74667 9.46975 5.42862 10.1206C6.11057 10.7714 7.0355 11.137 7.99992 11.137C8.96434 11.137 9.88927 10.7714 10.5712 10.1206C11.2532 9.46975 11.6363 8.58706 11.6363 7.66668C11.6363 5.74643 10.006 4.19636 7.99992 4.19636ZM12.8484 8.24506H14.6666V7.08829H12.8484V8.24506ZM11.1757 11.5187L12.2666 12.554L13.1211 11.7385L12.0363 10.6974L11.1757 11.5187ZM13.1211 3.59484L12.2666 2.77931L11.1757 3.81462L12.0363 4.63593L13.1211 3.59484ZM8.60598 1.33334H7.39386V3.03958H8.60598V1.33334ZM3.15143 7.08829H1.33325V8.24506H3.15143V7.08829ZM4.82416 3.81462L3.73325 2.77931L2.87871 3.59484L3.96356 4.63593L4.82416 3.81462Z" fill="currentColor"/>
  </Icon>
}

function SunButton (props) {
  return <Button {...props}><Sun /></Button>
}

export default Sun
export { SunButton }
