import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import auth from '@/api/auth'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import Help from '@/components/Icons/Help';
import { LogoPicker } from '@/components/ImagePicker'
import Input from '@/components/Input'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'
import '@/styles/AdminHome.css'

const updateOrganisation = ([id, data]) => auth.updateOrganisation(id, data)

const onComplete = () => localStorage.setItem('showTourO', 'no');
const onExit = () => { return true }
const steps = [
  {
    title: 'Organisation profile',
    intro: '<div style="font-weight: lighter; font-size: medium;">You now see your organisation\'s profile page. Please check the data: is everything entered correctly? Do you want to change or add anything?</div>',
  },
  {
    element: '.logoPicker',
    intro: '<div style="font-weight: lighter; font-size: medium;">Please upload a logo or picture to represent your organisation.</div>',
    position: 'top',
  },
  {
    element: '.step-done',
    intro: '<div style="font-weight: lighter; font-size: medium;">Don\'t forget to press the "Save"-Button in the bottom right corner as soon as you are done.</div>',
    position: 'top',
  },
];

export default function OrganisationProfile() {
  const { user, setUserOrganisation } = useAuth()
  const { organisation } = user
  const [city, setCity] = useState(organisation.city)
  const [country, setCountry] = useState(organisation.country)
  const [email, setEmail] = useState(organisation.email)
  const [legalName, setLegalName] = useState(organisation.legalName)
  const [name, setName] = useState(organisation.name)
  const [phone, setPhone] = useState(organisation.phone)
  const [streetAddress, setStreetAddress] = useState(organisation.streetAddress)
  const [zip, setZip] = useState(organisation.zip)
  const { t } = useTranslation()
  const [buttonText, setButtonText] = useState('Save')
  const changeText = (text) => setButtonText(text);
  const { error, isPending, run } = useAsync({
    deferFn: updateOrganisation,
    onResolve: organisation => setUserOrganisation(organisation),
  })

  const submit = event => {
    event.preventDefault()

    run(organisation.id, {
      city,
      country,
      email,
      legalName,
      name,
      phone,
      streetAddress,
      zip,
    })
  }

  const [enableTour, setEnableTour] = useState(true)
  const [showTourO, setShowTourO] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem('showTourO');
    return saved || 'yes';
  });

  const reloadTour = () => {
    setShowTourO((Math.random() + 1).toString(36).substring(7));
    setEnableTour(true);
    localStorage.setItem('showTour3', 'yes');
  }

  return <Card className="CodeDoor__OrganisationSettings__Profile is-large">
    <header>
      <h1 className="title">{t('settings.organisation.profile.title')}</h1>
      <div className="actions">
        <Button onClick={() => reloadTour()} variant='secondary' className="is-small"><Help/></Button>
      </div>
    </header>

    <p className="label">{t('vocab.logo')}</p>
    <div className="logoPicker" style={{ width: 'fit-content' }}><LogoPicker /></div>

    <form onSubmit={submit}>
      <FormGroup
        label={t('vocab.name')}
        error={getValidationMessage('name', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="ACME"
          value={name}
          onChange={setName}
          required
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.email')}
        error={getValidationMessage('email', error)}
      >
        <Input
          type="email"
          autoFocus
          placeholder="acme@example.com"
          value={email}
          onChange={setEmail}
          required
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.phone')}
        error={getValidationMessage('phone', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="+49 89 88 99 10"
          value={phone}
          onChange={setPhone}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.legalName')}
        error={getValidationMessage('legalName', error)}
      >
        <Input
          type="text"
          autoFocus
          required
          placeholder="ACME Inc."
          value={legalName}
          onChange={setLegalName}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.street_address')}
        error={getValidationMessage('streetAddress', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="Streetname 1"
          value={streetAddress}
          onChange={setStreetAddress}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.zip')}
        error={getValidationMessage('zip', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="XX-XX-XX"
          value={zip}
          onChange={setZip}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.city')}
        error={getValidationMessage('city', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="Berlin"
          value={city}
          onChange={setCity}
        />
      </FormGroup>

      <FormGroup
        label={t('vocab.country')}
        error={getValidationMessage('country', error)}
      >
        <Input
          type="text"
          autoFocus
          placeholder="Germany"
          value={country}
          onChange={setCountry}
        />
      </FormGroup>

      <div className="actions">
        <Button className="step-done" type="submit" disabled={isPending} onClick={() => changeText('Saved! Looking Good!')}>{
          isPending
            ? t('vocab.submitting')
            : buttonText
        }</Button>
      </div>
    </form>

    {showTourO !== 'no' && <Steps
      enabled={enableTour}
      steps={steps}
      initialStep={0}
      onComplete={onComplete}
      onExit={onExit}
      options={{ hideNext: false, doneLabel: 'Got it!' }}

    />}
  </Card>
}
