import { getCookie } from '@/helpers/cookies'
import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'

/**
 * Track whether an error has been shown and the site has not yet reloaded...
 */
let errorShown = false
let uri = '';

const client = async (endpoint, { method = 'GET', body, ...customConfig } = {}) => {
  const headers = {
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
    'Accept-Language': storage.getItem('i18nextLng'),
  }

  const config = {
    method,
    ...customConfig,
    credentials: 'include',
    mode: 'cors',
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      delete (config.headers['Content-Type'])
      config.body = body
    } else {
      config.headers['Content-Type'] = 'application/json'
      config.body = JSON.stringify(body)
    }
  }

  if (endpoint.includes('https')) {
    uri = endpoint;
  }
  else uri = process.env.REACT_APP_API_URL + endpoint;
  const response = await fetch(uri, config);

  if (response.status === 401 && !errorShown && endpoint !== '/user') {
    alert('Your session expired. Please log in again!')
    errorShown = true
    storage.removeItem(LOCALSTORAGE_KEYS.USER)
    return window.location.assign('/auth/login')
  }

  if (response.status === 419 && !errorShown) {
    alert('The page expired. Please try again!')
    errorShown = true
    return window.location.reload()
  }

  try {
    const body = await response.json()
    const data = body.data ? body.data : body

    if (response.ok) {
      return data
    }
    return Promise.reject(data)

  } catch (e) {
    return response
  }
}

const API = {
  get (endpoint, customConfig = {}) {
    return client(endpoint, customConfig)
  },

  post (endpoint, body = {}, customConfig = {}) {
    return client(endpoint, { body, method: 'POST', ...customConfig })
  },

  put (endpoint, body = {}, customConfig = {}) {
    return client(endpoint, { body, method: 'PUT', ...customConfig })
  },

  delete (endpoint, customConfig = {}) {
    return client(endpoint, { method: 'DELETE', ...customConfig })
  },

  upload (endpoint, body = {}, customConfig = {}) {
    const uploadConfig = {
      ...customConfig,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return client(endpoint, { body, method: 'POST', ...uploadConfig })
  },
}

export default API
