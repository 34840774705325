import React, { useState } from 'react'
import { useAsync, Async } from 'react-async'
import EasyEdit, { Types } from 'react-easy-edit';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import API from '@/api'
import Breadcrumbs from '@/components/Breadcrumbs'
import Button from '@/components/Button'
import Card from '@/components/Card'
import { Title } from '@/components/Meta'
import ProjectDefinitionOutlineEdit from '@/components/ProjectDefinitionOutlineEdit'
import Tag from '@/components/Tag'
// import { LOCALSTORAGE_KEYS, storage } from '@/helpers/storage/LocalStorage'
import { useAuth } from '@/state/auth'
import newProjectDefinition, { emptyOutlineItem } from './definition-template'
import Loading from './Loading'
import '@/styles/CardPageProjectDefinition.css'
import '@/styles/ProjectDefinitionEditor.css'

// const projectDefinitionFromStorage = storage.getItem(LOCALSTORAGE_KEYS.PROJECT_DEFINITION)
// const syncDefinitionToLS = (key, value) => storage.setItem(key, value)

const sendDefinition = ([definition]) => API.post('/project-definitions', { definition })
const fetchSkills = () => API.get(`/skills`)

const generateLevelOptions = (t) => ([
  { label: t('vocab.BEGINNER'), value: 'BEGINNER' },
  { label: t('vocab.INTERMEDIATE'), value: 'INTERMEDIATE' },
  { label: t('vocab.ADVANCED'), value: 'ADVANCED' },
])

export default function ProjectDefinitionEditor() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [definition, setDefinition] = useState(newProjectDefinition)
  const history = useHistory()
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('UPDATING', { definition })
  }, [
    definition,
    definition.description,
    definition.level,
    definition.name,
    definition.outline,
    definition.skills,
  ])

  const breadcrumbs = [
    {
      label: t('projects.title'),
      to: '/projects',
    },
    d => <Breadcrumbs.Item label={d.name} key="definition" />,
  ]

  const { isPending, run } = useAsync({
    deferFn: sendDefinition,
    onResolve: definition => {
      // eslint-disable-next-line no-console
      console.log('Project definition created', { definition })
      alert(`"${definition.name}" project definition created! \nYou will be redirected to projects page once you click ok. `)
      history.push(`/projects`)
    },
  })

  const validateDefinition = definition => {
    const isEmpty = (item) => typeof (item) === 'string' ? item.length === 0 || !item.trim() : item.length === 0
    const checkOutline = (outline) => {
      const outlineUpdates = {
        nameError: isEmpty(outline.name),
        descriptionError: isEmpty(outline.description),
      }
      return { ...outline, ...outlineUpdates, children: outline.children.map(checkOutline) }
    }

    return {
      nameError: isEmpty(definition.name),
      descriptionError: isEmpty(definition.description),
      outlineError: isEmpty(definition.outline),
      outline: definition.outline.map(checkOutline),
    }
  }

  const validationHasErrors = (validationResults) => {
    const { nameError, descriptionError, outlineError } = validationResults
    // check name , descripion, outline for errors
    if (nameError || descriptionError || outlineError) return true
    // check outline items for errors
    const outlineItemsErrors = validationResults.outline.some(item => {
      const itemHasErrors = item.nameError || item.descriptionError
      // check outline item children for errors
      return itemHasErrors || item.children.some(child => child.nameError || child.descriptionError)
    })
    return outlineItemsErrors
  }

  const handleSendDefinition = event => {
    event.preventDefault();
    const definitionWithOrgId = {
      ...definition,
      organisation_id: user.organisation.id,
    };
    const validationResults = validateDefinition(definitionWithOrgId)

    if (validationHasErrors(validationResults)) {
      setDefinition({ ...definition, ...validationResults })
      alert('Some fileds were incorrect. \nPlease double check that they are filled in and outline is not empty.')
    } else {
      run(definitionWithOrgId)
    }

  }

  const addNewOutlineItem = () => {
    const { outline } = definition
    setDefinition({ ...definition, outline: outline.concat(emptyOutlineItem) })
  }

  return (
    <div className="container">
      {breadcrumbs && (
        <Breadcrumbs>
          {
            breadcrumbs
              .map((b, index) => typeof b === 'function'
                ? b(definition)
                : <Breadcrumbs.Item to={b.to} label={b.label} key={index + 1} />)
              .filter(b => !!b)
          }
        </Breadcrumbs>
      )}
      <Card className='CodeDoor__Card__ProjectDefinition is-large'>
        {<>
          <Title text={definition.name} />
          <header>
            <EasyEdit
              value={definition.name || ''}
              saveOnBlur
              onHoverCssClass="inline-hover-on"
              cssClassPrefix="CodeDoor__Inline__DefinitionEditor title "
              displayComponent={<h1 className={classNames('title', { 'has-error': definition.nameError })}>{definition.name || 'title'}</h1>}
              placeholder={definition.name || 'title'}
              type={Types.TEXT}
              // validationMessage="empty"
              // onValidate={value => {return value != null && value !== ''}}
              onSave={
                (value) => {
                  let newValue = value
                  if (value !== '' && value == null) {
                    newValue = ''
                  }
                  const updatedDefinition = { ...definition, name: newValue }
                  setDefinition(prevState => ({ ...prevState, ...updatedDefinition }))
                }
              }
            />
            <div className="actions">
              <Button className="is-large" onClick={handleSendDefinition} disabled={isPending}>Save Outline</Button>
            </div>
          </header>
          <EasyEdit
            value={definition.description}
            saveOnBlur
            onHoverCssClass="inline-hover-on"
            cssClassPrefix="CodeDoor__Inline__DefinitionEditor lead "
            displayComponent={
              <p
                className={classNames('lead', { 'has-error': definition.nameError })}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: definition.description || 'subtitle',
                }}
              />}
            placeholder={definition.description || 'subtitle'}
            type={Types.TEXT}
            onSave={
              (value) => {
                let newValue = value
                if (value === '' || value == null) {
                  newValue = ''
                }
                const updatedDefinition = { ...definition, description: newValue }
                setDefinition(updatedDefinition)
              }
            }
          />

          <Async promiseFn={fetchSkills}>
            <Async.Pending><Loading /></Async.Pending>
            <Async.Fulfilled>
              {data => {
                return (
                  <section className="full">
                    <p className="label">{t('vocab.level')}</p>
                    <EasyEdit
                      saveOnBlur
                      onHoverCssClass="inline-hover-on"
                      cssClassPrefix="CodeDoor__Inline__DefinitionEditor"
                      displayComponent={<div><Tag title={definition.level} /></div>}
                      placeholder={definition.level}
                      type={Types.SELECT}
                      options={generateLevelOptions(t)}
                      onSave={(level) => setDefinition({ ...definition, level })}
                    />
                    <ProjectDefinitionOutlineEdit
                      definition={definition}
                      setDefinition={setDefinition}
                      availableSkills={data}
                    />
                    <Button onClick={addNewOutlineItem}>Add Outline Item</Button>
                  </section>
                )
              }
              }
            </Async.Fulfilled>
            <Async.Rejected>Error...</Async.Rejected>
          </Async>
        </>}
      </Card>
    </div>
  )
}
