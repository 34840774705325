import { useTranslation } from 'react-i18next'
import { Title } from '@/components/Meta'
import { useAuth } from '@/state/auth'
import UserLocale from './UserLocale'
import UserPassword from './UserPassword'
import UserProfile from './UserProfile'
import UserProjects from './UserProjects'
import '@/styles/UserSettings.css'

export default function User() {
  const { t } = useTranslation()
  const { isAdmin } = useAuth()

  return <div className="container CodeDoor__UserSettings">
    <Title text={t('settings.user.title')} />

    <UserProfile />
    {
      !isAdmin && (
        <UserProjects id="projects" />
      )
    }
    <UserLocale />
    <UserPassword />
  </div>
}
