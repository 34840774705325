import { useState } from 'react'
import Modal from 'react-modal'
import Button from './Button'
import VideoTalkToAi from './Editor/VideoTalkToAi'
import '../styles/VideoAIModal.css'

const VideoModal = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => setModalOpen(!modalOpen)

  return (
    <div className='buttonmargin'>
      <Button className="CodeDoor__FeedbackButton" onClick={toggleModal}>?</Button>
      <Modal
        className="VideoAIModal"
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        closeTimeoutMS={300}
      >
        <VideoTalkToAi />
      </Modal>
    </div>
  )
}

export default VideoModal
