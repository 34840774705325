import { Link } from 'react-router-dom'
import '@/styles/Breadcrumbs.css'

const Breadcrumbs = ({ children }) => (
  <div className="CodeDoor__Breadcrumbs">
    {
      children.map((child, index) => <span key={index}>{child}<span className="separator">/</span></span>)
    }
  </div>
)

Breadcrumbs.Item = ({ label, to }) => (
  to
    ? <Link to={to} className="label">{label}</Link>
    : <span>{label}</span>
)

export default Breadcrumbs
