import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownItem,
} from '@/components/Dropdown'
import ExternalLinkTag from './ExtenalLinkTag'
import Delete from './Icons/Delete'
import Edit from './Icons/Edit'
import LinkIcon from './Icons/Link'
import '@/styles/Attachment.css'

export default function Attachment ({ attachment, onUpdateClick, onDeleteClick, canEdit }) {
  const triggerUpdate = () => {
    onUpdateClick(attachment)
  }

  const triggerDetelte  = () => {
    onDeleteClick(attachment)
  }

  const triggerOpen = () => {
    window.open(attachment.url)
  }

  return (
    <div className="CodeDoor__Attachment">
      {
        canEdit
          ? <Dropdown>
            <DropdownTrigger>
              <ExternalLinkTag href="#" url={attachment.url} label={attachment.name} />
            </DropdownTrigger>

            <DropdownContent className="CodeDoor__Attachment__Dropdown">
              <DropdownItem onSelect={triggerOpen}>
                <div className="CodeDoor__Attachment__DropdownItem">
                  <LinkIcon /> Open "{attachment.name}"
                </div>
              </DropdownItem>
              <DropdownItem onSelect={triggerUpdate}>
                <div className="CodeDoor__Attachment__DropdownItem">
                  <Edit /> Edit
                </div>
              </DropdownItem>
              <DropdownItem onSelect={triggerDetelte}>
                <div className="CodeDoor__Attachment__DropdownItem">
                  <Delete /> Delete
                </div>
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
          : <ExternalLinkTag url={attachment.url} label={attachment.name} />
      }
    </div>
  )

}
