import { useTranslation } from 'react-i18next'
import shorten from '@/helpers/shorten'
import { useAuth } from '@/state/auth'
import Card from './Card'
import UserGroup from './UserGroup'
import '@/styles/ProjectListItem.css'

const ProjectListItem = ({ project }) => {
  const { user, isAdmin } = useAuth()
  const canView = project.students.includes(u => u.id === user.id)
    || project.mentors?.includes(u => u.id === user.id)
    || isAdmin
  const link = canView && `/projects/${project.id}`
  const { t } = useTranslation()

  return (
    <Card to={link} className="CodeDoor__ProjectListItem">
      <header>
        <h1>{project.name}</h1>

        <UserGroup users={project.students} className="is-small" />
      </header>

      <main>
        <p>{shorten(project.description)}</p>
        <p className="is-small margin-progress">{t('vocab.progress_single')}: {project.progress}% </p>
      </main>
    </Card>
  )
}

export default ProjectListItem
