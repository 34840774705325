import { useTranslation } from 'react-i18next'
import { Link, NavLink, useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownItem,
} from '@/components/Dropdown'
import { useAuth } from '@/state/auth'
import Avatar from './Avatar'
import FeedbackModal from './FeedbackModal'
import '@/styles/TopBar.css'

function TopBar() {
  const { user, isAdmin, signOut } = useAuth()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <header className="CodeDoor__TopBar">
      <div className="left">
        <Link to="/">
          <img src="/cddr-logo.png" alt="CodeDoor" />
          CodeDoor
        </Link>
      </div>

      <div className="center">
        {
          isAdmin
            ? <>
              <NavLink to="/students" className="label tour4" activeClassName="is-active">{t('vocab.students')}</NavLink>
              <NavLink to="/projects" className="label" activeClassName="is-active">{t('vocab.projects')}</NavLink>
              <NavLink to="/skills" className="label" activeClassName="is-active">{t('vocab.skills')}</NavLink>
            </>
            : <>
              <Dropdown className="dropdown_padding">
                <DropdownTrigger>
                  <div className="dropdown_padding" activeClassName="is-active">Learn with AI</div>
                </DropdownTrigger>

                <DropdownContent className="CodeDoor__Dropdown">
                  <DropdownItem onSelect={() => history.push('/')} className="CodeDoor__Dropdown">
                    <Link to="/">
                      {t('vocab.ai')}
                    </Link>
                  </DropdownItem>
                  <DropdownItem onSelect={() => history.push('/belture')} className="CodeDoor__Dropdown">
                    <Link to="/">
                      {t('vocab.belture')}
                    </Link>
                  </DropdownItem>
                  <DropdownItem onSelect={() => history.push('/settings/user/')} className="CodeDoor__Dropdown">
                    <Link to="/settings/user/#projects">
                      {t('vocab.currentprojects')}
                    </Link>
                  </DropdownItem>

                </DropdownContent>
              </Dropdown>

              <Dropdown className="dropdown_padding">
                <DropdownTrigger activeClassName="is-active">
                  <div className="dropdown_padding">Explore and Connect</div>
                </DropdownTrigger>

                <DropdownContent className="CodeDoor__Dropdown">
                  <DropdownItem onSelect={() => history.push('/projects')} className="CodeDoor__Dropdown">
                    <Link to="/projects">
                      {t('vocab.projects')}
                    </Link>
                  </DropdownItem>
                  <DropdownItem onSelect={() => history.push('/skills')} className="CodeDoor__Dropdown">
                    <Link to="/skills">
                      {t('vocab.skills')}
                    </Link>
                  </DropdownItem>
                  <DropdownItem onSelect={() => history.push('/peers')} className="CodeDoor__Dropdown">
                    <Link to="/peers">
                      {t('vocab.peers')}
                    </Link>
                  </DropdownItem>
                  <DropdownItem onSelect={() => history.push('/peersranking')} className="CodeDoor__Dropdown">
                    <Link to="/peersranking">
                      {t('vocab.peersranking')}
                    </Link>
                  </DropdownItem>

                </DropdownContent>
              </Dropdown>
            </>
        }
      </div>

      <div className="right">
        <FeedbackModal />
        <Dropdown>
          <DropdownTrigger>
            <span className="tour2" >{user.fullName}</span>
            <Avatar user={user} />
          </DropdownTrigger>

          <DropdownContent className="CodeDoor__Dropdown">
            <DropdownItem onSelect={() => history.push('/settings/user')} className="CodeDoor__Dropdown">
              <Link to="/settings/user">
                {t('navigation.user_settings')}
              </Link>
            </DropdownItem>
            {
              isAdmin && (
                <DropdownItem onSelect={() => history.push('/settings/organisation')} className="CodeDoor__Dropdown">
                  <Link to="/settings/organisation">
                    {t('navigation.organisation_settings')}
                  </Link>
                </DropdownItem>
              )
            }
            <DropdownItem onSelect={signOut} className="CodeDoor__Dropdown">
              {t('navigation.logout')}
            </DropdownItem>
          </DropdownContent>
        </Dropdown>
      </div>
    </header>
  )
}

export default TopBar
