import { useTranslation } from 'react-i18next'
import Breadcrumbs from '@/components/Breadcrumbs'
import { PlainUserNameList } from '@/components/UserGroup'
import { useAuth } from '@/state/auth'

export const getProjectTitle = (project, isAdmin = false) => project.name !== project.definition.name || !isAdmin
  ? project.name
  : PlainUserNameList({ prepend: `${project.definition.name} by `, users: project.students })

const useProjectBreadcrumbs = () => {
  const { isAdmin } = useAuth()
  const { t } = useTranslation()

  const breadcrumbs = [
    // FIXME - refactor this. The mix of JSX and config objects is weird.
    {
      label: t('projects.title'),
      to: '/projects',
    },
  ]

  if (isAdmin) {
    breadcrumbs.push(
      project => <Breadcrumbs.Item
        label={project.definition.name}
        to={`/project-definitions/${project.definition.id}`}
        key="definition"
      />
    )
  }

  breadcrumbs.push(project => {
    const title = getProjectTitle(project, isAdmin)

    return <Breadcrumbs.Item
      label={title}
      key="project"
    />
  })

  return breadcrumbs
}

export default useProjectBreadcrumbs
