import '@/styles/ProjectOutline.css'
import ProjectOutlineItem from './ProjectOutlineItem'

export default function ProjectOutline({ outline }) {
  return <>
    <p className="label">Outline</p>
    <div className="CodeDoor__ProjectOutline">
      {
        outline?.map(topic => <ProjectOutlineItem item={topic} key={topic.id} />)
      }
    </div>
  </>
}
