import { useState } from 'react'
import Modal from 'react-modal'
import API from '@/api'
import { useAuth } from '@/state/auth'
import Attachment from './Attachment'
import Button from './Button'
import FormGroup from './FormGroup'
import Add from './Icons/Add'
import Input from './Input'

const addAttachmentCall = (projectId, attachment) => API.post(`/projects/${projectId}/attachments`, attachment)
const updateAttachmentCall = (attachment) => API.put(`/attachments/${attachment.id}`, attachment)
const deleteAttachmentCall = (attachment) => API.delete(`/attachments/${attachment.id}`)

export default function AttachmentList ({ projectId, items }) {
  const [attachments, setAttachments] = useState(items)
  const [isBusy, setIsBusy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalAction, setModalAction] = useState('add')
  const [modalData, setModalData] = useState({ name: '', url: '' })

  const { isStudent } = useAuth()

  const toggleModal = (suppliedData = null, modalAction = 'add') => {
    setModalOpen(!modalOpen)
    setModalData(suppliedData || { name: '', url: '' })
    setModalAction(modalAction)
  }

  const addAttachment = async attachment => {
    setIsBusy(true)
    setAttachments([...attachments, await addAttachmentCall(projectId, attachment)])
    setIsBusy(false)
    toggleModal()
  }

  const updateAttachment = async attachment => {
    setIsBusy(true)
    setAttachments([
      ...attachments.filter(a => a.id !== attachment.id),
      await updateAttachmentCall(attachment),
    ])
    setIsBusy(false)
    toggleModal()
  }

  const deleteAttachment = async attachment => {
    if (! window.confirm('Do you really want to delete this attachment?')) return

    setIsBusy(true)
    const response = await deleteAttachmentCall(attachment)
    if (response.ok) {
      setAttachments(attachments.filter(item => item.id !== attachment.id))
      setIsBusy(false)
    }
  }

  const modalTitle = modalAction === 'add'
    ? 'Create Attachment'
    : 'Modify Attachment'
  const modalOnSave = modalAction === 'add'
    ? () => addAttachment(modalData)
    : () => updateAttachment(modalData)
  const canSubmit = modalData.name.trim() !== ''
    && modalData.url.trim() !== ''
    && !isBusy

  return (
    <div className="CodeDoor__AttachmentList">
      { isStudent && <>
        <Button
          onClick={() => toggleModal()}
          title="New Attachment"
          variant="secondary"
          className="is-tiny"
        ><Add /></Button>
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          closeTimeoutMS={300}
        >
          <h1 className="title">{modalTitle}</h1>
          <FormGroup label="Name">
            <Input
              type="text"
              placeholder="My attachment"
              value={modalData.name}
              onChange={(name) => setModalData({ ...modalData, name })}
            />
          </FormGroup>
          <FormGroup label="URL">
            <Input
              type="text"
              placeholder="https://example.com"
              value={modalData.url}
              onChange={(url) => setModalData({ ...modalData, url })}
            />
          </FormGroup>

          <footer>
            <Button
              disabled={isBusy}
              variant="secondary"
              onClick={() => toggleModal()}
            >Close</Button>
            <Button
              disabled={!canSubmit}
              onClick={modalOnSave}
            >
              {isBusy ? 'Saving...' : 'Save attachment'}
            </Button>
          </footer>
        </Modal>
      </> }

      {
        attachments.map(att => (
          <Attachment
            canEdit={isStudent}
            key={att.id}
            attachment={att}
            onUpdateClick={() => toggleModal(att, 'edit')}
            onDeleteClick={deleteAttachment}
          />
        ))
      }
    </div>
  )

}
