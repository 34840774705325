import Search from '@/components/Icons/Search'
import '@/styles/SearchBar.css'

const SearchBar = ({ keyword, updateInput }) => {
  return (
    <div className="CodeDoor__SearchBar">
      <input
        className="CodeDoor__SearchBar__input"
        key="searchbar"
        value={keyword}
        placeholder="Search Email"
        onChange={(e) => updateInput(e.target.value)}
      />
      <Search/>
    </div>
  )
}

export default SearchBar
