import shorten from '../helpers/shorten'
import striphtml from '../helpers/striphtml'
import Button from './Button'
import Card from './Card'
import IframeProjectDefinition from './Editor/VideoProjectDefinition'
import Tag from './Tag'
import '@/styles/ProjectCard.css'

const ProjectDefinitionCard = ({ children, definition }) => <Card to={`/project-definitions/${definition.id}`} className="CodeDoor__ProjectCard">
  <header>
    <div className="ProjectCard__thumbnail">
      {definition.video ? <IframeProjectDefinition video={definition.video} /> : <img src="https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/0fbdcec8-b93d-4893-9ee2-9877b43f8ade.gif?ClientID=vimeo-core-prod&Date=1652357952&Signature=45c5495ed29d43ce936a49f3f401ca794eda194f" alt="video about project" />}
    </div>
  </header>
  <main>
    <h1>{definition.name}</h1>
    <p>{shorten(striphtml(definition.description))}</p>
    <div className="ProjectCard__tags">
      {
        definition.skills?.length && (
          <div className="skills">
            {
              definition.skills
                ?.slice(0, 3)
                ?.map(s => <Tag title={s.name} to={`/skills/${s.slug}`} key={s.id} />)
            }
          </div>
        )
      }
    </div>
  </main>

  <footer>
    <Tag title={definition.level} />
    {definition.price > 0 ? <Button className="CodeDoor__Button__license is-small">Get License</Button> : <Button className="button is-primary is-small">License &#10003;</Button>}
  </footer>
  {/* {definition.price ? <p>License fee: €{definition.price} </p> : <p>Free</p>} */}
  {children}
</Card>

export default ProjectDefinitionCard
