import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import authApi from '@/api/auth'
import Button from '@/components/Button'
import FormGroup from '@/components/FormGroup'
import Input from '@/components/Input'
import { Title } from '@/components/Meta'

export default function RequestPasswordReset () {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { t } = useTranslation()

  // On Click send Code to Email
  async function handleSendCodeClick (event) {
    event.preventDefault()
    setSubmitted(true)

    try {
      await authApi.requestPasswordResetEmail(email)
    } catch (error) {
      // ...
    }
  }

  return <>
    <Title text={t('auth.request_password_reset.title')} />

    <h1>{t('auth.request_password_reset.title')}</h1>

    {
      submitted
        ? <>
          <div>
            <p>{t('auth.request_password_reset.submitted.p1', { email })} </p>
            <p>{t('auth.request_password_reset.submitted.p2')}</p>
          </div>
          <div className="actions">
            <Button
              onClick={() => setSubmitted(false)}
              variant="secondary"
            >{t('vocab.try_again')}</Button>

            <Button to="login">{t('auth.to_login')}</Button>
          </div>
        </> : (
          <form onSubmit={handleSendCodeClick}>
            <FormGroup label={t('vocab.email')}>
              <Input
                autoFocus
                type="email"
                value={email}
                placeholder="john@doe.com"
                onChange={setEmail}
                required
              />
            </FormGroup>
            <div className="actions">
              <Button to="login" variant="secondary">
                {t('auth.to_login')}
              </Button>
              <Button type="submit">
                {t('auth.request_password_reset.cta')}
              </Button>
            </div>
          </form>
        )
    }
  </>
}
