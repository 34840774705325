import { useEffect, useState } from 'react'

export default function useForceUpdate () {
  const [, update] = useTrackedForceUpdate()
  return update
}

export function useTrackedForceUpdate () {
  const [value, setValue] = useState(0)
  return [value, () => setValue(value => value + 1)]
}

export function usePollingForceUpdate (time = 2000) {
  const update = useForceUpdate()

  useEffect(() => {
    setInterval(() => update(), time)
  }, [time, update])
}
