import { useTranslation } from 'react-i18next'
import { Title } from '@/components/Meta'
import MyActiveProjects from '@/components/MyActiveProjects'
import TalkToAi from '@/components/TalkToAi'
import VideoModal from '@/components/VideoModal'
import { useAuth } from '@/state/auth'
import '@/styles/StudentHome.css'

export default function StudentHome() {
  const { user } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="container">
      <Title text={t('home.meta_title')} />

      <h1 className="title">{t('home.title', { name: user.firstName })}</h1>
      {/* <p className="lead">{t('home.students.lead')}</p> */}

      {/* <h2 className="title is-secondary">{t('home.students.daily')} <small>{t('home.students.how_are_you')}</small></h2> */}
      {/* <HealthIndicator /> */}
      <h2 className="title is-secondary">{t('home.students.belture')}          <VideoModal /></h2>
      <TalkToAi />

      <MyActiveProjects title={t('home.students.projects')} />

      {/* <h2 className="title is-secondary">Your Mentors</h2> */}
    </div>
  )
}
