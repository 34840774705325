import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import auth from '@/api/auth'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormGroup from '@/components/FormGroup'
import { getValidationMessage } from '@/helpers/errors'
import { useAuth } from '@/state/auth'

const LOCALES = [
  'en',
  'de',
]

const updateLocale = ([locale]) => auth.updateLocale(locale)

export default function UserLocale () {
  const { user } = useAuth()
  const [locale, setLocale] = useState(user.locale)
  const { i18n, t } = useTranslation()
  const { error, isPending, run } = useAsync({
    deferFn: updateLocale,
    onResolve: () => i18n.changeLanguage(locale),
  })

  const submit = event => {
    event.preventDefault()

    run(locale)
  }

  return <Card className="CodeDoor__UserSettings__Locale is-large">
    <h1 className="title">{t('settings.user.locale.title')}</h1>

    <form onSubmit={submit}>
      <FormGroup
        label={t('vocab.locale')}
        error={getValidationMessage('locale', error)}
      >
        <select
          className="CodeDoor__Input"
          value={locale}
          onChange={event => setLocale(event.target.value)}
        >
          {
            LOCALES.map(l => (
              <option value={l} key={l}>{t(`locales.${l}`)}</option>
            ))
          }
        </select>
      </FormGroup>

      <div className="actions">
        <Button type="submit" disabled={isPending}>{
          isPending
            ? t('vocab.submitting')
            : t('vocab.save')
        }</Button>
      </div>
    </form>
  </Card>
}
