import { useState } from 'react'
import { useAsync } from 'react-async'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import API from '@/api'
import Loading from '@/components/Loading'
import { getValidationMessage } from '@/helpers/errors'
import Button from './Button'
import FormGroup from './FormGroup'
import Input from './Input'

const updateProject = ([projectId, name, description]) => API.put(`/projects/${projectId}`, { name, description })

export default function EditProjectInformation ({
  project,
  open = true,
  setOpen = () => null,
  onSaved = () => null,
}) {
  const [name, setName] = useState(project.name)
  const [description, setDescription] = useState(project.description)
  const { t } = useTranslation()
  const { run, isPending, error } = useAsync({
    deferFn: updateProject,
    onResolve: data => {
      onSaved(data)
    },
  })

  return <>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      {
        isPending
          ? <Loading />
          : <>
            <h1 className="title">{t('projects.settings.info.modal.title')}</h1>

            <form onSubmit={event => {
              event.preventDefault()
              run(project.id, name, description)
            }}>
              <FormGroup
                label={t('vocab.name')}
                error={getValidationMessage('name', error)}
              >
                <Input
                  type="text"
                  value={name}
                  onChange={setName}
                />
              </FormGroup>

              <FormGroup
                label={t('vocab.description')}
                error={getValidationMessage('description', error)}
              >
                <textarea
                  className="CodeDoor__Input"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                  rows="6"
                />
              </FormGroup>

              <footer className="actions">
                <Button  variant="secondary" onClick={() => setOpen(false)}>{t('vocab.cancel')}</Button>
                <Button type="submit">{t('vocab.save')}</Button>
              </footer>
            </form>
          </>
      }
    </Modal>
  </>
}
