import React, { useState } from 'react'
import Avatar from '@/components/Avatar'
import SearchBar from '@/components/SearchBar'
import Table from '@/components/Table'
import Button from './Button'
import '@/styles/Modal.css'
import '@/styles/Table.css'

export default function SelectableUserList ({ users, userList, setUserList }) {
  const [input, setInput] = useState('')
  const [filtered, setFiltered] = useState(users)

  const userPool = React.useMemo(
    () =>
      users.map(u => ({
        ...u,
        searchString: `${u.fullName.toLowerCase()} ${u.email.toLowerCase()}`,
      })),
    [users]
  )

  const updateInput = async (input) => {
    const filter = userPool.filter(user => {
      return user.searchString.includes(input.toLowerCase())
    })
    setInput(input)
    setFiltered(filter)
  }

  if (!users?.length) return null

  return <>
    <SearchBar
      input={input}
      updateInput={updateInput}
    />
    <div className="CodeDoor__ScrollableDiv">
      {userList.length >= 1
      && <Table className="mt-spacer">
        <Table.Head>
          <Table.TH label="Selected Users" colSpan={3}/>
          <Table.TH label=""/>
        </Table.Head>
        <Table.Body>
          {
            users
              .filter(user => userList.some(item => user.id === item))
              .map(selectedUser => (
                <Table.Row key={selectedUser.id}>
                  <Table.TD className="col-right">
                    <Avatar user={selectedUser} />
                  </Table.TD>
                  <Table.TD>{selectedUser.firstName} {selectedUser.lastName}</Table.TD>
                  <Table.TD>{selectedUser.email}</Table.TD>
                  <Table.TD>
                    <Button
                      variant="primary"
                      className="is-small"
                      onClick={() => {
                        setUserList( arr => [...arr, selectedUser.id])
                        setUserList(userList.filter(item => item !== selectedUser.id))
                      }}
                    >Remove</Button>
                  </Table.TD>
                </Table.Row>
              ))
          }
        </Table.Body>
      </Table>
      }
      <Table className="mt-spacer">
        <Table.Head>
          <Table.TH label="Users" colSpan={3}/>
          <Table.TH label=""/>
        </Table.Head>
        <Table.Body>
          {
            filtered
              .filter(user => !userList.some(item => user.id === item))
              .map(p => (
                <Table.Row key={p.id}>
                  <Table.TD className="col-right">
                    <Avatar user={p} />
                  </Table.TD>
                  <Table.TD>{p.firstName} {p.lastName}</Table.TD>
                  <Table.TD>{p.email}</Table.TD>
                  <Table.TD>
                    {userList.some(item => p.id === item)
                      ?                  <Button
                        variant="primary"
                        className="is-small"
                        onClick={() => {
                          setUserList( arr => [...arr, p.id])
                          setUserList(userList.filter(item => item !== p.id))
                        }}
                      >Remove</Button>
                      :                  <Button
                        variant="secondary"
                        className="is-small"
                        onClick={() => {
                          setUserList( arr => [...arr, p.id])
                        }}
                      >Add</Button>

                    }
                  </Table.TD>
                </Table.Row>
              ))
          }
        </Table.Body>
      </Table>
    </div>
  </>
}
