import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useAuth } from '../state/auth'
import Button from './Button'

export default function RequestLicense() {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { user } = useAuth()

  return <>
    <Button onClick={() => setOpen(true)} className="is-large">{t('projects.license.request.cta')}</Button>
    <Modal isOpen={open} onRequestClose={() => setOpen(false)} closeTimeoutMS={300}>
      <h1 className="title">{t('projects.license.request.modal.title')}</h1>
      <p className="lead">{t('projects.license.request.modal.lead')}</p>
      <p className='lead'>Contact your admin at {user.organisation.name} via {user.organisation.email}.</p>
      <footer>
        <Button onClick={() => setOpen(false)}>{t('vocab.ok')}</Button>
      </footer>
    </Modal>
  </>
}
