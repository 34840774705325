import { Async } from 'react-async'
import API from '@/api'
import Loading from '@/components/Loading'
import SelectableUserList from '@/components/SelectableUserList'
import { useAuth } from '@/state/auth'

const listStudents = ({ id }) => API.get(`/organisations/${id}/students`)

export default function EditTeam ({
  students = [],
  setStudents = () => null,
}) {
  const { user } = useAuth()

  return <>
    <Async promiseFn={listStudents} id={user.organisation.id}>
      <Async.Pending><Loading /></Async.Pending>
      <Async.Fulfilled>
        { data => (
          <SelectableUserList users={data} userList={students} setUserList={setStudents}/>
        )}
      </Async.Fulfilled>
      <Async.Rejected>Error...</Async.Rejected>
    </Async>
  </>
}
