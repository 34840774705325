import classNames from 'classnames'
import '@/styles/IconButton.css'

function Button ({ children, className, type = 'button', ...props }) {
  return <button className={classNames('CodeDoor__Editor__IconButton', className)} {...props} type={type}>
    {children}
  </button>
}

export default Button
