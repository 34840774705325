import { Link } from 'react-router-dom'
import classNames from 'classnames'
import '@/styles/Card.css'

export default function Card ({ children, title, to, className }) {
  const classes = classNames('CodeDoor__Card', className)

  return <section className={classes}>
    {children}

    {to && <Link to={to} className="CodeDoor__Card__Link" title={title} />}
  </section>
}
