import classNames from 'classnames'
import { SORTS } from '@/helpers/enums'
import { onEnter } from '@/helpers/key-press'
import Down from './Icons/Down'
import '@/styles/Table.css'

// The Table Head Component
const Head = ({ children }) => (
  <thead>
    <tr>
      {children}
    </tr>
  </thead>
)

// The Table Header Cell Component
const HeaderCell = ({
  label,
  sortable,
  sort = SORTS.NONE,
  colSpan = 1,
  onClick = () => null,
}) => {
  return (
    <th className="label" colSpan={colSpan}>
      {
        sortable
          ? <span
            onClick={onClick}
            onKeyUp={onEnter(onClick)}
            className={`is-interactive is-${sort}`}
            role="button"
            tabIndex="0"
          >
            {label} <Down />
          </span>
          : label
      }
    </th>
  )
}

// The Table Body Component
const Body = ({ children }) => <tbody>{children}</tbody>

// The Table Row Component
const Row = ({ children }) => <tr>{children}</tr>

// The Table Data Cell Component
const DataCell = ({ children }) => <td>{children}</td>

// The Table Component
const Table = ({ children, className }) => (
  <table className={classNames('CodeDoor__Table', className)}>
    {children}
  </table>
)

// Shugar
Table.Head = Head
Table.TH = HeaderCell
Table.Body = Body
Table.Row = Row
Table.TD = DataCell

export default Table
