import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Avatar from './Avatar'
import '@/styles/UserList.css'

export default function UserList ({ users, className }) {

  return <ul className={classNames('CodeDoor__UserList', className)}>
    { users.map(user => (
      <li key={user.id}>
        <Link to={`/students/${user.id}`}>
          <Avatar user={user} />
          <span>{user.fullName}</span>
        </Link>
      </li>)
    ) }
  </ul>

}
