import { Async } from 'react-async'
import { useTranslation } from 'react-i18next'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import SkillListItem from '@/components/SkillListItem'
import Tag from '@/components/Tag'
import API from '../api'
import TagList from '../components/TagList'
import { useAuth } from '../state/auth'

const fetchLearnedSkills = ({ id }) => API.get(`/users/${id}/skills/learned`)
const fetchSkills = () => API.get(`/skills`)

export default function Skills() {
  const { user } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="container">
      <Title text={t('vocab.skills')} />

      <h1 className="title">{t('vocab.skills')}</h1>

      <Async promiseFn={fetchLearnedSkills} id={user.id}>
        <Async.Fulfilled>
          {data => !!data.length && (
            <>
              <h2 className="title is-secondary">Achieved Skills</h2>
              <TagList>
                {data.map(s => <Tag title={s.name} to={`/skills/${s.slug}`} key={s.id} />)}
              </TagList>
            </>
          )
          }
        </Async.Fulfilled>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>

      <h2 className="title is-secondary">Find fellow Learners and Projects via Skills</h2>
      <Async promiseFn={fetchSkills}>
        <Async.Pending><Loading /></Async.Pending>
        <Async.Fulfilled>
          {data => data.map(s => <SkillListItem skill={s} key={s.id} />)}
        </Async.Fulfilled>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>
    </div>
  )
}
