import { useEffect, useState } from 'react'
import { Async } from 'react-async'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import API from '@/api'
import Button from '@/components/Button'
import CardList from '@/components/CardList'
import DataViewSwitch from '@/components/DataViewSwitch'
import InviteUsers from '@/components/InviteUsers'
import ListInviteUsers from '@/components/ListInviteUsers'
import Loading from '@/components/Loading'
import { Title } from '@/components/Meta'
import StudentsTable from '@/components/StudentsTable'
import { StudentCard } from '@/components/UserCard'
import { VIEW } from '@/helpers/enums'
import { useSort } from '@/helpers/sort'
import { storage, LOCALSTORAGE_KEYS } from '@/helpers/storage/LocalStorage'
import { useAuth } from '@/state/auth'

const fetchStudents = ({ id, sort }) => API.get(`/organisations/${id}/students?include=projectsCount,skillsCount&sort=${sort}`)
const VIEW_PREFERENCE = LOCALSTORAGE_KEYS.DATA_TABLE_VIEW
const STUDENT_SORT_PREFERENCE = 'student_sort_preference'
const DEFAULT_SORT = 'name'

export default function Students() {
  const { user } = useAuth()
  const [viewMode, setViewMode] = useState(storage.getItem(VIEW_PREFERENCE, VIEW.TABLE))
  const { t } = useTranslation()
  const [sort, sorted, nextSort] = useSort(storage.getItem(STUDENT_SORT_PREFERENCE, DEFAULT_SORT))

  useEffect(() => {
    storage.setItem(STUDENT_SORT_PREFERENCE, sort)
    storage.setItem(VIEW_PREFERENCE, viewMode)
  }, [sort, viewMode])

  return (
    <div className="container">
      <Title text={t('vocab.students')} />

      <header>
        <h1 className="title">{t('vocab.students')}</h1>
        <div className="actions">
          <Async promiseFn={fetchStudents} id={user.organisation.id} sort={sort} watch={sort}>
            <Async.Fulfilled>
              {
                students => (
                  <CSVLink data={students.map(u => ({ name: u.fullName, email: u.email, projects: u.projectsCount, skills: u.skillsCount, lastLogin: u.lastLogin }))} filename='students.csv' separator=';'>
                    <Button
                      variant="secondary"
                      className="is-medium">
                      CSV Data
                    </Button>
                  </CSVLink>
                )
              }
            </Async.Fulfilled>
          </Async>
          <DataViewSwitch state={viewMode} setState={setViewMode} />
          <ListInviteUsers />
          <InviteUsers />
        </div>
      </header>

      <Async promiseFn={fetchStudents} id={user.organisation.id} sort={sort} watch={sort}>
        {viewMode === 'card'
          ? (
            <Async.Fulfilled>
              {
                students => (
                  <CardList>
                    {students.map(student => <StudentCard user={student} to={`/students/${student.id}`} key={student.id} />)}
                  </CardList>
                )
              }
            </Async.Fulfilled>
          ) : (
            <StudentsTable sorted={sorted} nextSort={nextSort} />
          )
        }
        <Async.Pending><Loading /></Async.Pending>
        <Async.Rejected>Error...</Async.Rejected>
      </Async>
    </div>
  )
}
