import Button from './Button'
import Icon from './Icon'

function Check ({ className }) {
  return <Icon dimention={24} className={className}>
    <path d="M8.84243 16.1121L17.9219 5.83243C18.165 5.60351 18.4766 5.48531 18.7945 5.50146C19.1123 5.51761 19.413 5.66692 19.6364 5.91953C19.8598 6.17215 19.9894 6.50946 19.9994 6.86404C20.0094 7.21861 19.8989 7.56432 19.6902 7.83204L9.72655 19.0975C9.49045 19.3555 9.17303 19.5 8.84243 19.5C8.51182 19.5 8.1944 19.3555 7.95831 19.0975L3.30982 14.8729C3.10107 14.6052 2.99065 14.2595 3.00062 13.9049C3.0106 13.5504 3.14023 13.213 3.36362 12.9604C3.58701 12.7078 3.88768 12.5585 4.20554 12.5423C4.52339 12.5262 4.835 12.6444 5.07807 12.8733L8.84243 16.1121Z" fill="currentColor"/>
  </Icon>
}

function CheckButton (props) {
  return <Button {...props}><Check /></Button>
}

export default Check
export { CheckButton }
