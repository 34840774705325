import { Async } from 'react-async'
import API from '../api'
import { useAuth } from '../state/auth'
import CardList from './CardList'
import Loading from './Loading'
import MyActiveProjects from './MyActiveProjects'
import ProjectDefinitionCard from './ProjectDefinitionCard'

const fetchProjectDefinitions = ({ organisationId }) =>
  API.get(`/organisations/${organisationId}/project-definitions`)

export default function MyProjects() {
  const { user } = useAuth()

  return <>
    <MyActiveProjects />

    <h2 className="title is-secondary">Available projects</h2>
    <Async promiseFn={fetchProjectDefinitions} organisationId={user.organisation.id}>
      <Async.Pending><Loading /></Async.Pending>
      <Async.Fulfilled>
        {data => (
          <CardList>
            {data.sort((a, b) => a.name.localeCompare(b.name)).map(d => <ProjectDefinitionCard definition={d} key={d.id} />)}
          </CardList>
        )}

      </Async.Fulfilled>
      <Async.Rejected>Error...</Async.Rejected>
    </Async>
  </>
}
