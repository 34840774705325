import Answer from './Answer'

export const TopicsMap = {}

class Topic {
  constructor({
    id,
    name,
    description,
    video,
    children,
    resources,
    parent,
    project,
    regex,
    wordlist,
    robo,
    uploadAttachment,
  }) {
    this.id = id
    this.name = name
    this.description = description
    this.video = video
    this.regex = regex
    this.wordlist = wordlist
    this.robo = robo
    this.resources = resources
    this.project = project
    this.uploadAttachment = uploadAttachment

    this.parent = parent
    this.children = children.map(c => {
      if (!TopicsMap[c.id]) {
        TopicsMap[c.id] = new Topic({
          ...c,
          parent: this,
          project: this.project,
        })
      }

      return TopicsMap[c.id]
    })

    if (!this.hasChildren) {
      this.answer = Answer.forTopic(this)
    }

    this.updateProgress()
  }

  get resourcesInTree() {
    return [
      ...this.parent?.resourcesInTree || [],
      ...this.resources || [],
    ].filter(r => !!r)
  }

  get hasChildren() {
    return this.children?.length > 0
  }

  get isComplete() {
    return this.progress >= 60
  }

  get firstLeafLink() {
    return generateLeafLink(this)
  }

  get root() {
    const getRoot = topic => topic.parent
      ? getRoot(topic.parent)
      : topic

    return getRoot(this)
  }

  get currentIndex() {
    return this.parent?.children.findIndex(c => c.id === this.id)
  }

  get nextSibling() {
    return this.parent?.children[this.currentIndex + 1]
  }

  get previousSibling() {
    return this.parent?.children[this.currentIndex - 1]
  }

  get nextLink() {
    return this.nextSibling
      ? this.nextSibling.id
      : this.parent?.nextSibling
        ? `../${this.parent.nextSibling.id}`
        : this.parent?.parent?.nextSibling && `../../${this.parent.parent.nextSibling.id}`
  }

  get previousLink() {
    return this.previousSibling
      ? this.previousSibling.id
      : this.parent?.previousSibling
        ? `../${this.parent?.previousSibling.id}`
        : this.parent?.parent?.previousSibling && `../../${this.parent.parent.previousSibling.id}`
  }

  computeProgress() {
    return this.hasChildren
      ? this.children.filter(c => c.isComplete).length === this.children.length ? 100 : 0
      : this.answer?.progress
  }

  updateProgress() {
    this.progress = this.computeProgress()
    this.parent?.updateProgress()
  }
}

export const generateMap = project => new Topic({ id: 0, project, children: project.topics })

export function generateLeafLink(topic, link = `${topic.id}`) {
  return topic.hasChildren
    ? generateLeafLink(topic.children[0], `${link}/${topic.children[0].id}`)
    : link
}

export default Topic
